<template>
	<transition
		ref="componentRef"
		name="scale-and-fade"
	>
		<div
			v-if="render"
			v-show="showModal"
			class="modal"
			:class="modalClasses"
		>
			<div
				class="modal__header"
				@click="hide()"
			>
				<button
					v-if="customIcon"
					type="button"
					class="modal__custom-icon"
				>
					<BaseIcon name="chevron" />
				</button>
				<span class="modal__title">
					{{ title }}
					<span
						v-if="board"
						class="modal__title--small-text"
					>
						(mind.)
					</span>
				</span>
				<svg
					v-if="!customIcon"
					class="modal__close"
					viewBox="0 0 27 32"
				>
					<path d="M15.25 16.188l11 10.938c0.625 0.625 0.625 1.563 0 2.188-0.563 0.625-1.563 0.625-2.188 0l-10.688-10.75-10.75 10.75c-0.625 0.625-1.625 0.625-2.188 0-0.625-0.625-0.625-1.563 0-2.188l11-10.938v-0.375l-11-10.938c-0.625-0.625-0.625-1.563 0-2.188 0.563-0.625 1.563-0.625 2.188 0l10.75 10.75 10.688-10.75c0.625-0.625 1.625-0.625 2.188 0 0.625 0.625 0.625 1.563 0 2.188l-11 10.938v0.375z" />
				</svg>
			</div>
			<div class="modal__body">
				<div class="modal__content-top">
					<slot name="content-top"></slot>
				</div>
				<div class="modal__content">
					<slot></slot>
				</div>
				<div class="modal__content-bottom">
					<slot name="content-bottom"></slot>
				</div>
			</div>
			<div
				v-if="withFooter"
				class="modal__footer"
			>
				<p
					v-if="errorMessage"
					class="modal__footer-warning"
				>
					{{ errorMessage }}
				</p>

				<div class="modal__footer-wrap">
					<rounded-button
						theme="outlined"
						class="modal__footer-button modal__footer-button--hide"
						@click="hide()"
					>
						{{ cancelButtonLabel }}
					</rounded-button>
					<rounded-button
						class="modal__footer-button modal__footer-button--ok"
						:disabled="!!errorMessage"
						@click="ok()"
					>
						{{ acceptButtonLabel }}
					</rounded-button>
				</div>
			</div>
		</div>
	</transition>
</template>

<script lang="ts" setup>
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import { EventBus } from '@global-js/event-bus';
import {
	computed, getCurrentInstance, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import ModalManager from '@/js/modules/modalManager';

const emit = defineEmits(['update:modelValue', 'Modal:Cancel', 'Modal:Ok', 'Modal:Close']);

interface Props {
	modelValue?: boolean,
	title: string,
	offerDurationModal?: boolean,
	initialRender?: boolean,
	board?: boolean,
	errorMessage?: string,
	customIcon?: string,
	withFooter?: boolean,
	cancelButtonLabel?: string,
	acceptButtonLabel?: string,
}

const props = withDefaults(defineProps<Props>(), {
	modelValue: false,
	title: 'Title',
	offerDurationModal: false,
	initialRender: false,
	board: false,
	customIcon: '',
	withFooter: true,
	errorMessage: '',
	cancelButtonLabel: 'Abbrechen',
	acceptButtonLabel: 'OK',
});

const componentRef = ref();

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue: boolean) {
		emit('update:modelValue', newValue);
	}
});

const parent = ref<HTMLElement | null>(null);

const root = ref();

const showModal = ref<boolean>(model.value);

const render = ref(props.initialRender);

const isClosing = ref(false);

const bodyElem = computed((): HTMLElement => document && document.body);

const modalClasses = computed((): Record<string, any> => ({
	'is-open': showModal.value,
	'is-closing': isClosing.value
}));

const isHeaderSearch = computed((): boolean => root.value?.type.name === 'SearchForm-Header' || root.value?.type.name === 'Solr-Search');

const close = (): void => {
	emit('update:modelValue', false);
};

const onPopState = (): void => {
	if (showModal.value) {
		close();
	}
};

const ok = (): void => {
	close();
	if (window.history.state && window.history.state.modal) {
		ModalManager.closeModalAndChildren(componentRef.value);
		emit('Modal:Ok');
	}
};

const hide = (): void => {
	emit('Modal:Cancel');
	close();
	if (window.history.state && window.history.state.modal) {
		ModalManager.closeModalAndChildren(componentRef.value);
	}
};

onBeforeUnmount(() => {
	EventBus.$off('OfferDuration:ModalClose', close);
	EventBus.$off('window:popstate', close);
});

onMounted(() => {
	parent.value = getCurrentInstance()?.parent?.vnode.el as unknown as HTMLElement;
	root.value = getCurrentInstance()?.root;

	const mql = window.matchMedia('(min-width: 1300px)');

	mql.addListener(function(e) {
		if (e.matches) {
			close();
		}
	});

	const unwatch = watch(() => model.value, () => {
		if (!model.value) {
			return;
		}

		render.value = true;
		unwatch();
	});

	EventBus.$on('OfferDuration:ModalClose', close);
});

watch(() => model.value, (next: boolean, prev: boolean) => {
	if (next === false && prev === true) {
		setTimeout(() => {
			isClosing.value = false;
			showModal.value = false;
		}, 100);

		isClosing.value = true;
		EventBus.$off('window:popstate', onPopState);
	} else {
		showModal.value = model.value;
		window.history.pushState({ modal: true }, '');
		EventBus.$on('window:popstate', onPopState);
		ModalManager.modalOpenHistory.push(componentRef.value);
	}

	if (root.value?.type.name === 'Solr-Search') {
		bodyElem.value.classList.toggle('remove-back-content', model.value);
	}
	if (!isHeaderSearch.value) {
		bodyElem.value.classList.toggle('no-scroll', model.value);
	} else {
		const headerSearch = document.querySelector('.rpb_header-search');
		if (headerSearch) {
			headerSearch.classList.toggle('rpb_header-search--open-modal');
		}
	}
});

defineExpose({
	showModal, model
});

</script>

<style lang="scss" scoped>
.hide-content main,
.hide-content footer {
	display: none;

	@include media-query-up($breakpoint-container) {
		display: block;
	}
}

@keyframes headerIn {
	from {
		transform: translateX(-3rem);
		opacity: 0;
	}

	to {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes headerOut {
	from {
		transform: translateX(0);
		opacity: 1;
	}

	to {
		transform: translateX(-3rem);
		opacity: 0;
	}
}

.modal {
	display: flex;
	position: fixed;
	z-index: 5001;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	flex-direction: column;
	background: $color-white;

	.modal__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $color-link;
		color: #FFFFFF;
		font-size: $font-small-1;
		box-shadow: 0 0.4rem 0.6rem 0 rgba(0, 0, 0, 0.1607843137);
		padding: 0.8rem 1.6rem;
		outline: none;
	}

	.modal__custom-icon {
		width: auto;
		border: none;
		background-color: transparent;
		height: 2.2rem;
		transform: rotateZ(270deg);

		& svg {
			height: 1.1rem;
			fill: $color-white;
		}
	}

	.modal__title {
		padding: 0;
		opacity: 0;
		font-family: $font-family;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		text-align: left;
	}

	&__title--small-text {
		font-size: $font-small-3;
	}

	.modal__close {
		padding: 0;
		height: 1.4rem;
		width: 1.4rem;
		color: $color-white;
		border: none;
		cursor: pointer;
		fill: $color-white;

		&:focus,
		&:active {
			outline: none;
		}
	}

	.modal__body {
		display: flex;
		position: relative;
		flex-direction: column;
		height: 100%;
		overflow-y: auto;
	}

	.modal__content {
		max-width: 54rem;
		width: 100%;
		margin: 0 auto;
		height: 100%;
		overflow: auto;
	}

	.modal__content-bottom {
		padding-top: 0.1rem;
	}

	.modal__footer {
		height: fit-content;
		background: $color-light-gray;
	}

	&__footer-warning {
		text-align: center;
		color: $color-warning;
		font-size: $font-small-2;
		margin: 1.5rem 0;
	}

	.modal__footer-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		column-gap: 0.8rem;
		background-color: #F9F9F9;
		padding: 1.6rem 0;
	}

	.modal__footer-button {
		padding: 0.8rem 4rem;

		&--ok {
			background-color: $color-extra;
		}
	}

	&.is-open {
		.modal__title {
			animation: headerIn forwards 0.1s 0.25s;
		}
	}

	&.is-closing {
		.modal__title {
			animation: headerOut forwards 0.1s 0s;
		}
	}

	:deep(.form-field__chevron-icon) {
		display: none;
	}

	@include media-query-up ($breakpoint-small) {
		.modal__header {
			padding: 1.6rem 2.4rem;
		}

		.modal__close {
			height: 1.6rem;
			width: 1.6rem;
		}

		.modal__title {
			text-align: center;
			font-size: $font-medium-2;
		}

		.modal__footer {
			padding: 0;
		}
	}
}

.scale-and-fade-enter-active,
.scale-and-fade-leave-active {
	transition: all 0.25s;
}

.scale-and-fade-enter-active {
	transition-delay: 0.2s;
}

.scale-and-fade-leave-active {
	transition-delay: 0s;
}

.scale-and-fade-enter-from,
.scale-and-fade-leave-to {
	opacity: 0;
}

</style>
