<template>
	<div class="mode-switch-mini">
		<SearchModeSwitchSimple
			:value="model"
			class="mode-switch-mini__switch"
			:draggable-aria-label="'Mini Mode Switch Handle'"
			v-bind="$attrs"
			@drag="onDrag"
		>
			<template #left>
				<svg
					class="mode-switch-mini__icon"
					viewBox="0 0 53 32"
				>
					<title>{{ searchLabel }}</title>
					<path d="M52.403 15.729c-0.456-1.671-1.961-2.88-3.748-2.88-0.358 0-0.704 0.048-1.032 0.139l0.027-0.006-13.769 3.691-17.878-16.671-5.001 1.346 10.726 18.589-12.877 3.446-5.089-4.003-3.758 1.011 4.714 8.169 1.973 3.437 42.963-11.512c1.673-0.455 2.883-1.961 2.883-3.749 0-0.358-0.049-0.705-0.139-1.035l0.006 0.027z" />
				</svg>
				<svg
					class="mode-switch-mini__icon-small"
					viewBox="0 0 28 32"
				>
					<title>plus</title>
					<path d="M16.313 27.188c0 1.438-1.125 2.563-2.563 2.563v0c-1.438 0-2.563-1.125-2.563-2.563v-22.375c0-1.438 1.125-2.563 2.563-2.563v0c1.438 0 2.563 1.125 2.563 2.563v22.375zM2.563 18.563c-1.438 0-2.563-1.125-2.563-2.563v0c0-1.438 1.125-2.563 2.563-2.563h22.375c1.438 0 2.563 1.125 2.563 2.563v0c0 1.438-1.125 2.563-2.563 2.563h-22.375z" />
				</svg>
				<svg
					class="mode-switch-mini__icon"
					viewBox="0 0 32 32"
				>
					<title>Nur Hotel</title>
					<path d="M9.343 17.345c2.209-0.002 4-1.793 4-4.003 0-2.211-1.792-4.003-4.003-4.003s-4.003 1.792-4.003 4.003c0 0.001 0 0.001 0 0.002v-0c0 0.001 0 0.002 0 0.003 0 2.208 1.79 3.998 3.998 3.998 0.003 0 0.006 0 0.009 0h-0zM25.361 9.336h-10.685v9.339h-10.673v-12.004h-2.669v20.014h2.669v-3.996h24.015v4.003h2.669v-12.012c0-0.001 0-0.003 0-0.005 0-2.946-2.389-5.335-5.335-5.335-0.001 0-0.002 0-0.003 0h0z" />
				</svg>
			</template>
			<template #right>
				<svg
					class="mode-switch-mini__icon"
					viewBox="0 0 32 32"
				>
					<title>Nur Hotel</title>
					<path d="M9.343 17.345c2.209-0.002 4-1.793 4-4.003 0-2.211-1.792-4.003-4.003-4.003s-4.003 1.792-4.003 4.003c0 0.001 0 0.001 0 0.002v-0c0 0.001 0 0.002 0 0.003 0 2.208 1.79 3.998 3.998 3.998 0.003 0 0.006 0 0.009 0h-0zM25.361 9.336h-10.685v9.339h-10.673v-12.004h-2.669v20.014h2.669v-3.996h24.015v4.003h2.669v-12.012c0-0.001 0-0.003 0-0.005 0-2.946-2.389-5.335-5.335-5.335-0.001 0-0.002 0-0.003 0h0z" />
				</svg>
			</template>
		</SearchModeSwitchSimple>

		<div class="mode-switch-mini__value">
			<span
				class="mode-switch-mini__value-label"
				:style="checkedStyle"
				aria-label="Nur Hotel"
			>Nur Hotel</span>
			<span
				class="mode-switch-mini__value-label"
				:style="uncheckedStyle"
				:aria-label="searchLabel"
			>{{ searchLabel }}</span>
		</div>
	</div>
</template>

<script lang="ts" setup>
import SearchModeSwitchSimple from '@lmt-rpb/SearchModeSwitchSimple/SearchModeSwitchSimple.vue';
import { getLocaleString } from '@utils/utils';
import { computed, ref, watch } from 'vue';

interface Props {
	modelValue?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
	modelValue: false,
});

const emit = defineEmits(['update:modelValue']);

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const progress = ref(0);

watch(() =>	model.value, () => {
	progress.value = model.value ? 100 : 0;
});

const checkedStyle = computed((): Record<string, any> => ({
	opacity: progress.value / 100
}));

const uncheckedStyle = computed((): Record<string, any> => ({
	opacity: (100 - progress.value) / 100
}));

const searchLabel = computed((): string => getLocaleString('package'));

const onDrag = (progressNum: number) => {
	progress.value = progressNum;
};

</script>

<style lang="scss" scoped>
.mode-switch-mini {
	.mode-switch-mini__switch {
		padding: 0.35rem 0;

		& > .mode-switch__handle {
			box-shadow: 0 0 0 0.1rem $color-primary-l1;
		}
	}

	.mode-switch-mini__value {
		position: relative;
		margin-top: 1rem;
		color: $color-text-l24;
		font-size: $font-small-1;
		font-weight: bold;
	}

	.mode-switch-mini__value-label {
		position: absolute;
		right: 0;
		left: 0;
		text-align: center;
	}

	.mode-switch-mini__icon {
		width: 2rem;
		height: 2.5rem;
		vertical-align: middle;
	}

	.mode-switch-mini__icon-small {
		width: 1rem;
		height: 1rem;
		vertical-align: middle;
	}

	.mode-switch__label + .mode-switch__label {
		padding: 0;

		.mode-switch-mini__icon {
			width: 2.5rem;
		}
	}
}
</style>
