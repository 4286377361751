<template>
	<RowList class="departure-field-content">
		<template #content>
			<section
				class="departure-field-content__body"
				aria-label="Flughäfen"
			>
				<template v-if="!airports.length">
					Keine Ergebnisse
				</template>

				<PillSelect
					v-for="(item, index) in airports"
					ref="item"
					:key="index"
					v-model="model"
					:item="item"
					class="departure-field-content__pill"
				/>
			</section>
		</template>
		<template #footer>
			<div
				v-if="model.length"
				class="departure-field-content__selected"
			>
				<button
					type="button"
					class="departure-field-content__clear-button"
					aria-label="Ausgewählte Elemente löschen"
					@click.stop="clear"
				>
					<BaseIcon
						name="close"
						class="departure-field-content__close-icon"
					/>
				</button>
				<div class="departure-field-content__selected-list">
					<span
						v-for="item in visibleItems"
						:key="item.value"
						class="departure-field-content__selected-item"
					>
						<span class="departure-field-content__airport">{{ item.label }}</span>
						<span class="departure-field-content__airport-code">
							<template v-for="airportCode in item.additional">
								({{ airportCode }})
							</template>
						</span>
					</span>

					<template v-if="moreItems.length">
						<button
							:title="moreItems.join(', ')"
							class="departure-field-content__more-button"
							@click.stop="showMoreItems"
						>
							und <span class="departure-field-content__more-text">{{ moreItems.length }}</span> weitere
						</button>
					</template>
				</div>
			</div>
		</template>
	</RowList>
</template>

<script lang="ts" setup>
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import PillSelect from '@lmt-rpb/PillSelect/PillSelect.vue';
import RowList from '@lmt-rpb/RowList/RowList.vue';
import type { AirportDataType } from '@/components/common/types';
import { useStore } from '@/components/common/store';
import { computed, ref } from 'vue';

const store = useStore();

const emit = defineEmits(['update:modelValue']);

interface Props {
	moreCount?: number,
	modelValue?: string[]
}
const props = withDefaults(defineProps<Props>(), {
	moreCount: 3,
	modelValue: () => []
});

const count = ref(props.moreCount);

const airports = computed((): AirportDataType[] => store.state.items.airports);

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const getAirportDataByCode = (items: string[]): AirportDataType[] => items.map((item: string) => airports.value.find(({ value }) => item === value))
	.filter(Boolean) as AirportDataType[];

const visibleItems = computed((): AirportDataType[] => {
	if (!model.value.length) {
		return [];
	}

	return getAirportDataByCode(model.value.slice(0, count.value));
});

const moreItems = computed((): string[] => {
	if (!visibleItems.value.length) {
		return [];
	}

	const airportData = getAirportDataByCode(model.value.slice(count.value));
	return airportData.map((airport: AirportDataType) => {
		const additional = airport.additional && airport.additional.map((extra) => `(${extra})`).join(' ');
		return `${airport.label} ${additional}`;
	});
});

const showMoreItems = () => {
	count.value = model.value.length;
};

const clear = () => {
	model.value = [];
};

</script>

<style lang="scss">
.departure-field-content {
	overflow: hidden;

	.departure-field-content__close-icon {
		width: 1.5rem;
		height: 1.5rem;
	}

	.departure-field-content__body {
		@include max-width(50rem);

		padding: 1rem;
	}

	.departure-field-content__pill {
		margin-right: 1.5rem;
		margin-bottom: 1rem;
	}

	.departure-field-content__selected {
		position: relative;
		width: 100%;
		padding: 1rem 6rem 1rem 2rem;
		border-top: 0.1rem solid $color-primary-l4;
	}

	.departure-field-content__selected-list {
		@include max-width(60rem);

		height: 100%;
		max-height: 9rem;
		overflow: auto;
		line-height: 1.4;
	}

	.departure-field-content__selected-item {
		font-size: $font-small-1;

		& + .departure-field-content__selected-item::before {
			content: ', ';
		}
	}

	.departure-field-content__airport {
		font-weight: $font-weight-semibold;
	}

	.departure-field-content__airport-code {
		margin-left: 0.25rem;
		font-size: $font-small-2;
	}

	.departure-field-content__clear-button {
		position: absolute;
		top: 50%;
		right: 1rem;
		padding: 1.25rem;
		transform: translateY(-50%);
		border: 0;
		outline: none;
		background: none;
		fill: $color-primary;
		cursor: pointer;
	}

	.departure-field-content__more-button {
		border: none;
		background-color: transparent;
		color: $color-primary;

		&:hover {
			text-decoration: underline;
		}
	}

	.departure-field-content__more-text {
		font-weight: $font-weight-semibold;
	}
}
</style>
