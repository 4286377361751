import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "departure-field" }

import {
	watch, ref, computed,
} from 'vue';
import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import { cloneDeep } from '@utils/utils';

import { useStore } from '@/components/common/store';
import type { AirportDataType } from '@/components/common/types';
import DepartureFieldContent from './DepartureFieldContent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DepartureField',
  setup(__props, { expose: __expose }) {

const store = useStore();
const showModal = ref(false);
const dropdown = ref<InstanceType<typeof DropdownField> | null>(null);

const departure = computed({
	get(): string[] {
		return store.state.searchMask?.departure || [];
	},
	set(value: string[]) {
		store.commit('searchMask/updateFormData', { departure: value, filter: '' });
	}
});

const maxTravelDurationError = computed(() => store.state.searchMask.maxTravelDurationError);

const selectedItems = ref(cloneDeep(departure.value));

const isDesktop = computed(() => store.state.config.isDesktop);

const airports = computed((): AirportDataType[] => store.state.items.airports);

const getAirportLabel = (code: string): string => {
	const airport = airports.value.find(({ value }: any) => value === code);
	if (!airport) {
		return 'unknown airport';
	}

	return airport.label;
};

const fieldValue = computed(() => {
	if (!departure.value.length) {
		return '';
	}
	return departure.value.map(getAirportLabel).join(', ');
});

watch(() => departure.value, () => {
	selectedItems.value = cloneDeep(departure.value);
});

const closeDropdown = () => {
	if (dropdown.value) {
		dropdown.value.closeDropdown();
	}
};

const onReset = () => {
	selectedItems.value = cloneDeep(departure.value);
	closeDropdown();
};

const onClear = () => {
	departure.value = [];
};

const onOk = (): void => {
	departure.value = cloneDeep(selectedItems.value) as string[];
	closeDropdown();
};

__expose({
	selectedItems,
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isDesktop.value)
      ? (_openBlock(), _createBlock(DropdownField, {
          key: 0,
          ref_key: "dropdown",
          ref: dropdown,
          modelValue: fieldValue.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((fieldValue).value = $event)),
          class: "departure-field__dropdown",
          "form-field-class": "departure-field__field",
          label: "Abflughafen",
          icon: "flightStart",
          wide: true,
          "max-travel-duration-error": maxTravelDurationError.value,
          "onDropdownField:Ok": onOk,
          "onDropdownField:Cancel": onReset,
          "onDropdownField:OutsideClick": onOk,
          "onDropdownField:Clear": onClear
        }, {
          default: _withCtx(() => [
            _createVNode(DepartureFieldContent, {
              modelValue: selectedItems.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedItems).value = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "max-travel-duration-error"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(FormField, {
            selected: fieldValue.value,
            "show-modal": showModal.value,
            "show-toggle-icon": false,
            icon: "flightStart",
            label: "Abflughafen",
            disabled: maxTravelDurationError.value,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (showModal.value = !showModal.value)),
            "onFormField:Clear": onClear
          }, null, 8 /* PROPS */, ["selected", "show-modal", "disabled"]),
          _createVNode(Modal, {
            modelValue: showModal.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((showModal).value = $event)),
            class: "departure-field__modal",
            title: "Abflughafen",
            "onModal:Ok": onOk,
            "onModal:Cancel": onReset
          }, {
            default: _withCtx(() => [
              _createVNode(DepartureFieldContent, {
                modelValue: selectedItems.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectedItems).value = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ], 64 /* STABLE_FRAGMENT */))
  ]))
}
}

})