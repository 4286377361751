<template>
	<div
		class="destination-filter"
		:class="disabled ? 'destination-filter--disabled' : ''"
	>
		<select-field
			v-if="isDesktop"
			ref="destinationFilterDesktop"
			v-model="selected"
			class="destination-filter__select"
			:items="types"
			:label="destinationLabel"
			label-prop="Name"
			:value-prop="locationIdName"
			icon="marker"
			:placeholder="placeHolderText('select')"
			:disabled="disabled"
			:offer-count="true"
			:offer-count-class="'destination-filter__count'"
			:count-label="countLabel"
			:button-wording="getLocaleString('close')"
			:total-count="totalCount"
			:item-any="true"
			:label-for-any="placeHolderText('dropdown')"
			@SelectField:Toggle="onToggle"
			@SelectField:Clear="clearInput"
		/>

		<FormField
			v-if="!isDesktop"
			:selected="fieldValue"
			:label="destinationLabel"
			:show-modal="showModal"
			:placeholder="placeHolderText('select')"
			icon="marker"
			:show-toggle-icon="false"
			@click="toggleModal"
			@FormField:Clear="clearInput(); clearProxyInput()"
		/>

		<modal
			v-model="showModal"
			class="destination-filter__modal"
			:title="locationType === 'COUNTRY' ? 'Regionen' : 'Orte'"
			@Modal:Ok="onOk"
			@Modal:Cancel="onReset"
		>
			<select-field
				ref="destinationFilterMobile"
				v-model="proxy"
				class="destination-filter__select"
				:items="types"
				:manual="true"
				:placeholder="placeHolderText('dropdown')"
				label-prop="Name"
				:value-prop="locationIdName"
				:show-footer="false"
				:offer-count="true"
				:count-label="countLabel"
				:offer-count-class="'destination-filter__count'"
				:allow-clear="true"
				icon="marker"
				@SelectField:Clear="clearInput(); clearProxyInput()"
			/>
		</modal>
	</div>
</template>

<script lang="ts" setup>
import SelectField from '@lmt-rpb/SelectField/SelectField.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import { getLocaleString, pluralize } from '@utils/utils';
import { CountLabel, HOTEL_LABELS } from '@global-js/constants';
import { useStore } from '@/components/common/store';
import { computed, ref, watch } from 'vue';
import type { DestinationElement } from '@/components/common/types';

interface Props {
	destinationLabel: string,
	locationType: string,
	disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	disabled: false
});

const store = useStore();

const open = ref(false);

const showModal = ref(false);

const countLabel = ref<CountLabel>(HOTEL_LABELS);

const isDesktop = computed(() => store.state.config.isDesktop);

const totalCount = computed((): number => store.state.searchMask.availableHotelCount);

const types = computed((): DestinationElement[] => store.state.types.destination);

const locationIdName = computed((): 'RegionID' | 'CityID' => (props.locationType === 'COUNTRY' || props.locationType === 'REGIONGROUP' ? 'RegionID' : 'CityID'));

const destinationTypes = computed({
	get() {
		return store.state.searchMask.destinationTypes as number[];
	},

	set(value: number[]) {
		let destinationType: number[];
		let destinationTypeName;
		let selectedGeoInventory;

		const foundType = types.value.find((type) => type[locationIdName.value] === value[0]);

		if (foundType && foundType[locationIdName.value]) {
			destinationType = [foundType[locationIdName.value] as number];
			destinationTypeName = foundType.Name;
			selectedGeoInventory = foundType;
		} else {
			destinationType = [];
			destinationTypeName = '';
			selectedGeoInventory = {
				RegionID: undefined,
				CityID: undefined,
				Name: '',
				Count: 0
			};
		}

		store.commit('searchMask/updateFormData', { destinationTypes: destinationType });
		store.commit('searchMask/updateFormData', { destinationTypeName });
		store.commit('searchMask/updateFormData', { selectedGeoInventory });
	}
});

const selected = ref<number | null>((destinationTypes.value && destinationTypes.value.length && destinationTypes.value[0]) || null);

const proxy = ref<number | null>((destinationTypes.value && destinationTypes.value.length && destinationTypes.value[0]) || null);

const fieldValue = computed((): string => (store.state.searchMask.selectedGeoInventory ? store.state.searchMask.selectedGeoInventory.Name : ''));

const destinationCountAndDescription = computed(() : string => pluralize(totalCount.value, countLabel.value.singular, countLabel.value.plural));

const clearInput = (): void => {
	selected.value = null;
};

const clearProxyInput = ():void => {
	proxy.value = null;
};

const onToggle = (isOpen: boolean): void => {
	if (props.disabled && isOpen) {
		return;
	}
	open.value = isOpen;
};

const onOk = (): void => {
	destinationTypes.value = proxy.value ? [proxy.value] : [];
	onToggle(false);
};

const onReset = (): void => {
	proxy.value = destinationTypes.value[0];
	onToggle(false);
};

const toggleModal = (): void => {
	if (props.disabled) {
		return;
	}
	showModal.value = !showModal.value;
};

const placeHolderText = (componentName: string): string => {
	let placeHolderString = '';
	if (props.disabled) {
		placeHolderString = 'Keine Treffer';
	} else if ((props.locationType === 'COUNTRY' || props.locationType === 'REGIONGROUP') && componentName === 'select') {
		placeHolderString = 'Alle Regionen';
	} else if ((props.locationType === 'COUNTRY' || props.locationType === 'REGIONGROUP') && componentName === 'dropdown') {
		placeHolderString = `Alle Regionen (${destinationCountAndDescription.value})`;
	} else if (componentName === 'select') {
		placeHolderString = 'Alle Orte';
	} else if (componentName === 'dropdown') {
		placeHolderString = `Alle Orte (${destinationCountAndDescription.value})`;
	}
	return placeHolderString;
};

watch(() => selected.value, () => {
	destinationTypes.value = selected.value ? [selected.value] : [];
});

watch(() => destinationTypes.value, () => {
	selected.value = (destinationTypes.value.length && destinationTypes.value[0]) || null;
});

</script>

<style lang="scss" scoped>
.destination-filter {
	.destination-filter__icon {
		width: 3rem;
		height: 3.5rem;
		margin-right: 1rem;
	}
}

:deep(.destination-filter__count) {
	font-weight: 200;
	font-size: $font-small-1;
}

.destination-filter__modal {
	.destination-filter__select {
		padding: 2.5rem 1.5rem 0;
	}
}

.destination-filter--disabled {
	:deep(.form-field__icon),
	:deep(.form-field__chevron-icon) {
		fill: $color-state-disabled;
	}

	:deep(.form-field) {
		cursor: default;
		border-color: $color-state-disabled;
	}

	:deep(.form-field__label-text) {
		color: $color-state-disabled;
	}

	:deep(.form-field__input::placeholder) {
		color: $color-state-disabled !important;
	}
}

</style>
