<template>
	<div class="flying-time-slider">
		<div class="flying-time-slider__header">
			<div class="flying-time-slider__title">
				{{ title }}
			</div>
			<div class="flying-time-slider__hours">
				{{ displayedHours }}
			</div>
		</div>
		<div class="flying-time-slider__inputs">
			<span
				ref="rangeTrack"
				class="flying-time-slider__range_track"
				:style="{ left: trackLeft + '%', width: trackWidth + '%' }"
			></span>

			<input
				v-model="minSliderValue"
				type="range"
				step="1"
				min="0"
				max="24"
				@mouseup="handleMinMouseUp"
				@input="handleMinInput"
				@touchend="handleMinMouseUp"
				@keyup.left.exact.stop.prevent="handleKeydown($event,'from', -1)"
				@keyup.right.exact.stop.prevent="handleKeydown($event,'from', 1)"
			/>
			<input
				v-model="maxSliderValue"
				type="range"
				step="1"
				min="0"
				max="24"
				@mouseup="handleMaxMouseUp"
				@input="handleMaxInput"
				@touchend="handleMaxMouseUp"
				@keyup.left.stop.prevent="handleKeydown($event,'to', -1)"
				@keyup.right.stop.prevent="handleKeydown($event,'to', 1)"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import {
	ref, computed, watch,
} from 'vue';

const props = defineProps({
	modelValue: {
		type: Object,
		required: true,
		default: () => ({ from: 0, to: 24 }),
	},
	title: {
		type: String,
		default: '',
	},
});

const emit = defineEmits(['update:modelValue']);

const rangeTrack = ref(null);
const minRangeValueGap = 1;

const totalRange = 24;

const trackLeft = ref((props.modelValue.from / totalRange) * 100);
const trackRight = ref(props.modelValue.to);
const trackWidth = computed(() => (trackRight.value / totalRange) * 100 - trackLeft.value);

const minSliderValue = ref(props.modelValue.from);
const maxSliderValue = ref(props.modelValue.to);

const minHours = ref(`${Math.round(props.modelValue.from)}:00`);
const maxHours = ref(`${Math.round(props.modelValue.to)}:00`);
const displayedHours = computed(() => `${minHours.value} - ${maxHours.value}`);

const maxConstraint = computed(() => maxSliderValue.value - minRangeValueGap);
const minConstraint = computed(() => minSliderValue.value + minRangeValueGap);

const updateMin = (value: number) => {
	minHours.value = `${value}:00`;
	trackLeft.value = (value / totalRange) * 100;
};

const updateMax = (value: number) => {
	maxHours.value = `${value}:00`;
	trackRight.value = value;
};

const handleMinMouseUp = (evt) => {
	emit('update:modelValue', { from: Math.min(maxSliderValue.value - minRangeValueGap, evt.target.valueAsNumber), to: maxSliderValue.value });
};
const handleMinInput = (evt) => {
	if (evt.target.valueAsNumber < maxConstraint.value) {
		minSliderValue.value = evt.target.valueAsNumber;
		updateMin(evt.target.valueAsNumber);
		return;
	}
	evt.preventDefault();
	minSliderValue.value = maxConstraint.value;
	updateMin(maxConstraint.value);
};

const handleMaxMouseUp = (evt) => {
	emit('update:modelValue', { from: minSliderValue.value, to: Math.max(evt.target.valueAsNumber, minSliderValue.value + minRangeValueGap) });
};
const handleMaxInput = (evt) => {
	if (evt.target.valueAsNumber > minConstraint.value) {
		maxSliderValue.value = evt.target.valueAsNumber;
		updateMax(evt.target.valueAsNumber);
		return;
	}
	evt.preventDefault();
	maxSliderValue.value = minConstraint.value;
	updateMax(minConstraint.value);
};

const handleKeydown = (evt, type, offset) => {
	const value = evt.target.valueAsNumber;
	updateValue(type, value + offset);
};

const updateValue = (type: 'from' | 'to', value: number) => {
	const updated = Math.max(Math.min(value, 24), 0);
	if (type === 'from') {
		if (updated < maxConstraint.value) {
			updateMin(updated);
			emit('update:modelValue', { to: maxSliderValue.value, from: updated });
		} else {
			updateMin(maxConstraint);
			emit('update:modelValue', { to: maxSliderValue.value, from: maxConstraint.value });
		}
	} else if (type === 'to') {
		if (updated > minConstraint.value) {
			updateMax(updated);
			emit('update:modelValue', { from: minSliderValue.value, to: updated });
		} else {
			updateMax(minConstraint);
			emit('update:modelValue', { from: minSliderValue.value, to: minConstraint.value });
		}
	}
};

watch(() => props.modelValue, (value) => {
	minSliderValue.value = value.from;
	maxSliderValue.value = value.to;
	updateMin(value.from);
	updateMax(value.to);
}, { immediate: true, deep: true });

</script>

<style lang="scss" scoped>
.flying-time-slider {
	position: relative;
	padding-bottom: 0.8rem;

	&__header {
		display: flex;
		gap: 0.6rem;
		margin-bottom: 1.6rem;
		font-size: $font-medium-2;
		font-weight: $font-weight-regular;
	}
	$margin: 1.3rem;
	&__inputs {
		margin-left: $margin;
		width: calc(100% - 2*$margin);
		height: 0.8rem;
		position: relative;
		background-color: $color-inactive;
		border-radius: 2rem;
	}

	&__range_track {
		height: 100%;
		position: absolute;
		border-radius: 2rem;
		background-color: $price-slider-track-bg;
		top: 0;
		left: 0;
	}

	input {
		position: absolute;
		width: 100%;
		height: 0.5rem;
		background: none;
		pointer-events: none;
		appearance: none;
		top: 50%;
		transform: translateY(-50%);
		margin: 0;

		&::-webkit-slider-thumb {
			height: 2.4rem;
			width: 2.4rem;
			border-radius: 50%;
			border: 2px solid $color-primary;
			background-color: $color-white;
			pointer-events: auto;
			appearance: none;
			cursor: pointer;
			margin-bottom: 1px;
		}

		&::-moz-range-thumb {
			height: 2rem;
			width: 2rem;
			border-radius: 50%;
			border: 2px solid $color-primary;
			background-color: $color-white;
			pointer-events: auto;
			appearance: none;
			cursor: pointer;
			margin-top: 30%;
		}
	}
}
</style>
