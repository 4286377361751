import type { BestAccommodationOfferForHotelList } from '@/interfaces/api/v1-best-accommodation-offer-for-hotel-list';
import { BestPackageOfferForHotelList } from '@/interfaces/api/v1-best-package-offer-for-hotel-list';
import { InsuranceOffer } from '@/interfaces/checkout/insurance-data';
import type { BaseOfferForHotelList } from '@/interfaces/common';
import { boolean } from 'zod';

/**
 * Type guard also known as a runtime check or type predicate https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
*/
export function isBestAccommodationOfferForHotelList(offer: BaseOfferForHotelList): offer is BestAccommodationOfferForHotelList {
	return (offer as BestAccommodationOfferForHotelList)?.AccommodationOffer !== undefined;
}

/**
 * Type guard also known as a runtime check or type predicate https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
*/
export function isBestPackageOfferForHotelList(offer: BaseOfferForHotelList): offer is BestPackageOfferForHotelList {
	return (offer as BestPackageOfferForHotelList)?.PackageOffer !== undefined;
}

export function isInsuranceOffer(insuranceOffer: InsuranceOffer | boolean): insuranceOffer is InsuranceOffer {
	return typeof insuranceOffer !== 'boolean';
}

