import { SearchFormDataType } from '@/interfaces/search-form';
import { TourOperatorInventoryResponse } from '@/interfaces/api/v1-touroperator-inventory';
import request from '@components/common/services/request';
import axios, { AxiosError } from 'axios';
import { useStore } from '../store';

// NOTE: Temporary excludes for the inventory requests till API also sends items with count 0
const TEMPORARY_EXCLUDES = [
	'BoardTypes',
	'RoomTypes',
	'HotelCategory',
	'MinMeanRecommendationRate',
	'MaxPrice',
	'RatingAttributes',
	'HotelAttributes',
	'TourOperatorCodes',
];

const HOTEL_EXCLUDES = ['DepartureAirport', 'DirectFlight', 'Transfer'];
const COMMON_EXCLUDES = ['DefiniteStartDate', 'TourOperatorCodes', 'SortOrder'];

export const fetchTourOperatorInventory = (bodyParams: Record<string, any>, excludes: string[] = []): void => {
	const store = useStore();
	const { touroperatorInventory: { packages, hotels } } = store.state.config;
	const formData: SearchFormDataType = store.state.searchMask;
	const apiData = (override?: any, exclude?: string[]) => store.getters['searchMask/apiData'](override, exclude);
	const travelType = formData.onlyHotel ? 'hotel' : 'package';
	const url = formData.onlyHotel ? hotels : packages;
	const exclude = [...(travelType === 'hotel' ? HOTEL_EXCLUDES : []), ...COMMON_EXCLUDES, ...excludes, ...TEMPORARY_EXCLUDES];

	const body = apiData(bodyParams, exclude);

	request<TourOperatorInventoryResponse>({ url, data: body }, 'tourOperatorOfferList')
		.then((resp) => {
			if (!resp) {
				return;
			}
			store.dispatch('updateTypes', {
				operator: resp.TourOperators,
			});
		})
		.catch((axiosError: AxiosError) => {
			if (!axios.isCancel(axiosError)) {
				console.error('Touroperator: ', axiosError);
			}
		})
		.finally(() => {
			store.dispatch('updateTourOperatorLoading', false);
		});
};
