import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["href", "title"]
const _hoisted_3 = ["title"]
const _hoisted_4 = {
  key: 1,
  class: "rpb_offergrid__content--slider-overlay"
}
const _hoisted_5 = {
  key: 0,
  class: "rpb_offergrid__bottom-cta"
}

import Hotelbox from '@lmt-rpb/Hotelbox/Hotelbox.vue';
import RpbButton from '@lmt-rpb/RpbButton/RpbButton.vue';
import ComponentHeader from '@lmt-rpb/ComponentHeader/ComponentHeader.vue';
import type { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';
import type { HotelBoxData } from '@/interfaces/components/hotelBoxData';

interface Props {
	hotelList: Array<HotelBoxData>,
	currencySymbol?: string,
	showSuns?: boolean,
	headerProps: HeaderProps,
	headline?: string,
	showBottomCTA?: boolean,
	bottomCTAText?: string,
	slider?: boolean,
	destinationName?: string,
	deal?: boolean,
	fourColumns?: boolean,
	moreLink?: string,
	hasPromotionAnalytics?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OfferGrid',
  props: {
    hotelList: {},
    currencySymbol: { default: 'EUR' },
    showSuns: { type: Boolean, default: true },
    headerProps: {},
    headline: { default: '' },
    showBottomCTA: { type: Boolean, default: false },
    bottomCTAText: { default: '' },
    slider: { type: Boolean, default: true },
    destinationName: { default: '' },
    deal: { type: Boolean, default: false },
    fourColumns: { type: Boolean, default: false },
    moreLink: { default: '' },
    hasPromotionAnalytics: { type: Boolean, default: true }
  },
  emits: ["hotelBoxClick"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

function hotelClick(index: number, hotel: HotelBoxData) {
	emit('hotelBoxClick', { index, hotelData: hotel });
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", {
    id: _ctx.slider ? 'rpb_horizontal-scroller-vue' : 'rpb_horizontal-grid',
    class: _normalizeClass(["rpb_container", _ctx.slider ? 'rpb_container--slider' : ''])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["rpb_offergrid", [{ 'rpb_offergrid--deals': _ctx.deal }]])
    }, [
      _createVNode(ComponentHeader, { "header-props": _ctx.headerProps }, null, 8 /* PROPS */, ["header-props"]),
      _createElementVNode("div", {
        class: _normalizeClass(["rpb_offergrid__content", [{'rpb_offergrid__content--slider': _ctx.slider }, { 'rpb_offergrid--4columns': _ctx.fourColumns }]])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hotelList, (hotel, index) => {
          return (_openBlock(), _createBlock(Hotelbox, {
            key: hotel.HotelIffCode,
            hotel: hotel,
            "show-suns": _ctx.showSuns,
            "currency-symbol": _ctx.currencySymbol,
            slider: _ctx.slider,
            deal: _ctx.deal,
            "has-promotion-analytics": _ctx.hasPromotionAnalytics,
            "promotion-name": _ctx.headerProps.mobileTitle || _ctx.headerProps.title,
            index: index,
            onMousedown: ($event: any) => (hotelClick(index, hotel))
          }, null, 8 /* PROPS */, ["hotel", "show-suns", "currency-symbol", "slider", "deal", "has-promotion-analytics", "promotion-name", "index", "onMousedown"]))
        }), 128 /* KEYED_FRAGMENT */)),
        (_ctx.slider && _ctx.moreLink)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.moreLink,
              class: "rpb_hotelbox rpb_hotelbox__placeholder",
              title: _ctx.headerProps.titleAttribute
            }, [
              _createElementVNode("span", {
                class: "rpb_btn--secondary rpb_btn--slider",
                title: _ctx.headerProps.titleAttribute
              }, "Alle Angebote", 8 /* PROPS */, _hoisted_3)
            ], 8 /* PROPS */, _hoisted_2))
          : _createCommentVNode("v-if", true),
        (_ctx.slider)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */)
    ], 2 /* CLASS */),
    (_ctx.showBottomCTA)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(RpbButton, {
            "rpb-button-class": 'rpb_button rpb_button--azure',
            text: _ctx.bottomCTAText,
            link: _ctx.moreLink,
            "rel-attr": "nofollow"
          }, null, 8 /* PROPS */, ["text", "link"])
        ]))
      : _createCommentVNode("v-if", true)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}
}

})