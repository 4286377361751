import { defineComponent as _defineComponent } from 'vue'
import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  ref: "list",
  class: "autocomplete__list",
  role: "group",
  "aria-label": "Ergebnisse",
  tabindex: "-1"
}
const _hoisted_2 = { class: "autocomplete__load-box" }
const _hoisted_3 = {
  class: "autocomplete__item-header",
  "aria-hidden": "true"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "autocomplete__search-history-destination" }
const _hoisted_6 = { class: "autocomplete__search-history-departure" }
const _hoisted_7 = { class: "autocomplete__search-history-person" }
const _hoisted_8 = { class: "autocomplete__search-history-duration" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "autocomplete__item-sublabel" }

import topPlacesData from '@json/topPlaces.json';
import axios, {
	AxiosError,
	Canceler,
	AxiosResponse,
} from 'axios';
import {
	debounce,
	isOfferlistPage,
	formatDateInterval,
	pluralize,
	getTravelDuration,
} from '@utils/utils';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Loading from '@lmt-rpb/Loading/Loading.vue';
import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import { SuggestionDataType, FullSuggest, SearchFormDataType } from '@interfaces/search-form';
import { EventBus } from '@global-js/event-bus';
import {
	computed, onBeforeUnmount, onMounted, ref, watch, nextTick,
} from 'vue';
import { useStore } from '@/components/common/store';
import * as searchHistoryService from '@/components/common/services/localStorage/searchHistoryService';
import airportData from '@/components/common/store/items/airports';

interface Props {
	url: string,
	label: string,
	filter?: any,
	minLength?: number,
	inline?: boolean,
	icon?: string,
	modelValue?: any,
	errorDuration?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Autocomplete',
  props: {
    url: {},
    label: {},
    filter: { default: (data: any) => data },
    minLength: { default: 2 },
    inline: { type: Boolean, default: false },
    icon: { default: '' },
    modelValue: { default: {} },
    errorDuration: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { expose: __expose, emit: __emit }) {


const store = useStore();

const props = __props;

const term = ref<string>('');

const searchTerm = ref<string>('');

const open = ref<boolean | undefined>(props.inline);

const items = ref<FullSuggest>({});

const loading = ref(false);

const error = ref<AxiosError | null>(null);

const abort = ref<Canceler>();

const activeIndex = ref(0);

const activeItem = ref<SuggestionDataType>({});

const focus = ref(false);

const dropdown = ref<InstanceType<typeof DropdownField> | null>(null);

const formField = ref<InstanceType<typeof FormField> | null>(null);

const itemList = ref<HTMLElement>();

const input = ref<HTMLInputElement>();

const defaultAutosuggestsLoaded = ref(false);

const initialLoad = ref(true);

const emit = __emit;

const minCharCount = computed((): boolean => !!term.value && term.value.length >= props.minLength);

const pageType = computed((): string => store.state.config.pageType);

const placeholderText = computed((): string => (!focus.value && 'Beliebig') || '');

const itemsArray = computed(() : SuggestionDataType[] => Object.keys(items.value).reduce((acc: SuggestionDataType[], cat: string) => {
	const category = (items.value as {[key: string]: SuggestionDataType[]})[cat];
	return [...acc, ...category];
}, []));

let requestAvailableHotels = true;

const getSearchHistory = async() => {
	loading.value = true;
	const searchHistory = await searchHistoryService.getThreeReversed(requestAvailableHotels);
	loading.value = false;
	requestAvailableHotels = false;
	return searchHistory;
};

const getDefaultItems = async(): Promise<FullSuggest> => {
	const searchHistory = await getSearchHistory();
	const defaultItems: FullSuggest = {
		searchHistory,
		RegionGroups: topPlacesData.RegionGroups as SuggestionDataType[],
	};
	if (!searchHistory.length) {
		delete defaultItems.searchHistory;
	}
	return defaultItems;
};

const getAirportLabels = (formData: SearchFormDataType): string => {
	let result = '';
	if (!formData.onlyHotel && formData.departure.length) {
		result = 'Flug ab ' + airportData.filter((airport) => formData.departure.includes(airport.value)).map((a) => a.label).join(', ');
	} else if (!formData.onlyHotel) {
		result = 'Inkl. Flug';
	} else {
		result = 'Eigene Anreise';
	}
	return result;
};

const onFocus = (): void => {
	focus.value = true;
};

const onBlur = (): void => {
	focus.value = false;
};

const onNavigate = (direction: number) => {
	nextTick(() => {
		const list = itemList.value as HTMLElement;

		if (!list) {
			return;
		}
		const itemsArr: HTMLElement[] = Array.from(list.querySelectorAll('.autocomplete__item'));
		activeIndex.value = Math.min(Math.max(0, activeIndex.value + direction), itemsArr.length - 1);
		const top = itemsArr[activeIndex.value].offsetTop;
		(list as HTMLElement).scrollTo({ top, behavior: 'smooth' });
	});
};

const flattenedItemsIndex = (actualKey: string, actualIndex: number): number => {
	let destinationCount = 0;

	// eslint-disable-next-line no-restricted-syntax
	for (const [key, destinationArray] of Object.entries(items.value)) {
		if (key === actualKey) {
			destinationCount += actualIndex;
			break;
		}
		destinationCount += destinationArray ? destinationArray.length : 0;
	}

	return destinationCount;
};

const buildUrlWithId = (item: SuggestionDataType): string => {
	const type = item.Type || item.type;
	const id = item.ID || item.id;

	if (!id || !type) {
		return '';
	}

	let url = '';

	if (type === 'region_group') {
		url = `/region/g/${id}/`;
	} else if (type === 'region') {
		url = `/hotels/r/${id}/`;
	} else if (type === 'city') {
		url = `/hotels/o/${id}/`;
	} else {
		url = `/hotel/${id}/`;
	}

	return url;
};

const itemKeysToLowerCase = (item: SuggestionDataType): SuggestionDataType => {
	const lowerCaseItemKeys: SuggestionDataType = {};

	// eslint-disable-next-line no-restricted-syntax
	for (const [key, value] of Object.entries(item)) {
		lowerCaseItemKeys[key.toLowerCase()] = value;
	}

	return lowerCaseItemKeys;
};

const getIdFromUrl = (): number => {
	if (!isOfferlistPage()) {
		return 0;
	}

	let id = null;

	// get id from location path
	const pathArray = window.location.pathname.split('/').filter((fraction) => fraction);
	const onlyNumbers = new RegExp('^[0-9]+$');

	pathArray.forEach((path) => {
		if (path.match(onlyNumbers)) {
			id = +path;
		}
	});

	return id || 0;
};

const getIdFromStore = (): number | false => {
	const regionTypeIds = {
		/* eslint-disable quote-props */
		'page_country': 'rgid',
		'page_region': 'rid',
		'page_city': 'cyid',
	};
	let destinationIdSelector = '',
		destinationId = 0;

	// eslint-disable-next-line no-restricted-syntax
	for (const [key, value] of Object.entries(regionTypeIds)) {
		if (document.body.classList.contains(key)) {
			destinationIdSelector = value;
		}
	}

	const regionString = store.state.config[destinationIdSelector];

	if (regionString) {
		destinationId = parseInt(regionString, 10);
		destinationId += destinationIdSelector === 'rgid' ? 40000 : 0; // add 40000 for region groups
	}

	return destinationId || false;
};

const updateBySearchHistoryEntry = (searchParams: SearchFormDataType) => {
	store.commit('searchMask/updateFormData', {
		destination: searchParams.destination,
		departure: searchParams.departure,
		travelDuration: searchParams.travelDuration,
		travelers: searchParams.travelers,
		offerDuration: searchParams.offerDuration,
		onlyHotel: searchParams.onlyHotel,
	});
};

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const closeDropdown = (): void => {
	open.value = false;
};

const commitTerm = (): void => {
	store.commit('searchMask/updateFormData', {
		searchTerm: searchTerm.value,
	});
};

const isSearchFormDataType = (item: SuggestionDataType | SearchFormDataType): item is SearchFormDataType => ((item as SearchFormDataType).destination !== undefined);

const selectItem = (item: SuggestionDataType | SearchFormDataType): void => {
	if (isSearchFormDataType(item)) {
		updateBySearchHistoryEntry(item);
	} else {
		// eslint-disable-next-line no-param-reassign
		item.url = buildUrlWithId(item);
		const transformedItem = itemKeysToLowerCase(item);

		model.value = transformedItem;
	}
	closeDropdown();

	if (isOfferlistPage() && store.state.config.isDesktop) {
		EventBus.$emit('search:submit');
	}
};

const clearTerm = (): void => {
	term.value = model.value?.label || null;
	activeItem.value = {};
};

const selectItemBy = (index: number): void => {
	if (Object.keys(items.value).length) {
		selectItem(itemsArray.value[index] || itemsArray.value[0]);
	} else {
		clearTerm();
	}
};

const selectFirst = (): void => {
	if (minCharCount.value) {
		selectItemBy(activeIndex.value);
	}
};

const selectItemByLabel = (label: string | undefined): void => {
	if (!label) {
		return;
	}

	if (Object.keys(items.value).length) {
		const active = itemsArray.value.find((item) => item.Label === label);
		selectItem(active || itemsArray.value[0]);
		activeItem.value = active ? itemKeysToLowerCase(active) : itemKeysToLowerCase(itemsArray.value[0]);
	} else {
		clearTerm();
	}
};

const selectItemById = (id: number | false): void => {
	if (!id) {
		return;
	}

	if (Object.keys(items.value).length) {
		const currentDestination: SuggestionDataType | undefined = itemsArray.value.find((item) => item.ID === +id);
		if (currentDestination) {
			currentDestination.url = buildUrlWithId(currentDestination);
		}

		activeItem.value = currentDestination ? itemKeysToLowerCase(currentDestination) : itemKeysToLowerCase(itemsArray.value[0]);
	} else {
		clearTerm();
	}
};

// Resetting to correct value
const resetTerm = (value: any): void => {
	model.value = value;
	term.value = value?.label || null;
};

const getCategoryName = (category: string): string => ({
	Countries: 'Land',
	Hotels: 'Hotel',
	Cities: 'Stadt',
	Regions: 'Region',
	RegionGroups: 'Destinationen',
	searchHistory: 'Zuletzt gesucht',
} as {[key: string]: string})[category];

const clearInput = async(): Promise<void> => {
	input.value?.focus();

	if (open.value) {
		items.value = await getDefaultItems();
	}
	if (term.value) {
		term.value = '';
	}
	if (model.value) {
		model.value = null;
	}
};
const search = (): void => {
	loading.value = true;
	error.value = null;

	const url = `${props.url}?term=${encodeURIComponent(term.value)}`;

	// if previous search still in progress abort it
	if (abort.value) {
		abort.value();
	}

	axios.get(url, {
		cancelToken: new axios.CancelToken((abortCanceler: Canceler) => {
			abort.value = abortCanceler;
		})
	})
		.then(({ data }: AxiosResponse) => data)
		.then((data: FullSuggest) => {
			const filteredItems: FullSuggest = {};
			// eslint-disable-next-line no-restricted-syntax
			for (const [key, value] of Object.entries(data)) {
				if (value && value.length) {
					filteredItems[key] = value;
				}
			}
			items.value = filteredItems;
		})
		.catch((err: AxiosError) => {
			// ignore abort error
			if (err instanceof axios.Cancel) {
				return;
			}

			error.value = err;
		})
		.finally(() => {
			loading.value = false;
			if (initialLoad.value) {
				initialLoad.value = false;
				// Setting the correct item as target destination on initial load
				// to set the URL if user doesn't change anything (on region and city pages)
				if (['regionPage'].indexOf(pageType.value) !== -1 && !document.body.classList.contains('page_country')) {
					selectItemById(getIdFromStore());
				}

				// Getting wrong rgid from typo3 data on some countries, e.g. Spain
				if (document?.body.classList.contains('page_country')) {
					selectItemByLabel(term.value);
				}

				// Get the correct destination (not just the label)
				// for list pages on mobile to prevent unnecessary redirects
				if (
					!store.state.config.isDesktop
						&& ['regionList', 'hotelList', 'hotelPage'].indexOf(pageType.value) !== -1
				) {
					selectItemById(getIdFromUrl());
				}
				if (activeItem.value?.id) {
					store.commit('searchMask/updateFormData', {
						destination: activeItem.value,
					});
					store.dispatch('updateProxies', { initialDestination: activeItem.value });
					EventBus.$emit('Autocomplete:updatedActiveItem');
				}
			}

			if (activeItem.value?.id) {
				if (Object.keys(items.value).length) {
					const isCurrentDestination = (element: SuggestionDataType) => element.ID === activeItem.value?.id;
					activeIndex.value = itemsArray.value.findIndex(isCurrentDestination);
				}
			} else {
				activeIndex.value = 0;
			}
		});
};

const onTermChange = (): void => {
	error.value = null;
	if (!minCharCount.value) {
		getDefaultItems().then((defaultItems) => { items.value = defaultItems; });
	} else {
		search();
	}
};

const openDropdown = (): void => {
	onTermChange();
	open.value = true;
};

const onKeydown = (e: KeyboardEvent): void => {
	const limited = ['Tab', 'Shift'];

	if (!props.errorDuration) {
		if (!open.value && focus.value && limited.indexOf(e.key) === -1) {
			openDropdown();
			input.value?.focus();
		} else if (open.value && ['Enter', 'Tab'].indexOf(e.key) !== -1) {
			selectItemBy(activeIndex.value);
			closeDropdown();
		}
	}
};

const onInput = (): void => {
	if (input.value?.value && input.value.value !== '') {
		searchTerm.value = input.value.value;
		term.value = input.value.value;
	}
	if (input.value?.value === '') {
		store.commit('searchMask/updateFormData', { destination: null });
	}
	commitTerm();
	onTermChange();
};

const onFieldClick = (clickOnField: boolean) => {
	if (clickOnField && !open.value && !props.errorDuration) {
		input.value?.focus();
		openDropdown();
	}
};

const onOutsideClick = (): void => {
	selectFirst();
	closeDropdown();
};

watch(() => model.value, () => {
	if (model.value && model.value.label) {
		term.value = model.value.label;
	}
}, { immediate: true });

onMounted((): void => {
	formField.value = dropdown.value?.field as any;
	input.value = formField.value?.input as HTMLInputElement;

	document.addEventListener('keydown', onKeydown);
	input.value?.addEventListener('input', debounce(onInput, 400));
	if (['regionPage', 'hotelPage'].indexOf(pageType.value) !== -1) {
		if (!defaultAutosuggestsLoaded.value) {
			search();
			defaultAutosuggestsLoaded.value = true;
		}
		EventBus.$on('loadDefaultAutosuggests', search);
	}
});

onBeforeUnmount((): void => {
	document.removeEventListener('keydown', onKeydown);
	input.value?.removeEventListener('input', onInput);

	if (['regionPage', 'hotelPage'].indexOf(pageType.value) !== -1) {
		EventBus.$off('loadDefaultAutosuggests', search);
	}
});

__expose({
	selectFirst,
	resetTerm,
	clearInput,
	openDropdown,
	input,
	open,
	dropdown
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "autocomplete",
    onFocusCapture: onFocus
  }, [
    _createVNode(DropdownField, {
      ref_key: "dropdown",
      ref: dropdown,
      modelValue: term.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((term).value = $event)),
      tabindex: "-1",
      class: "autocomplete__dropdown",
      label: _ctx.label,
      readonly: false,
      manual: open.value,
      "close-on-esc": false,
      "show-footer": false,
      "show-toggle-icon": false,
      placeholder: placeholderText.value,
      parent: 'autocomplete',
      icon: _ctx.icon,
      "prevent-mousedown": false,
      "allow-clear": true,
      "max-travel-duration-error": _ctx.errorDuration,
      "onDropdownField:FieldClick": onFieldClick,
      "onDropdownField:OutsideClick": onOutsideClick,
      "onDropdownField:Focus": onFocus,
      "onDropdownField:Blur": onBlur,
      "onDropdownField:Navigate": onNavigate,
      "onDropdownField:Clear": clearInput
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (loading.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createCommentVNode(" Loading "),
                _renderSlot(_ctx.$slots, "loading", {}, () => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(Loading, {
                      size: "small",
                      class: "autocomplete__loader"
                    })
                  ])
                ])
              ], 64 /* STABLE_FRAGMENT */))
            : (error.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createCommentVNode(" Error "),
                  _renderSlot(_ctx.$slots, "error", {
                    error: error.value,
                    term: term.value
                  }, () => [
                    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "autocomplete__error" }, [
                      _createElementVNode("p", null, "Der Server ist im Augenblick nicht erreichbar."),
                      _createTextVNode(" Bitte versuchen Sie es in Kürze erneut. ")
                    ], -1 /* HOISTED */))
                  ])
                ], 64 /* STABLE_FRAGMENT */))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  (minCharCount.value && !Object.keys(items.value).length)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createCommentVNode(" No result "),
                        _renderSlot(_ctx.$slots, "no-result", {}, () => [
                          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "autocomplete__helper" }, " Keine Ergebnisse gefunden. ", -1 /* HOISTED */))
                        ])
                      ], 64 /* STABLE_FRAGMENT */))
                    : _renderSlot(_ctx.$slots, "result", { key: 1 }, () => [
                        _createCommentVNode(" Result list "),
                        _createElementVNode("ul", {
                          ref_key: "itemList",
                          ref: itemList,
                          class: "autocomplete__item-list"
                        }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (category, key) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                              _createCommentVNode(" Category name "),
                              _createElementVNode("li", _hoisted_3, _toDisplayString(minCharCount.value || key === 'searchHistory'? getCategoryName(`${key}`) : 'Beliebteste Destinationen'), 1 /* TEXT */),
                              _createCommentVNode(" Result item "),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category, (destination, index) => {
                                return (_openBlock(), _createElementBlock(_Fragment, {
                                  key: `${key}-${index}`
                                }, [
                                  (isSearchFormDataType(destination))
                                    ? (_openBlock(), _createElementBlock("li", {
                                        ref_for: true,
                                        ref: "item",
                                        key: `${key}-${index}`,
                                        class: _normalizeClass([{'is-active': flattenedItemsIndex(key, index) === activeIndex.value }, "autocomplete__item"]),
                                        role: "button",
                                        onClick: ($event: any) => (selectItem(destination))
                                      }, [
                                        _createElementVNode("div", _hoisted_5, _toDisplayString(destination.destination?.label), 1 /* TEXT */),
                                        _createElementVNode("div", _hoisted_6, _toDisplayString(getAirportLabels(destination)), 1 /* TEXT */),
                                        _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(pluralize)(destination.travelers.adult, "Erwachsener", "Erwachsene")) + " " + _toDisplayString(destination.travelers.children.length ? ', ' + _unref(pluralize)(destination.travelers.children.length, 'Kind', 'Kinder') : ''), 1 /* TEXT */),
                                        _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(formatDateInterval)(destination.offerDuration.from, destination.offerDuration.to)) + ", " + _toDisplayString(_unref(getTravelDuration)(destination.travelDuration)), 1 /* TEXT */)
                                      ], 10 /* CLASS, PROPS */, _hoisted_4))
                                    : (_openBlock(), _createElementBlock("li", {
                                        key: 1,
                                        ref_for: true,
                                        ref: "item",
                                        role: "button",
                                        class: _normalizeClass(["autocomplete__item", {'is-active': flattenedItemsIndex(key, index) === activeIndex.value }]),
                                        onClick: ($event: any) => (selectItem(destination))
                                      }, [
                                        _createTextVNode(_toDisplayString(destination.Label) + " ", 1 /* TEXT */),
                                        _createElementVNode("span", _hoisted_10, _toDisplayString(destination.SubLabel), 1 /* TEXT */)
                                      ], 10 /* CLASS, PROPS */, _hoisted_9))
                                ], 64 /* STABLE_FRAGMENT */))
                              }), 128 /* KEYED_FRAGMENT */))
                            ], 64 /* STABLE_FRAGMENT */))
                          }), 128 /* KEYED_FRAGMENT */))
                        ], 512 /* NEED_PATCH */)
                      ])
                ], 64 /* STABLE_FRAGMENT */))
        ], 512 /* NEED_PATCH */)
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["modelValue", "label", "manual", "placeholder", "icon", "max-travel-duration-error"])
  ], 32 /* NEED_HYDRATION */))
}
}

})