import type { ClientType, OfferFeatures, SingleFeature } from '@/interfaces/common';
import { NBSP, NDASH } from '@global-js/constants';
import austrianWordings from '@assets/i18n/at-wordings.json';
import germanWordings from '@assets/i18n/de-wordings.json';
import swissWordings from '@assets/i18n/ch-wordings.json';
import OfferFlex from '@/interfaces/checkout/offer-flex';
import type { Storno } from '@/components/common/types';
import { scrollIt as scrollItLib } from '../../../../all/js/libs/scrollIt_module';

export type DateInput = Date | string | number | undefined | null;

export const scrollIt = (htmlElement: HTMLElement | number, animationTime?: number, animationName?: string, offset = 0, callback: any = null) => {
	// https://dev.to/natclark/checking-for-reduced-motion-preference-in-javascript-4lp9
	const noAnimation = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

	if (noAnimation) {
		scrollItLib(htmlElement, 0, animationName, offset, callback);
	} else {
		scrollItLib(htmlElement, animationTime, animationName, offset, callback);
	}
};

/**
 * @param storeState creates a deep copy of an store state obj with an initial state.
 */
/* eslint-disable-next-line arrow-body-style */
export const createStoreCopy = <T extends object>(storeState: T): T => JSON.parse(JSON.stringify(storeState));
/**
 * @param store The store object to be restored(original store object).
 * @param copy deep copy for initial state from store obtained using 'createStoreCopy'.
 */

/* eslint-disable no-param-reassign */
export const restoreStore = <T extends object>(store: T, copy: T): void => {
	Object.keys(store).forEach((key) => {
		store[key as keyof T] = copy[key as keyof T];
	});
};

// eslint-disable-next-line arrow-body-style
export const groupBy = (arr: any[], key: string): any => {
	return arr.reduce((acc, curr) => ({
		...acc,
		[curr[key]]: (acc[curr[key]] || []).concat(curr)
	}), {});
};

// eslint-disable-next-line arrow-body-style
export const rangeArray = (min: number, max: number): number[] => {
	return [...Array((max + 1) - min)].map((_: number, index: number) => min + index);
};

export const convertDate = (value: string | number): Date => {
	if (typeof value === 'string' && !Date.parse(value)) {
		const dateTime = value.replace(' ', 'T');

		return new Date(dateTime);
	}

	return new Date(value);
};

export const convertDateToTime = (value: string | null | undefined): string => {
	if (value === null || value === undefined) return '';
	let time = '';
	const dateSplit = value.split(' ');
	if (dateSplit.length === 2) {
		const timeSplit = dateSplit[1].split(':');
		if (timeSplit.length === 3 || timeSplit.length === 2) {
			time = timeSplit[0] + ':' + timeSplit[1];
		}
	}
	if (dateSplit.length >= 4) {
		const timeSplit = dateSplit[4].split(':');
		if (timeSplit.length === 3 || timeSplit.length === 2) {
			time = timeSplit[0] + ':' + timeSplit[1];
		}
	}
	return time;
};

export const now = (formatNumber?: boolean): Date | number => {
	const date = new Date();

	if (formatNumber) {
		return date.setHours(0, 0, 0, 0);
	}

	return date;
};

export const formatDate = (date: DateInput, format: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit' }, locale = 'de-AT'): string => {
	if (!date) {
		return '';
	}

	const intl = new Intl.DateTimeFormat(locale, format);
	const d = date instanceof Date ? date : convertDate(date);

	try {
		return intl.format(d);
	} catch {
		return '';
	}
};
export const formatDateWeekday = (date: DateInput): string => {
	if (!date) {
		return '';
	}
	const dateWeekday = date && formatDate(new Date(date), { weekday: 'short' });

	const dateFormatted = (date && formatDate(new Date(date), { month: '2-digit', day: '2-digit' })) || 'Beliebig';
	return `${dateWeekday} ${dateFormatted}`;
};

export const formatDateWithWeekdayAndYear = (date: DateInput): string => {
	if (!date) {
		return '';
	}
	const dateWeekday = date && formatDate(new Date(date), { weekday: 'short' });
	const dateFormatted = (date && formatDate(new Date(date), { month: '2-digit', day: '2-digit', year: '2-digit' })) || 'Beliebig';
	return `${dateWeekday}. ${dateFormatted}`;
};

export const formatDateInterval = (
	from: DateInput,
	to: DateInput,
	inklusiveYear = false,
) => {
	const fromDate = inklusiveYear ? formatDateWithWeekdayAndYear(from) : formatDateWeekday(from);
	const toDate = inklusiveYear ? formatDateWithWeekdayAndYear(to) : formatDateWeekday(to);
	return `${fromDate} ${NDASH} ${toDate}`;
};

/**
 * Converts seconds to string with hours and minutes.
 * Output will be "2 h 30min"
 * @param seconds number
 */
export const convertSeconds = (seconds: number): string => {
	if (!seconds) {
		return '';
	}

	const h = Math.floor(seconds / 3600);
	const m = Math.floor((seconds % 3600) / 60);

	return `${h} h ${m} min`;
};

export const clientString = (client?: ClientType): string => {
	const _client = client || determineClient(getBaseUrl());
	const clientMap = {
		kuat: 'kuo_at',
		lmat: 'lm_at',
		at: 'rpb_at',
		com: 'rpb_com',
		ch: 'rpb_ch'
	};

	return clientMap[_client] || '';
};

export const isClient = (client: ClientType): boolean => determineClient(getBaseUrl()) === client;

/**
 * Get the client mode based on the TLD of the given url.
 * Never use it in Storybook Components. Instead pass the client as a prop
 * @param url
 */
export const determineClient = (url: string): ClientType => {
	let client: ClientType = 'at'; // AT is default
	if (url.indexOf('.com') !== -1) {
		client = 'com';
	} else if (url.indexOf('.ch') !== -1) {
		client = 'ch';
	} else if (url.indexOf('.kuoni.at') !== -1) {
		client = 'kuat';
	} else if (url.indexOf('.lastminute.at') !== -1) {
		client = 'lmat';
	}

	return client;
};

export const getLocaleString = (i18Property: keyof typeof austrianWordings): string => {
	let localeJSON = austrianWordings;

	const client = determineClient(window.location.host);

	if (client === 'com') {
		localeJSON = germanWordings;
	}

	if (client === 'ch') {
		localeJSON = swissWordings;
	}

	return localeJSON[i18Property];
};

export const dateRange = (from: Date | number, to: Date | number): Date[] => {
	const dates = [];
	// convert back to date
	const f = new Date(from);
	const t = new Date(to);

	while (f <= t) {
		dates.push(new Date(f));
		f.setDate(f.getDate() + 1);
	}

	return dates;
};

export const dateDiff = (aDate: number | string | Date, bDate: number | string | Date, type = 'day'): number => {
	const a = new Date(aDate);
	const b = new Date(bDate);

	if (type === 'day') {
		const date1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
		const date2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

		return (date2 - date1) / (1000 * 60 * 60 * 24); // ms / day;
	}

	// month diff
	const yearDiff = b.getFullYear() - a.getFullYear();
	return (b.getMonth() - a.getMonth()) + (yearDiff * 12);
};

export const offsetDate = (date: Date | string | number, offsetDay: number): Date => {
	const d = new Date(date);
	d.setDate(d.getDate() + Number(offsetDay));

	return d;
};

export const isEqual = (...objects: Record<string, any>[]): boolean => objects.every((obj) => JSON.stringify(obj) === JSON.stringify(objects[0]));

export const cloneDeep = <T>(target: T): T => JSON.parse(JSON.stringify(target));

// helper to get a value from a deep object
// eslint-disable-next-line arrow-body-style
export const get = (path: string, obj: Record<string, any>): any => {
	// eslint-disable-next-line no-new-func
	return new Function('_', 'return _.' + path)(obj);
};

// eslint-disable-next-line arrow-body-style
export const isPlainObject = (obj: any): boolean => {
	return Object.prototype.toString.call(obj) === '[object Object]';
};

// eslint-disable-next-line arrow-body-style
export const deepMerge = (target: Record<string, any>, ...source: { [key: string]: any }[]): Record<string, any> => {
	return source.reduce((acc, current: { [key: string]: any }) => {
		Object.keys(current).forEach((key) => {
			if (Array.isArray(acc[key]) && Array.isArray(current[key])) {
				acc[key] = acc[key].concat(current[key]).filter((item: any, index: number, srcArray: any[]) => srcArray.indexOf(item) === index);
			} else if (isPlainObject(acc[key]) && isPlainObject(current[key])) {
				acc[key] = deepMerge(acc[key], current[key]);
			} else if ([null, undefined].indexOf(current[key]) === -1) {
				acc[key] = current[key];
			}
		});

		return { ...acc };
	}, cloneDeep(target) as { [key: string]: any });
};

export const throttle = (fn: Function, wait: number): any => {
	let isCalled = false;
	let timer: any;

	return (...args: any[]): any => {
		if (!isCalled) {
			fn(...args);
			isCalled = true;
			setTimeout(() => { isCalled = false; }, wait);
		} else {
			if (timer) {
				clearTimeout(timer);
			}

			// call event last time because it may blocked
			timer = setTimeout(() => { fn(...args); }, wait);
		}
	};
};

export const debounce = (fn: (args: any[]) => void, delay: number): any => {
	let timeoutID: null | ReturnType<typeof setTimeout> = null;

	return (...args: any[]): any => {
		if (timeoutID) {
			clearTimeout(timeoutID);
		}
		timeoutID = setTimeout(() => {
			fn(args);
		}, delay);
	};
};

type PageTypes = 'hotelPage' | 'regionPage' | 'searchLandingPage' | 'homePage' | 'regionList' | 'hotelList' | 'servicePage' | 'themePage';
export const determinePageType = (): PageTypes => {
	const bodyClassList = document.body.classList;
	const searchIcon = document.getElementById('rpb_header-icon-search');

	if (bodyClassList.contains('page_hotel')) return 'hotelPage';
	if (bodyClassList.contains('page_country')
		|| bodyClassList.contains('page_region')
		|| bodyClassList.contains('page_city')
	) {
		return 'regionPage';
	}
	if (bodyClassList.contains('page_search_landing')) return 'searchLandingPage';
	if (bodyClassList.contains('page_home')) return 'homePage';
	if (bodyClassList.contains('page_region_list')) return 'regionList';
	if (bodyClassList.contains('page_hotel_list')) return 'hotelList';

	// workaround - body tag for service page needed
	if (searchIcon && searchIcon.childElementCount === 0) return 'servicePage';
	// workaround - body tag for theme page needed
	return 'themePage';
};

export const getpaymentTypesUrl = (client: string): string => `https://booking.${client}.at/ibecustomer/whitelabel/img/icons`;

/**
 * Helper function for defining offerlist pages
 * Returns true if current page is offerlist
 * Add additional offerlist pages to const offerlistPages
 *
 * @return boolean
 */
export const isOfferlistPage = (): boolean => {
	const offerlistPages = ['hotelPage', 'hotelList', 'regionList', 'searchLandingPage'];	// For additional offerlist pages in the future
	const offerlistPage = offerlistPages.indexOf(determinePageType()) > -1;

	return offerlistPage;
};

/**
 * Scrolling to offerlist and open specific tab
 * @param dataTarget string of the data-target attribute
 * @param contentId string of the content id
 */
export const showOfferlist = (selector: string, offset = 0, time = 500): void => {
	setTimeout(() => {
		scrollIt(
			document.querySelector(selector) as HTMLElement,
			time,
			'easeOutQuad',
			offset
		);
	}, 50);
};

/**
 * Get the base TT IBE URL ("https://<domain>/") from
 * the project environment "ibeBaseUrls" object.
 */
export const getIbeBaseUrl = (): string => {
	const client = determineClient(window.location.host);
	let baseUrl = '';
	if (!process.env.ibeBaseUrls) {
		throw new Error('ibeBaseUrls is not defined!');
	} else {
		baseUrl = JSON.parse(process.env.ibeBaseUrls)[client];
	}

	return baseUrl;
};

/**
 * Get the base URL ("https://<domain>/") from
 * the project environment "baseUrls" object.
 */
export const getBaseUrl = (client: string = determineClient(window.location.host)): string => {
	let baseUrl = '';
	if (!process.env.baseUrls) {
		throw new Error('baseUrls is not defined!');
	} else {
		baseUrl = JSON.parse(process.env.baseUrls)[client];
	}
	return baseUrl;
};

export const getBasePath = (client: string = determineClient(window.location.host)): string => {
	let basePath = getBaseUrl(client);
	switch (client) {
	case 'kuat':
		basePath += '/fileadmin/2/kuoni';
		break;
	case 'lmat':
		basePath += '/fileadmin/2/lastminute';
		break;
	default:
		basePath += '/fileadmin/2/restplatzboerse/all';
		break;
	}
	return basePath;
};

export const getWebsiteLogoPath = (client: string = determineClient(window.location.host)):string => {
	let basePath = getBaseUrl(client);
	switch (client) {
	case 'kuat':
		basePath += '/fileadmin/2/kuoni';
		break;
	case 'lmat':
		basePath += '/fileadmin/2/lastminute';
		break;
	case 'com':
		basePath += '/fileadmin/2/restplatzboerse/de';
		break;
	case 'ch':
		basePath += '/fileadmin/2/restplatzboerse/ch';
		break;
	default:
		basePath += '/fileadmin/2/restplatzboerse/at';
	}
	return basePath;
};

/** returns the logo of the correct website.
 */
export const getQuoteBannerLogo = (client: string = determineClient(window.location.host)): string => {
	const baseUrl = getBaseUrl();

	switch (client) {
	case 'kuat':
		return `${baseUrl}/fileadmin/2/kuoni/img/logo.svg`;
	case 'lmat':
		return `${baseUrl}/fileadmin/2/lastminute/img/logo.svg`;
	case 'at':
	case 'com':
	case 'ch':
		return `${baseUrl}/fileadmin/2/restplatzboerse/all/img/logo_plane_shadow@2x.png`;
	default:
		return '';
	}
};

export function getDefaultCurrency(client: ClientType = determineClient(window.location.host)): string {
	if (client === 'ch') {
		return 'CHF';
	}
	return 'EUR';
}

/** returns the name of website/company (Restplatzboerse, Lastminute or Kuoni)
 */
export const getWebsiteName = (clientParam?: ClientType): string => {
	let client: ClientType;

	if (clientParam) {
		client = clientParam;
	} else {
		client = determineClient(window.location.host);
	}

	switch (client) {
	case 'kuat':
		return 'kuoni.at';
	case 'lmat':
		return 'lastminute.at';
	case 'com':
		return 'restplatzboerse.com';
	case 'ch':
		return 'restplatzboerse.ch';
	default:
		return 'restplatzboerse.at';
	}
};

export const getPaymentUrl = (): string => {
	const baseUrl = getBaseUrl();

	return `${baseUrl}/fileadmin/2/restplatzboerse/all/img/payment`;
};

/**
 * Get the flux ibe base URL ("https://<domain>/") from
 * the project environment "fluxIbeUrl" variable.
 */
export const getFluxIbeUrl = (): string => {
	let fluxIbeUrl = '';
	if (!process.env.fluxIbeUrl) {
		throw new Error('fluxIbeUrl is not defined!');
	} else {
		fluxIbeUrl = process.env.fluxIbeUrl;
	}

	return fluxIbeUrl;
};

const flexCodeToStornoMap: Record<string, Storno> = {
	1: 'Cancel',
	2: 'Rebooking',
};

export const getStornoValueByFlexCode = (flexCode: string): Storno | undefined => flexCodeToStornoMap?.[flexCode];

/**
 * Get the flux image base URL ("https://<domain>/") from
 * the project environment "fluxImagesUrl" variable.
 */
export const getFluxImageUrl = (): string => {
	let fluxImagesUrl = '';
	if (!process.env.fluxImagesUrl) {
		throw new Error('fluxImagesUrl is not defined!');
	} else {
		fluxImagesUrl = process.env.fluxImagesUrl;
	}

	return fluxImagesUrl;
};

/* Get the flux api base URL ("https://<domain>/") from
 * the project environment "fluxRestApiUrl" variable.
 */
export const getFluxApiUrl = (): string => {
	let fluxRestApiUrl = '';
	if (!process.env.fluxRestApiUrl) {
		throw new Error('fluxRestApiUrl is not defined!');
	} else {
		fluxRestApiUrl = process.env.fluxRestApiUrl;
	}

	return fluxRestApiUrl;
};

/**
 * Determines rating text.
 */
export const ratingText = (rating: number): string => {
	var ratingString = '';
	// eslint-disable-next-line valid-typeof
	if (typeof rating == null) {
		return '';
	}
	if (rating >= 5.4) {
		ratingString = 'Exzellent';
	} else if (rating >= 4.8) {
		ratingString = 'Sehr Gut';
	} else if (rating >= 4.2) {
		ratingString = 'Gut';
	} else {
		ratingString = '';
	}

	return ratingString;
};

/**
 * Capitalizes the first letter of each word in a string with certain exceptions
 * @param {string} the text to be capitalized
 * @return {string} the capitalized text
 */
export const capitalizeText = (text: string): string | boolean => {
	var textString: string[] = [];
	var convertedText: string[] = [];
	var termExceptions = ['mit', 'ohne', 'und'];

	if (typeof text !== 'string') {
		return false;
	}
	textString = text.split(' ');
	textString.forEach(function(word) {
		var convertedWord = word.toLowerCase();

		if (convertedWord && termExceptions.indexOf(convertedWord) === -1) {
			convertedWord = convertedWord[0].toUpperCase() + convertedWord.slice(1);
		}

		convertedWord = convertedWord.replace(/[/|-]./g, function(str) {
			return str.toUpperCase();
		});

		convertedText.push(convertedWord);
	});

	return convertedText.join(' ');
};

/**
 * Capitalizes the first letter of given string without conditions
 *
 * @param {string} text The string to convert first letter to uppercase without conditions
 * @return {string} The converted string with first letter uppercase
 */
export const firstLetterUppercase = (text: string): string => {
	if (!text) {
		return '';
	}

	return text.charAt(0).toUpperCase() + text.slice(1);
};

export const chunkArray = <T>(arr: T[], size: number): T[][] => {
	const slices = Math.ceil(arr.length / size);
	return [...Array(slices)].map((_, index) => arr.slice(size * index, size + size * index));
};

/**
 * Truncates string.
 */
export const truncate = (text: string, stop: number, clamp: string): string => {
	if (text != null && typeof text === 'string') {
		return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
	}
	return text;
};

/**
 * Convert price to other currency.
 * @param {float} price The source price.
 * @param {float} conversionRate A number between 0.00 and 1.00.
 * @returns {float} Returns price or false in error case.
 */
export const convertPrice = (price: any, conversionRate: any): number | boolean => {
	var conversionRateFloat = parseFloat(conversionRate) || 1;
	var priceFloat = parseFloat(price);

	if (price === priceFloat) { // eslint-disable-line no-cond-assign
		return price / conversionRateFloat;
	}

	return false;
};

/**
 * Determines symbol for currency.
 * @param {string} currencyISO currency in ISO4217 format. (EUR, USD)
 * @return {string} Currency symbol if available or input string.
 */
export const currencyISOToSymbol = (currencyISO: string): string => {
	switch (currencyISO) {
	case 'EUR':
		return '€';
	case 'USD':
		return '$';
	default:
		return currencyISO;
	}
};

/**
* Returns The rating string (e.g. 'Hervorragend')
* @param {number} The rating
* @return {string} The rating string
*/
export const determineRatingString = (rating: number): string => {
	var ratingString = '';

	if (rating <= 3) {
		ratingString = 'Akzeptabel';
	} else if (rating <= 4) {
		ratingString = 'Gut';
	} else {
		ratingString = 'Hervorragend';
	}

	return ratingString;
};

/**
 * Converts ISO time to relative time indication
 * @param {string} time ISO time format string, e.g. '2020-01-20 12:41:19'
 * @return {string} relative time indication, e.g. 'vor 2 Tagen'
 */
export const relativeTime = (time: string): string => {
	const timeSplit: any[] = time.split(/[- :]/);
	timeSplit[1]--;
	const fixedDate: any = new Date(Date.UTC(timeSplit[0], timeSplit[1]--, timeSplit[2], timeSplit[3], timeSplit[4], timeSplit[5]));
	try {
		fixedDate.toISOString();
	} catch (error) {
		return '';
	}
	const trustPilotDate: number = Date.parse(fixedDate) / 1000;
	let relativeDate: string,
		nowDate: any = new Date(),
		newDate: number;

	nowDate = Date.parse(nowDate) / 1000;
	newDate = nowDate - trustPilotDate;
	if (newDate <= (60 * 59)) {
		newDate = Math.floor(newDate / 60);
		relativeDate = newDate <= 1 ? '1 Minute' : newDate + ' Minuten';
	} else if (newDate <= (60 * 60 * 23)) {
		newDate = Math.floor(newDate / (60 * 60));
		relativeDate = newDate <= 1 ? '1 Stunde' : newDate + ' Stunden';
	} else {
		newDate = Math.floor(newDate / (60 * 60 * 24));
		relativeDate = newDate <= 1 ? '1 Tag' : newDate + ' Tagen';
	}
	return newDate ? 'vor ' + relativeDate : '';
};

/**
 * Returns the given counts & pluralizes the label if necessary.
 * @param {string} single singular form of the label
 * @param {string} plural plural form of the label
 * @return {string} count & label
 */
export const pluralize = (count: number, single: string, plural: string): string => {
	const label = count === 1 ? single : plural;
	return count + NBSP + label;
};

/**
 * Converts and formats a number into a language sensitive string
 * @param {number} num the number to be formated/converted
 * @param {Intl.NumberFormatOptions} format style options, currency, digits,...
 * @param {string} locale locale identifier string
 */
export const formatNumber = (num: number, format: Intl.NumberFormatOptions = { style: 'currency', currency: 'EUR' }, locale = 'de-AT'): string => {
	const intl = new Intl.NumberFormat(locale, format);
	return intl.format(num);
};

export const getTravelDuration = (travelDuration: number[] | null): string => {
	let duration = '';
	if (travelDuration && travelDuration.length && travelDuration.length > 1) {
		duration = `${travelDuration.join(NDASH)} Tage`;
	} else if (travelDuration && travelDuration.length && travelDuration[0]) {
		duration = pluralize(travelDuration[0], 'Tag', 'Tage');
	}
	return duration;
};

/**
 * Conversts the aid to the amadeus image group
 *
 * '100099702' should be '100095000'
 * '100084929' should be '100080000'
 */
export const amadeusImageGroup = (aid: string): string => {
	const imageGroup = parseInt(aid.substr(5, 1), 10) >= 5 ? '5000' : '0000';
	return `${aid.substr(0, 5)}${imageGroup}`;
};

/**
 * Converts the given date to Weekday DD MMM & if the domain is AT changes 'Jan' to 'Jän'.
 * @param {string} date
 * @return {string} domain
 */
export const convertDateHelper = (date: string, domain: string): string => {
	var convertedDate = new Date(date);
	var days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
	var months = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
	if (domain === 'AT') {
		months[0] = 'Jän';
	}
	return days[convertedDate.getDay()] + ' ' + convertedDate.getDate() + '. ' + months[(convertedDate.getMonth())];
};

/**
 * Add top margin class to element if on top of page
 * @param {string} element
 */
export const addTopMarginToFirstElement = (element: HTMLElement): void => {
	if (!element || !element.classList) {
		return;
	}

	const previousElement = element.previousElementSibling;
	const className = element.classList[0] || '';

	if (
		previousElement?.id === 'search-form-container'
		&& window.matchMedia('(min-width: 1300px)').matches
	) {
		element.classList.add(`${className}--margin-top`);
	}
};

export const pascalToKebabCase = (pascalCaseString: string): string => pascalCaseString.replace(/([a-z0–9])([A-Z])/g, '$1-$2').toLowerCase();

export const extendUrlParams = (hash: string): void => {
	const currentWindowHash = window.location.href?.split('#')[1];

	if (currentWindowHash === hash) {
		return;
	}

	const pathname = window.location.pathname;
	const search = window.location.search.split('#')[0];

	window.history.replaceState(null, '', `${pathname}${search}#${hash}`);
};

export const getDirectionToElement = (elementId: string): 'down' | 'up' | '' => {
	const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
	const targetElement = document.getElementById(elementId);

	if (!targetElement) return '';

	const targetTop = targetElement.getBoundingClientRect().top + currentScrollTop;

	if (targetTop > currentScrollTop) return 'down';

	if (targetTop < currentScrollTop) return 'up';

	return '';
};

const getElementHeight = (selector: string): number => document.querySelector(selector)?.getBoundingClientRect().height ?? 0;

const getNavbarHeightAfterScrollingToElement = (targetId: string, isDesktop: boolean): number => {
	const scrollDirection = getDirectionToElement(targetId);

	if (!scrollDirection) return 0;

	const navbarHeight = getElementHeight('.rpb_header');
	const anchorBarHeight = getElementHeight('.hotel-page-main-content__menu-wrapper');

	// Case desktop
	if (isDesktop) {
		return scrollDirection === 'up' ? navbarHeight + anchorBarHeight : anchorBarHeight;
	}

	// Case mobile
	return scrollDirection === 'up' ? navbarHeight + anchorBarHeight : anchorBarHeight;
};

const getHashFromEvent = (event: MouseEvent): string => {
	const href = (event.target as HTMLElement).closest('a')?.getAttribute('href');

	if (!href) return '';

	return href.split('#')[1];
};

export const scrollToHash = (hash: string, scrollTime = 1000, offset = 200, callback: null | (() => void) = null) => {
	extendUrlParams(hash);
	setTimeout(() => {
		const scrollToSelector = document.querySelector(`#${hash}`) as HTMLElement;
		if (scrollToSelector) {
			scrollIt(
				scrollToSelector,
				scrollTime,
				'easeOutQuad',
				offset,
				callback
			);
		}
	}, 0);
};

export const scrollToAnchor = (event: MouseEvent, scrollTime = 1000, offset = 200) => {
	const hash = getHashFromEvent(event);
	if (hash) {
		scrollToHash(hash, scrollTime, offset);
	}
};

export const scrollToHashWithDynamicOffset = (hash: string, isDesktop: boolean, callback?: () => void): void => {
	const navBarHeightAfterScrolling = getNavbarHeightAfterScrollingToElement(hash, isDesktop);
	scrollToHash(hash, undefined, navBarHeightAfterScrolling, callback);
};

export const scrollToAnchorWithDynamicOffset = (event: MouseEvent, isDesktop: boolean, callback?: () => void): void => {
	const targetId = getHashFromEvent(event);
	scrollToHashWithDynamicOffset(targetId, isDesktop, callback);
};

export const convertFeaturesIntoArray = (offerFeatures?: OfferFeatures, flexStorno?: OfferFlex | null, isFlexChecked: boolean = false, isThankYouPage = false): SingleFeature[] => {
	const result = [];

	if (offerFeatures && offerFeatures.FreeCancellation) {
		result.push({
			title: 'Flexible Stornierung',
			description: offerFeatures.FreeCancellation
		});
	}
	if (offerFeatures && offerFeatures.PaidCancellation) {
		result.push({
			title: 'Flexible Stornierung',
			description: offerFeatures.PaidCancellation
		});
	}
	if (offerFeatures && offerFeatures.FreeRebooking) {
		result.push({
			title: 'Flexible Umbuchung',
			description: offerFeatures.FreeRebooking
		});
	}

	if (offerFeatures && offerFeatures.OptionalFlexRate) {
		result.push({
			title: (isFlexChecked || isThankYouPage) ? 'Flexible Stornierung zugebucht' : 'Flexible Stornierung zubuchbar',
			description: flexStorno?.FlexRate.Description || offerFeatures.OptionalFlexRate
		});
	}

	return result;
};

declare global {
	interface Window {
		Sentry: any;
	}
}

export const reportErrorToSentry = (
	error: any,
	contexts: { name: string; data: any;}[]
) => {
	if (window.Sentry && window.Sentry.captureException) {
		contexts.forEach(({ name, data }) => {
			window.Sentry.setContext(name, data);
		});

		window.Sentry.captureException(error);
	}
};
