import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "departure-field-content__body",
  "aria-label": "Flughäfen"
}
const _hoisted_2 = {
  key: 0,
  class: "departure-field-content__selected"
}
const _hoisted_3 = { class: "departure-field-content__selected-list" }
const _hoisted_4 = { class: "departure-field-content__airport" }
const _hoisted_5 = { class: "departure-field-content__airport-code" }
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "departure-field-content__more-text" }

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import PillSelect from '@lmt-rpb/PillSelect/PillSelect.vue';
import RowList from '@lmt-rpb/RowList/RowList.vue';
import type { AirportDataType } from '@/components/common/types';
import { useStore } from '@/components/common/store';
import { computed, ref } from 'vue';

interface Props {
	moreCount?: number,
	modelValue?: string[]
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DepartureFieldContent',
  props: {
    moreCount: { default: 3 },
    modelValue: { default: () => [] }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const store = useStore();

const emit = __emit;

const props = __props;

const count = ref(props.moreCount);

const airports = computed((): AirportDataType[] => store.state.items.airports);

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const getAirportDataByCode = (items: string[]): AirportDataType[] => items.map((item: string) => airports.value.find(({ value }) => item === value))
	.filter(Boolean) as AirportDataType[];

const visibleItems = computed((): AirportDataType[] => {
	if (!model.value.length) {
		return [];
	}

	return getAirportDataByCode(model.value.slice(0, count.value));
});

const moreItems = computed((): string[] => {
	if (!visibleItems.value.length) {
		return [];
	}

	const airportData = getAirportDataByCode(model.value.slice(count.value));
	return airportData.map((airport: AirportDataType) => {
		const additional = airport.additional && airport.additional.map((extra) => `(${extra})`).join(' ');
		return `${airport.label} ${additional}`;
	});
});

const showMoreItems = () => {
	count.value = model.value.length;
};

const clear = () => {
	model.value = [];
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(RowList, { class: "departure-field-content" }, {
    content: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        (!airports.value.length)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(" Keine Ergebnisse ")
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(airports.value, (item, index) => {
          return (_openBlock(), _createBlock(PillSelect, {
            ref_for: true,
            ref: "item",
            key: index,
            modelValue: model.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
            item: item,
            class: "departure-field-content__pill"
          }, null, 8 /* PROPS */, ["modelValue", "item"]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    footer: _withCtx(() => [
      (model.value.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("button", {
              type: "button",
              class: "departure-field-content__clear-button",
              "aria-label": "Ausgewählte Elemente löschen",
              onClick: _withModifiers(clear, ["stop"])
            }, [
              _createVNode(BaseIcon, {
                name: "close",
                class: "departure-field-content__close-icon"
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleItems.value, (item) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: item.value,
                  class: "departure-field-content__selected-item"
                }, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(item.label), 1 /* TEXT */),
                  _createElementVNode("span", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.additional, (airportCode) => {
                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                        _createTextVNode(" (" + _toDisplayString(airportCode) + ") ", 1 /* TEXT */)
                      ], 64 /* STABLE_FRAGMENT */))
                    }), 256 /* UNKEYED_FRAGMENT */))
                  ])
                ]))
              }), 128 /* KEYED_FRAGMENT */)),
              (moreItems.value.length)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    title: moreItems.value.join(', '),
                    class: "departure-field-content__more-button",
                    onClick: _withModifiers(showMoreItems, ["stop"])
                  }, [
                    _cache[1] || (_cache[1] = _createTextVNode(" und ")),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(moreItems.value.length), 1 /* TEXT */),
                    _cache[2] || (_cache[2] = _createTextVNode(" weitere "))
                  ], 8 /* PROPS */, _hoisted_6))
                : _createCommentVNode("v-if", true)
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}
}

})