// analytic-utils.ts
import { useStore } from '@/components/common/store';
import { SearchFormDataType } from '@/interfaces/search-form';

const store = useStore();

export const determineTravellerType = (submitData: SearchFormDataType): string => {
	const { adult, children } = submitData.travelers;

	if (children.length > 0) {
		return 'Familien';
	}
	if (adult === 2) {
		return 'Paare';
	}
	if (adult === 1) {
		return 'Singles';
	}
	if (adult > 2) {
		return 'Gruppen';
	}
	return 'not_set';
};

export const customAnalyticsDimensions = () => {
	const submitData = store.getters['searchMask/submitData'];
	const travellerType = determineTravellerType(submitData);
	return {
		traveller_type: travellerType,
		departure_date: submitData.offerDuration.from,
		return_date: submitData.offerDuration.to
	};
};

export const getTravellerTypeCheckout = () => {
	const travellers = store.getters['checkout/travellers'];
};
