import { SimplePrice } from '@/interfaces/checkout/offer-data';
import { InsuranceOffer } from '@/interfaces/checkout/insurance-data';
import { getLocaleString } from './utils';

export type Currency = 'CHF' | 'EUR';

export const getFormatPriceEur = (price?: SimplePrice, showCents = false) => {
	let result = '';
	if (price) {
		const amount = price.Amount;
		const currency = price.Currency;
		result = formatPrice(amount, currency, showCents);
	}
	return result;
};
export const getInsurancePrice = (insurance?: Partial<InsuranceOffer>, isCH = false, extraSpan = false) => {
	if (!insurance || !insurance.Price) {
		return '';
	}
	const euroPrice = formatPrice(insurance.Price, 'EUR', true);
	if (!isCH) {
		return euroPrice;
	}
	const converted = insurance.PriceInformation?.Converted;
	if (!converted) {
		return '';
	}
	const convertedPrice = formatPrice(converted.Amount, converted.Currency, true);
	const suffix = extraSpan ? `<span class="insurance-price__currency">(${euroPrice})</span>` : `(${euroPrice})`;

	return `${convertedPrice} ${suffix}`;
};

export const getFormatPriceConverted = (price?: SimplePrice, showCents = false) => {
	let result = '';
	if (price) {
		const convertedPrice = price.Converted;
		const amount = convertedPrice?.Amount || price.Amount;
		const currency = convertedPrice?.Currency || price.Currency;
		result = formatPrice(amount, currency, showCents);
	}
	return result;
};
/**
 * Formats a price based on the current domain.
 * Output will be "€ 1234"
 * @param num number that will be formatted to a price e.g. 1234.5
 * @param currency 'EUR' or 'CHF' => result will have the right currency prefix
 * @param showCents if true ouput will have 2 numbers after decimal point eg. € 1234.50
 */
export const formatPrice = (num: number | null, currency: string = getLocaleString('currency'), showCents = false): string => {
	if (num === null) return '';
	let minimumFractionDigits = 0;
	const maximumFractionDigits = showCents ? 2 : 0;
	if (maximumFractionDigits === 2 && (num * 10) % 10) {
		//	don't show prices like € 10,9
		minimumFractionDigits = 2;
	}
	const intl = new Intl.NumberFormat('de-DE', {
		currency,
		style: 'currency',
		minimumFractionDigits,
		maximumFractionDigits
	});

	return intl.format(num);
};

export const getPriceCorrectCurrency = (price?: SimplePrice, showCents = false) => {
	if (!price) return '';
	const convertedPrice = price.Converted;
	const amount = convertedPrice?.Amount || price.Amount;
	const currency = convertedPrice?.Currency || price.Currency;
	return formatPrice(amount, currency, showCents);
};

interface Verified {
	price?: number | null;
	currency?: string;
}

interface UnverifiedOffer {
	price?: number;
	currency?: string;
	travellerAmount: number;
}

const isUnVerified = (status: string) => status === 'CheckUnable' || status === 'Undefined';

// eslint-disable-next-line arrow-body-style
export const getFinalPrice = (status: string, price: number | undefined | null, unverifiedPrice: number | undefined = 0, travellerAmount: number) => {
	// If status 'CheckUnable' or 'Undefined'
	// we just have the total price of one person from the unverified offer
	return isUnVerified(status) ? unverifiedPrice * travellerAmount : price as number;
};

// eslint-disable-next-line arrow-body-style
export const getFinalCurrency = (status: string, currency: string | undefined, unverifiedCurrency: string | undefined) => {
	return (isUnVerified(status) ? unverifiedCurrency : currency) as string;
};

export const getFinalPriceWithCurrency = (status: string, verified: Verified, unverified: UnverifiedOffer) => {
	const priceResult = getFinalPrice(status, verified.price, unverified.price, unverified.travellerAmount);
	const currencyResult = getFinalCurrency(status, verified.currency, unverified.currency);
	return formatPrice(priceResult, currencyResult, true);
};

export const currencyCodes = {
	euro: 'EUR',
	switzerland: 'CHF'
};
