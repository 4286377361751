import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flight-details__segment" }
const _hoisted_2 = { class: "flight-details__row" }
const _hoisted_3 = { class: "flight-details__row-right" }
const _hoisted_4 = { class: "flight-details__row" }
const _hoisted_5 = { class: "flight-details__row-right" }
const _hoisted_6 = { class: "flight-details__row" }
const _hoisted_7 = { class: "flight-details__row-right" }
const _hoisted_8 = { class: "flight-details__row" }
const _hoisted_9 = { class: "flight-details__row-right" }
const _hoisted_10 = { class: "flight-details__row" }
const _hoisted_11 = { class: "flight-details__row-right" }
const _hoisted_12 = { class: "flight-details__row" }
const _hoisted_13 = { class: "flight-details__row-right" }
const _hoisted_14 = { class: "flight-details__row" }
const _hoisted_15 = { class: "flight-details__row-right" }
const _hoisted_16 = { class: "flight-details__row" }
const _hoisted_17 = { class: "flight-details__row-right" }
const _hoisted_18 = { class: "flight-details__row" }
const _hoisted_19 = { class: "flight-details__row-left has-icon" }
const _hoisted_20 = { class: "flight-details__row-right" }
const _hoisted_21 = { class: "flight-details__baggage" }
const _hoisted_22 = {
  key: 0,
  class: "flight-details__row"
}
const _hoisted_23 = { class: "flight-details__row-right" }

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { formatDate, convertSeconds } from '@utils/utils';
import { FlightSegment } from '@/interfaces/checkout/offer-data';
import { BaggageAllowance, Juncture } from '@/interfaces/common';
import { AirportData } from '@/interfaces/offer';
import InfoTooltip from '@lmt-rpb/InfoTooltip/InfoTooltip.vue';

interface Props {
	segment: FlightSegment,
	isRight?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutFlightDetailsSegment',
  props: {
    segment: {},
    isRight: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const getDate = (date: Date | string): Date | string => {
	if (!date) {
		return '';
	}

	return formatDate(date, { year: 'numeric', month: 'short', day: '2-digit' });
};

const isAiportData = (aiport: Juncture | AirportData): aiport is AirportData => ('time' in aiport);

const getAllowanceText = (baggage: BaggageAllowance | undefined): string => {
	if (!baggage || !(baggage.MaxWeight || baggage.MaxBags)) {
		return '1 x p. P. inkl.';
	}
	const { MaxBags, MaxWeight } = baggage;
	if (MaxBags && MaxWeight) {
		return baggage.MaxBags + ' x ' + baggage.MaxWeight + 'kg p. P. inkl.';
	}

	if (MaxWeight) {
		return '1 x ' + baggage.MaxWeight + 'kg p. P. inkl.';
	}

	return MaxBags + ' x p. P. inkl.';
};

const getTooltipText = (baggae: BaggageAllowance | undefined) => {
	if (!baggae || !baggae.MaxWeight) {
		return 'Der Veranstalter liefert uns leider keine Informationen zum Freigepäck. Dieses beträgt ca. 20kg und kann je nach Airline auch weniger oder mehr betragen.';
	}
	return 'Bitte beachten Sie, dass die Angaben zu Freigepäckmengen in Ausnahmefällen abweichen können.';
};

const getTime = (airport: Juncture | AirportData): string => {
	let time = '';
	if (isAiportData(airport)) {
		if (airport.time) {
			time = airport.time + ' Uhr';
		}
	}

	if (!time && airport.Date) {
		time = `${formatDate(airport.Date, { hour: '2-digit', minute: '2-digit' })} Uhr`;
	}

	return time;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flight-details__row-left" }, " Datum ", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_3, _toDisplayString(getDate(_ctx.segment.Departure.Date)), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flight-details__row-left" }, " Abflughafen ", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.segment.Departure.Airport), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flight-details__row-left" }, " Zielflughafen ", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.segment.Arrival.Airport), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flight-details__row-left" }, " Fluggesellschaft ", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.segment.Airline), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flight-details__row-left" }, " Flugklasse ", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.segment.CabinClass), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flight-details__row-left" }, " Flugnummer ", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.segment.FlightNumber), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flight-details__row-left" }, " Abflugzeit ", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_15, _toDisplayString(getTime(_ctx.segment.Departure)), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flight-details__row-left" }, " Ankunftzeit ", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_17, _toDisplayString(getTime(_ctx.segment.Arrival)), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createVNode(BaseIcon, {
            name: "briefcase",
            class: "icon"
          }),
          _cache[8] || (_cache[8] = _createTextVNode(" Freigepäck "))
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("span", _hoisted_21, [
            _createTextVNode(_toDisplayString(getAllowanceText(_ctx.segment.BaggageAllowance)) + " ", 1 /* TEXT */),
            _createVNode(InfoTooltip, {
              placement: "bottom",
              "modifier-class": props.isRight ? 'is-right' : 'is-left'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(getTooltipText(_ctx.segment.BaggageAllowance)), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modifier-class"])
          ])
        ])
      ]),
      (_ctx.segment.StopOver)
        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "flight-details__row-left" }, " Aufenthalt ", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_23, _toDisplayString(_unref(convertSeconds)(_ctx.segment.StopOver.StopoverDuration)), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}
}

})