<template>
	<div
		class="theme-main"
		:class="{'is-hotel-page': pageType === 'hotelPage'}"
	>
		<nav class="theme-main__tabs">
			<li
				class="theme-main__tab"
				:class="{'is-active': !isHotelOnly, 'is-home-page' : pageType === 'homePage'}"
				role="button"
				@click="onTabClick(false)"
			>
				<BaseIcon
					name="package"
					class="theme-main__tab-icon package-icon"
				/>
				{{ getLocaleString('package') }}
			</li>
			<li
				class="theme-main__tab"
				:class="{'is-active': isHotelOnly, 'is-home-page' : pageType === 'homePage'}"
				role="button"
				@click="onTabClick(true)"
			>
				<BaseIcon
					name="room"
					class="theme-main__tab-icon"
				/>
				Nur Hotel
			</li>
		</nav>

		<!-- mobile searchmask -->
		<form
			class="theme-main-form theme-main__form"
			autocomplete="false"
			@submit.prevent="onSubmit"
		>
			<SearchModeSwitch
				class="theme-main-form__switch-button"
				@SearchModeSwitchSimple:Drag="onDrag($event)"
				@SearchModeSwitchSimple:DragEnd="onDragEnd()"
				@SearchModeSwitchSimple:Change="onChange($event)"
				@SearchModeSwitchSimple:Toggle="onToggle"
			/>
			<div
				ref="travel-group"
				class="theme-main-form__travel-group"
			>
				<DestinationField
					ref="destinationField"
					v-model="formData.destination"
					class="theme-main-form__destination-field"
					:class="{'theme-main-form__destination-field--is-dragging': isDragging}"
					:style="destinationStyle"
					:inline="false"
					:url="destinationSuggestionUrl"
					label="Reiseziel, Hotel, Ort, ..."
				/>

				<DepartureField
					ref="departureField"
					class="theme-main-form__departure-field"
					:class="{'theme-main-form__departure-field--is-dragging': isDragging}"
					:style="animationStyle(departureFieldHeight)"
				/>
			</div>

			<div class="theme-main-form__date-group">
				<OfferDurationField
					class="theme-main-form__offer-duration-field"
				/>
				<TravelDurationField
					class="theme-main-form__travel-duration-field"
				/>
			</div>

			<TravelersField
				class="theme-main-form__passengers-field"
			/>
			<div class="theme-main-form__footer">
				<RoundedButton
					type="submit"
					theme="extra"
					class="theme-main-form__submit-button"
					:disabled="maxTravelDurationError"
					@keydown.enter.prevent="onSubmit"
					@keydown.space.prevent="onSubmit"
				>
					<span class="theme-main-form__submit-button-text">{{ submitButtonText }}</span>
				</RoundedButton>
			</div>
		</form>
	</div>
</template>

<script lang="ts" setup>

import {
	SearchFormDataType,
} from '@interfaces/search-form';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import DepartureField from '@lmt-rpb/DepartureField/DepartureField.vue';
import DestinationField from '@lmt-rpb/DestinationField/DestinationField.vue';
import OfferDurationField from '@lmt-rpb/OfferDurationField/OfferDurationField.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import SearchModeSwitch from '@lmt-rpb/SearchModeSwitch/SearchModeSwitch.vue';
import TravelDurationField from '@lmt-rpb/TravelDurationField/TravelDurationField.vue';
import TravelersField from '@lmt-rpb/TravelersField/TravelersField.vue';
import {
	getLocaleString, isOfferlistPage, scrollIt,
} from '@utils/utils';
import { EventBus } from '@global-js/event-bus';
import {
	computed, onBeforeUnmount, onMounted, onUpdated, ref, watch,
} from 'vue';
import { useStore } from '@/components/common/store';

const store = useStore();

const emit = defineEmits(['ThemeMain:Submit', 'ThemeMain:Cancel']);

const destinationField = ref<InstanceType<typeof DestinationField> | null>(null);

const departureField = ref<InstanceType<typeof DepartureField> | null>(null);

const transferGroup = ref<HTMLElement | null>(null);

const isDragging = ref(false);

const destinationFieldWidth = ref(55); // in percent

const departureFieldHeight = ref<number>(0);

const transferGroupHeight = ref(0);

const isHotelPage = ref(false);

const toggleActive = ref(false);

const isOfferlistPageRef = ref<boolean>(isOfferlistPage());

const isDefaultHotelOnly = computed((): boolean => store.state.config.hotelOnly);

const isDesktop = computed((): boolean => store.state.config.isDesktop);

const formData = computed((): SearchFormDataType => store.state.searchMask);

const isHotelOnly = computed((): boolean => formData.value.onlyHotel);

const progress = ref<number>(formData.value.onlyHotel ? 100 : 0);

const destinationSuggestionUrl = computed((): string => store.state.config.suggestions);

const pageType = computed((): string => store.state.config.pageType);

const maxTravelDurationError = computed(() => store.state.searchMask.maxTravelDurationError);

const destinationStyle = computed((): Record<string, string> => {
	if (!isDesktop.value) {
		return {};
	}

	const add = ((100 - destinationFieldWidth.value) / 100) * progress.value;

	return {
		width: `${destinationFieldWidth.value + add}%`
	};
});

const submitButtonText = computed((): string => {
	if (isDesktop.value || isOfferlistPageRef.value) return 'Suchen';
	return 'Reise finden';
});

const animationStyle = (elementHeight: number): Record<string, number | string> => {
	if (!elementHeight && !isHotelOnly.value) {
		return {};
	}

	const percent = 100 - progress.value;
	const opacity = percent / 100;

	return {
		opacity,
		visibility: opacity === 0 ? 'hidden' : 'visible',
		...(!isDesktop.value ? {
			'max-height': `${(elementHeight / 100) * percent}px`,
			'margin-bottom': `${(0.5 / 100) * percent}rem`,
		} : {})
	};
};

const scrollUpSearchForm = (): void => {
	const searchForm: HTMLElement | null = document.querySelector('#search-form-container .search-form');
	if (!searchForm) {
		return;
	}

	const spaceSearchToBottom = (window.innerHeight - searchForm?.getBoundingClientRect().bottom);

	if (spaceSearchToBottom < 450) {
		scrollIt(
			searchForm,
			300,
			'easeOutQuad',
		);
	}
};

const onSubmit = (): void => {
	emit('ThemeMain:Submit', formData.value);
};

const updateSizes = (): void => {
	if (departureField.value?.$el?.scrollHeight) {
		departureFieldHeight.value = departureField.value?.$el?.scrollHeight;
	}
	if (transferGroup.value) {
		transferGroupHeight.value = transferGroup.value.scrollHeight;
	}
};

const onDrag = (progressNum: number): void => {
	progress.value = progressNum;
	isDragging.value = true;
};

const onDragEnd = (): void => {
	isDragging.value = false;
};

const onChange = (checked: boolean): void => {
	progress.value = checked ? 100 : 0;
};

const setAccommodationType = (onlyHotel: boolean): void => {
	store.commit('searchMask/updateFormData', { onlyHotel });
};
const onTabClick = (onlyHotel: boolean): void => {
	setAccommodationType(onlyHotel);
};

const onToggle = (): void => {
	// Small delay for overflow hidden during animation
	toggleActive.value = true;
	setTimeout(() => {
		toggleActive.value = false;
	}, 500);
};

watch(() => formData.value.onlyHotel, (onlyHotel: boolean) => {
	onChange(onlyHotel);
});

watch(() => isDesktop.value, () => {
	updateSizes();
});

onMounted(() => {
	isHotelPage.value = document.body.classList.contains('page_hotel');

	EventBus.$on('window:resize', updateSizes);
	EventBus.$on('OfferSearch:ScrollUpSearchForm', scrollUpSearchForm);

	if (isDefaultHotelOnly.value) {
		setAccommodationType(true);
	}

	updateSizes();
});

onUpdated(() => {
	const departureFieldScrollHeight = departureField.value?.$el?.scrollHeight;

	transferGroupHeight.value = 230; // default value for max-height

	if (departureFieldScrollHeight && (departureFieldScrollHeight !== departureFieldHeight.value)) {
		departureFieldHeight.value = departureFieldScrollHeight;
	}
});

onBeforeUnmount(() => {
	EventBus.$off('window:resize', updateSizes);
});
</script>

<style lang="scss" scoped>
.theme-main-form__room-and-board {
	margin-bottom: 0;
}

:deep(.collapse__icon) {
	width: 3rem;
	height: 2.3rem;
	fill: $color-primary;
	margin-top: 2rem;
}

:deep(.collapse__body) {
	margin: 2rem 0 2rem 0;
}

:deep(.price-slider__value) {
	margin-top: 0;
}

:deep(.recommendation-rate__button.button) {
	padding: 0 0.9rem;
}

:deep(.price-slider__heading) {
	color: #707070 !important;
	font-size: $font-medium-2;
	font-weight: $font-weight-semibold;
}

.theme-main {
	max-width: 144rem;
	position: relative;

	.theme-main__tabs {
		@include visible-from($breakpoint-extralarge, flex);

		z-index: 10;
		list-style-type: none;
	}

	.theme-main__tab {
		padding: 0.5rem 2rem;
		border-width: 0.2rem 0.2rem 0 0.2rem;
		border-top-left-radius: $border-radius-small;
		border-top-right-radius: $border-radius-small;
		fill: $search-inactive-tab;
		color: $search-inactive-tab;
		font-size: $font-small-1;
		position: relative;
		bottom: -0.5rem;
		cursor: pointer;
		z-index: 30;

		&.is-active {
			fill: $color-primary-l1;
			border: 1px solid;
			color: $color-primary-l1;
			border-color: $color-lime-light;
			background: $color-light-gray;
			z-index: 40;
			font-weight: bold;
			bottom: 0;
			box-shadow: 0.2rem 0 0.3rem rgba(0, 0, 0, 0.15);

			&::after {
				position: absolute;
				content: '';
				left: 0;
				right: 0;
				bottom: -0.5rem;
				height: 1rem;
				background: $color-white;
				z-index: 50;
			}
		}
	}

	.theme-main__tab-icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: inherit;
		vertical-align: middle;

		&.package-icon {
			height: 3rem;
			width: 6rem;
		}

		&.is-small {
			width: 1rem;
			height: 1rem;
		}
	}

	.theme-main__form {
		display: flex;
		position: relative;
		flex-direction: column;
		align-items: center;
		max-width: 45rem;
		margin: 0 auto;
		padding: 2.4rem 1.5rem 0;
		border-top: 0;
		border-radius: $border-radius-search-home-mobile;

		// form fields
		.theme-main-form__travel-group {
			display: flex;
			position: relative;
			flex-direction: inherit;
			justify-content: flex-end;
			width: 100%;
		}

		.theme-main-form__switch-button {
			@include visible-to($breakpoint-extralarge, flex);

			margin-bottom: 2.4rem;
		}

		.theme-main-form__field {
			width: 100%;
			margin-bottom: 0.5rem;
		}

		.theme-main-form__destination-field {
			@extend .theme-main-form__field;

			&:not(.theme-main-form__destination-field--is-dragging) {
				transition: all 0.25s;
			}
		}

		.theme-main-form__departure-field {
			@extend .theme-main-form__field;

			&:not(.theme-main-form__departure-field--is-dragging) {
				transition: opacity 0.25s, max-height 0.25s, margin-bottom 0.25s, visibility 0.25s;
				max-height: 7.5rem;
			}
		}

		.theme-main-form__date-group {
			display: flex;
			position: relative;
			justify-content: space-between;
			width: 100%;
		}

		.theme-main-form__travel-duration-field {
			@extend .theme-main-form__field;

			width: 40%;
		}

		.theme-main-form__offer-duration-field {
			@extend .theme-main-form__field;

			margin-right: 0.5rem;
			width: 60%;

			@media (min-width: $breakpoint-extralarge){
				margin-left: 0.25rem;
			}
		}

		.theme-main-form__recommendation-filter,
		.theme-main-form__price-filter,
		.theme-main-form__collapse {
			@extend .theme-main-form__field;
		}

		.theme-main-form__passengers-field {
			width: 100%;
		}

		.hotel-list-filters__most-popular {
			border-top: 0.1rem solid $color-primary-l4;
			margin-top: 3rem;
		}

		.hotel-list-filters__collapse {
			position: relative;
			border-bottom: 0.1rem solid $color-primary-l4;
		}

		.hotel-list-filters__header--destination {
			border-top: none;
			padding-top: 0;
			margin-top: 1.6rem;
			padding-bottom: 1.6rem;
		}

		.hotel-list-filters__header--hotel-category {
			margin-top: 0;
		}

		.theme-main-form__footer {
			display: none;
			justify-content: center;
			width: 100%;
			margin: 2.4rem 0;
			z-index: 20;
		}

		.theme-main-form__submit-button {
			width: 100%;
			max-width: 30rem;
			padding: 1rem 2rem;
		}


		@include media-query-up($breakpoint-extralarge) {
			flex-direction: row;
			max-width: 144rem;
			padding: 2rem 1.5rem 1.5rem;
			border-radius: 0 $border-radius-main-search $border-radius-main-search $border-radius-main-search;
			z-index: 30;

			.rpb_header .theme-main__form {
				box-shadow: -0.3rem 0.3rem 0 $color-lime-light;
			}

			.theme-main-form__switch-button,
			.theme-main-form__field {
				margin-bottom: 0;
			}

			.theme-main-form__switch-button {
				align-self: flex-start;
				min-width: 13rem;
				margin-left: 0.5rem;
			}

			.theme-main-form__field {
				margin-right: 0.5rem;
			}

			.theme-main-form__travel-group {
				min-width: 51rem;
			}

			.theme-main-form__destination-field {
				position: absolute;
				z-index: 10;
				left: 0;
				width: 55%; // NOTE: this will be overwritten by js
				margin: 0;
			}

			.theme-main-form__departure-field {
				width: calc(45% - 0.5rem);
				margin: 0;
				margin-bottom: 0 !important;
				float: right;
			}

			.theme-main-form__date-group {
				min-width: 40rem;
				margin-left: 0.25rem;
			}

			.theme-main-form__travel-duration-field {
				width: 45%;
			}

			.theme-main-form__passengers-field {
				width: 56%;
				margin-right: 1.9rem;
			}

			.theme-main-form__footer {
				display: flex;
				width: auto;
				margin: 0 auto !important;
			}

			.theme-main-form__submit-button {
				// IE width fix
				width: auto;
				min-width: 10.5rem;
				margin-left: -1rem;
				padding: 1.3rem;
				font-size: $font-medium-3;

				@media screen and (min-width: 1370px) {
					min-width: 16rem;
					margin-left: unset;
				}
			}
			.theme-main-form__destination-field .form-field,
			.theme-main-form__departure-field .form-field,
			.theme-main-form__travel-duration-field .form-field,
			.theme-main-form__offer-duration-field .form-field,
			.theme-main-form__passengers-field .form-field {
				border-radius: 0.5rem;
			}
		}
	}

	@include media-query-up($breakpoint-extralarge) {
		position: relative;
		margin: 0 auto;
		padding: 2rem 0;
	}

	&.is-hotel-page {
		.theme-main__tab.is-active {
			background-color: rgba($color-white, 0.9);
			border: $color-extra-light;
		}

		.theme-main-form__footer {
			justify-content: space-between;
		}
	}
}

.collapse-enter-active,
.collapse-leave-active {
	transition: all 0.5s;
	max-height: 19rem;
}

.collapse-enter-from,
.collapse-leave-to {
	opacity: 0;
	max-height: 0;
}

// All Pages, Header Search
.rpb_header {
	.theme-main__tab {
		bottom: -0.5rem;
		padding-top: 0.8rem;
		padding-bottom: 1rem;
		height: 4.7rem;

		svg {
			margin-top: -0.6rem;
		}

		&.is-active {
			padding-top: 1.3rem;
			bottom: 0;
			background: $color-light-gray;

			svg {
				margin-bottom: -0.1rem;
			}
		}
	}

	.theme-main__form {
		background: $color-filter-bg;

		@include media-query-up($breakpoint-extralarge) {
			box-shadow: -0.3rem 0.3rem 0 $color-lime-light;
		}
	}
}

// Home Page and Hotel Page
.page_home,
.page_hotel {
	#search-form-container {
		.theme-main__tab {
			&.is-active {
				z-index: 50;
				background-color: rgba($color-white, 0.9);
			}
		}
	}

	.rpb_header {
		.theme-main__form {
			@include media-query-up($breakpoint-extralarge) {
				background-color: rgba($color-white, 1);
				box-shadow: -0.3rem 0.3rem 0 $color-lime-light;
			}
		}
	}
}

// Home Page
.page_home {
	#search-form-container {
		.theme-main {
			display: block;
		}

		.theme-main-form__footer {
			display: flex;
		}

		.theme-main__tab.is-active {
			background-color: rgba($color-white, 1);
		}
	}

	.rpb_header {
		.theme-main__form {
			@include media-query-up($breakpoint-extralarge) {
				box-shadow: -0.3rem 0.3rem 0 $color-vivid;
				background: rgba(255, 255, 255, 1);
			}
		}
	}
}

.page_home #search-form-container .theme-main__form {
	@media (min-width: $breakpoint-small) and (max-width: $breakpoint-extralarge - 1) {
		border: none;
		background: $search-form-bg-small;
	}
}

.page_home.kuo,
.page_home.lmt {
	#search-form-container {
		.theme-main__form {
			border: none;
			@media (min-width: $breakpoint-small) {
				border: none;
				background: $search-form-frost;
			}
			@media (min-width: $breakpoint-extralarge) {
				background-color: rgba($color-white, 1);
				border: 1px solid $color-vivid;
				box-shadow: -0.3rem 0.3rem 0 $color-vivid;
			}
		}
	}
}

.page_home.lmt {
	#search-form-container {
		.theme-main__form {
			@media (min-width: $breakpoint-extralarge) {
				border-color: $color-secondary;
				box-shadow: -0.3rem 0.3rem 0 $color-secondary;
			}
		}
	}
}

// Hotel Page
.page_hotel {
	.rpb_header-search .mode-switch__handle {
		background-color: $color-primary;
		border-color: $color-primary;
		box-shadow: -0.1rem 0 0 0.2rem $color-primary-l1;
	}
}

// Hotel Page and hotel-list
.page_hotel,
.page_hotel_list,
.page_region_list {
	.rpb_header {
		.theme-main__tab {
			border: none;
		}

		.theme-main__form {
			@include media-query-up($breakpoint-extralarge) {
				box-shadow: -0.3rem 0.3rem 0 $color-lime-light;
			}
		}
	}

	.theme-main-form__travel-group,
	.theme-main-form__passengers-field,
	.theme-main-form__date-group {
		padding: 0;

		@include media-query-up($breakpoint-extralarge) {
			padding: 0;
		}
	}

	.checkbox .checkbox__label {
		font-weight: $font-weight-semibold;
		color: #707070;
	}

	// direct flight info icon hotelpage desktop
	.additional-filters__direct-flight .direct-flight__info-icon {
		margin: 1.1rem 0 0 0.8rem;
	}

	.hotel-list-filters__transfer,
	.hotel-list-filters__board-types,
	.hotel-list-filters__room-type {
		.form-field .form-field__icon {
			fill: $color-primary;

			path {
				fill: $color-primary;
			}
		}
	}

	.theme-main-form__footer {
		display: flex;
		justify-content: center;
		width: 100%;
		max-width: 45rem;
		margin: 4rem 0 0 !important;
		padding: 1.5rem 0 3rem;
		position: -webkit-sticky; /* Fix for Safari */
		position: sticky;
		bottom: 0;
		background-color: rgba($color-filter-bg, 0.9);
		border-top: 0.1rem solid $color-primary-l2;

		@include media-query-up($breakpoint-extralarge) {
			margin: 2rem 0;
			max-width: 41.6rem;
			padding: 0;
			position: relative;
			background-color: transparent;
			border-top: none;
		}
	}
}

.page_hotel_list,
.page_region_list {
	.hotel-list-filters__skeleton {
		border: 1px solid $color-primary-l4;
	}
}

// Main Search on White Background
@include media-query-up($breakpoint-extralarge) {
	.theme-main {
		.theme-main-form {
			background: rgba($color-white, 1);
			border: 1px solid $color-lime-light;
			box-shadow: -0.3rem 0.3rem 0 $color-lime-light
		}

		.theme-main__tabs {
			.theme-main__tab {
				background: rgba($color-white, 0.9);
				padding-top: 0.8rem;
				padding-bottom: 1rem;
				height: 4.7rem;

				svg {
					margin-top: -0.6rem;
				}

				&.is-active {
					border: 0.1rem solid $color-lime-light;
					box-shadow: -0.3rem 0.1rem 0 $color-lime-light;
					background-color: rgba($color-white, 1);
					color: $color-extra-light;
					padding-top: 1.3rem;

					svg {
						fill: $color-extra-light;
						margin-bottom: -0.1rem;
					}
				}

				&:first-child.is-active {
					box-shadow: -0.3rem 0.3rem 0 $color-lime-light;
				}
			}
		}
	}
}

.theme-main-form--overflow-hidden {
	overflow: hidden;
}

.rpb_header-search .theme-main .theme-main__tab{
	background:none;
}

</style>
