import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "offer-details-modal__header" }
const _hoisted_2 = { class: "offer-details-modal__title" }
const _hoisted_3 = { class: "offer-details-modal__content" }

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import dialogPolyfill from 'dialog-polyfill';
import {
	computed, onMounted, ref, watch,
} from 'vue';
import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'OfferDetailsModal',
  props: {
    title: { default: 'Title' }
  },
  emits: ['OfferDetailsModal:closeModal'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const store = useStore();

const isDesktop = computed((): boolean => store.state.config.isDesktop);



const emit = __emit;

const offerdetailsModalRef = ref<HTMLDialogElement>();

const open = (): void => {
	if (offerdetailsModalRef.value) {
		offerdetailsModalRef.value.showModal();
		window.history.pushState({ offerdetailsModal: true }, '');
		document.body.style.overflow = 'hidden';
	}
};

const modalExistsAndIsOpen = (): boolean | undefined => offerdetailsModalRef.value && offerdetailsModalRef.value.open;

const closeModalWithoutAnimation = (): void => {
	if (modalExistsAndIsOpen()) {
		offerdetailsModalRef.value?.close();
	}
	emit('OfferDetailsModal:closeModal');
	document.body.style.overflow = 'auto';
};
const historyBack = (): void => {
	if (window.history.state && window.history.state.offerdetailsModal) {
		window.history.back();
	}
};

const closeDesktopModal = (): void => {
	historyBack();
	offerdetailsModalRef.value?.close();
};

const closeCallback = (): void => {
	if (modalExistsAndIsOpen()) {
		offerdetailsModalRef.value?.close();
		offerdetailsModalRef.value?.classList.remove('close');
		offerdetailsModalRef.value?.removeEventListener('animationend', closeCallback);
		historyBack();
	}
};

const closeModal = (): void => {
	if (isDesktop.value && modalExistsAndIsOpen()) {
		closeDesktopModal();
	} else {
		offerdetailsModalRef.value?.classList.add('close');
		offerdetailsModalRef.value?.addEventListener('animationend', closeCallback);
	}
	document.body.style.overflow = 'auto';
};

const hasSwipeBack = (): boolean => {
	const ua = navigator.userAgent;
	const ipad = /Macintosh/i.test(ua) && !!navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
	const android = /android/i.test(ua);
	const chrome = /(Chrome)/i.test(ua);
	const ios = /iPad|iPhone|iPod|CriOS/.test(ua) || ipad;

	return (android && chrome) || ios;
};

// Close modal if switching from isDesktop to !isDesktop and vice versa
// to prevent dissapearing modal and hidden body overflow (no scrolling possible)
watch(() => isDesktop.value, () => {
	if (!isDesktop.value && modalExistsAndIsOpen()) {
		closeDesktopModal();
	}
});

onMounted((): void => {
	// dialog polyfill
	const isDialogSupported = window.HTMLDialogElement;
	if (!isDialogSupported) {
		dialogPolyfill.registerDialog(offerdetailsModalRef.value as HTMLDialogElement);
	}

	// close on outside click
	if (offerdetailsModalRef.value) {
		offerdetailsModalRef.value.addEventListener('mousedown', (e: MouseEvent) => {
			const isInGallery = (e.target as HTMLElement).closest('.gallery-modal');
			const rect = (offerdetailsModalRef.value as HTMLDialogElement).getBoundingClientRect();
			const isInDialog = (rect.top <= e.clientY && e.clientY <= rect.top + rect.height
			&& rect.left <= e.clientX && e.clientX <= rect.left + rect.width);
			if (!isInDialog && !isInGallery) {
				closeModal();
			}
		});
	}

	// close modal on native back event
	window.addEventListener('popstate', () => {
		closeModalWithoutAnimation();
	});

	// close on ESC to also clear history state
	(offerdetailsModalRef.value as HTMLDialogElement).addEventListener('keydown', (event) => {
		if (event.key === 'Escape') {
			event.preventDefault();
			const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
			if (isNotCombinedKey) {
				closeModal();
			}
		}
	});
});

__expose({
	open,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("dialog", {
    ref_key: "offerdetailsModalRef",
    ref: offerdetailsModalRef,
    class: _normalizeClass({
			'offer-details-modal': true,
			'offer-details-modal--border': hasSwipeBack(),
		})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        type: "button",
        class: "offer-details-modal__close offer-details-modal__close--left",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (closeModal()))
      }, [
        _createVNode(BaseIcon, {
          name: "chevron",
          class: "offer-details-modal__close-icon"
        }),
        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "offer-details-modal__close-text" }, "zurück", -1 /* HOISTED */))
      ]),
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1 /* TEXT */),
      _createElementVNode("button", {
        type: "button",
        class: "offer-details-modal__close offer-details-modal__close--right",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (closeModal()))
      }, [
        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "offer-details-modal__close-text" }, "Schließen", -1 /* HOISTED */)),
        _createVNode(BaseIcon, {
          name: "close",
          class: "offer-details-modal__close-icon"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default"),
      (!isDesktop.value)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "offer-details-modal__close-button--flights-tablet",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (closeModal()), ["prevent"]))
          }, [
            _createVNode(BaseIcon, {
              name: "chevron",
              class: "offer-details-modal__close-button-icon"
            }),
            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "offer-details-modal__close-button-label" }, "zurück", -1 /* HOISTED */))
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("button", {
      type: "button",
      class: "offer-details-modal__close-button",
      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (closeModal()), ["prevent"]))
    }, [
      _createVNode(BaseIcon, {
        name: "chevron",
        class: "offer-details-modal__close-button-icon"
      }),
      _cache[7] || (_cache[7] = _createElementVNode("span", { class: "offer-details-modal__close-button-label" }, "zurück", -1 /* HOISTED */))
    ])
  ], 2 /* CLASS */))
}
}

})