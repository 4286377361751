import { amadeusImageGroup, getBaseUrl, getIbeBaseUrl } from '@/js/utils/utils';
import { getSearchParams } from '@/js/modules/updateUrls';
import { CookieHotelItem, LastSeenHotel } from '@/interfaces/localStorage/last-seen-hotel';
import Cookies from 'js-cookie';
import * as hotelListService from './hotelListService';
/**
* Adds the hotel link & image to the hotel object.
* @param array list
* @return Array of hotels.
*/
const addInfo = (list: CookieHotelItem[]): LastSeenHotel[] => {
	var today = new Date();

	return list.map(function(hotel) {
		const rawParams = hotel.urlparams,
			params = new URLSearchParams(rawParams),
			rgid = params.get('rgid');

		let deeplink: string,
			image: string,
			paramsString = '';

		if (rawParams) {
			// If ddate is today or older, don't add ddate & rdate to urlparams.
			const departureDate = new Date(params.get('ddate') as string);

			if (rawParams && today >= departureDate) {
				params.delete('ddate');
				params.delete('rdate');
			}
		}

		paramsString = rawParams ? `?${params.toString()}` : '';

		// If rgid matches one of the following we link to the amadeus IBE
		// Kreuzfahrten rgid=40042
		// Rundreisen rgid=40045
		// Additionally checking the aid if we don't receive an rgid.
		// If the aid has a length of 5 or more and starts with 1000 it is most likely one of the above.
		if (
			rgid === '40042'
			|| rgid === '40045'
			|| (hotel.id.length > 5 && hotel.id.substr(0, 4) === '1000')
		) {
			deeplink = getIbeBaseUrl() + `/offer${paramsString}`;
			image = 'https://de.images.traveltainment.eu/images/content/booking_thumbs_s_gr/';
			image += `${amadeusImageGroup(hotel.id)}/THB_999_H${hotel.id}.jpg`;
		} else {
			deeplink = getBaseUrl() + `/hotel/${hotel.id}/${paramsString}`;
			image = `${process.env.fluxImagesUrl}/hotel/${hotel.id}/1/s`;
		}

		const {
			id,
			region,
			name,
			category
		} = hotel;

		return {
			id,
			url: deeplink,
			image,
			title: name,
			hotelRating: category,
			region,
		};
	});
};

/**
 * Takes parameters of current hotel and adds it to cookie if it's not already in the cookie
 *
 * @param  {} newHotel parameters of the current hotel
 */
export const processLastSeenHotelData = (newHotel: Record<string, any>): void => {
	var newCookieData = [];
	var currentCookie = null;
	var hotelIndex = -1;

	// Cookie is defined as a global by webpack
	currentCookie = Cookies.get('rpb_lastSeenHotels');

	if (!currentCookie) {
		newCookieData.push(newHotel);
	} else {
		currentCookie = JSON.parse(currentCookie);
		newCookieData = currentCookie;
		currentCookie.forEach(function(hotel: Record<string, any>, index: number) {
			if (hotel.id === newHotel.id) {
				hotelIndex = index;
			}
		});
		if (hotelIndex > -1) {
			newCookieData.splice(hotelIndex, 1);
		}
		newCookieData.unshift(newHotel);
	}

	if (newCookieData.length > 4) {
		newCookieData.splice(4, 1);
	}
	saveCookie(newCookieData);
};

/**
 * Adds the hotel on pageload to the last seen hotels cookie.
 *
 */
export const addToLastSeenHotelsCookie = (): void => {
	const typoDataElement = document.getElementById('typo-data');
	if (!typoDataElement) return;
	if (!typoDataElement.dataset.hoteldata) return;

	const parsedData = JSON.parse(typoDataElement.dataset.hoteldata);
	const hotelInfo = {
		id: parsedData.Hotel.IffCode.toString(),
		name: parsedData.Hotel.Name,
		region: parsedData.Location.Region.Name,
		category: parsedData.Hotel.Category,
		urlparams: getSearchParams(),
	};

	processLastSeenHotelData(hotelInfo);
};

/**
* Load hotel data from IBE Iframe.
*/
export const loadCoookieHotel = (): CookieHotelItem[] => {
	// Cookies is defined as a global via webpack
	if (!Cookies) {
		return [];
	}

	const cookieData = Cookies.get('rpb_lastSeenHotels');

	if (!cookieData) {
		return [];
	}

	const list = JSON.parse(cookieData).slice(0, 4) as CookieHotelItem[];
	return list;
};

export async function editCookieHotels(cookieHotels: CookieHotelItem[]) {
	let lastSeenHotel: LastSeenHotel[] = [];
	if (cookieHotels && cookieHotels.length > 0) {
		const enabledHotels = await filterEnabledHotels(cookieHotels);
		lastSeenHotel = addInfo(enabledHotels);
	}
	return lastSeenHotel;
}

async function filterEnabledHotels(list: CookieHotelItem[]) {
	const enabledHotels = await hotelListService.get(list.map((hotel) => Number(hotel.id)));
	const newList = list.filter((hotel) => enabledHotels.find((e) => e.IffCode.toString() === hotel.id));
	if (newList.length !== list.length) {
		saveCookie(newList);
	}
	return newList;
}

function saveCookie(newCookieData: CookieHotelItem[]) {
	Cookies.set('rpb_lastSeenHotels', JSON.stringify(newCookieData), {
		expires: 365,
		// eslint-disable-next-line no-restricted-globals
		domain: location.hostname.substring(location.hostname.indexOf('.')),
		secure: true,
		sameSite: 'strict'
	});
}
