<template>
	<div
		class="operator-filter"
		:class="disabled ? 'operator-filter--disabled' : ''"
	>
		<DropdownField
			v-if="isDesktop"
			ref="dropdown"
			v-model="fieldValue"
			label="Veranstalter"
			icon="sphere"
			:disabled="disabled"
			:placeholder="disabled ? 'Keine Treffer' : 'Beliebig'"
			:button-wording="localeString"
			@DropdownField:Ok="onOk"
			@DropdownField:Cancel="onReset"
			@DropdownField:Clear="clearInput"
			@DropdownField:OutsideClick="onOk"
		>
			<template #default>
				<div class="operator-filter__checkbox-list">
					<checkbox
						v-for="type in types"
						:key="type.Code"
						v-model="operatorTypes"
						class="operator-filter__checkbox-item"
						:label="type.Name"
						:custom-value="type.Code"
					/>
				</div>
			</template>
		</DropdownField>

		<FormField
			v-if="!isDesktop"
			:selected="fieldValue"
			:show-modal="showModal"
			:placeholder="disabled ? 'Keine Treffer' : 'Beliebig'"
			label="Veranstalter"
			icon="sphere"
			:show-toggle-icon="false"
			@click="toggleModal"
			@FormField:Clear="clearInput(); clearProxyInput()"
		/>

		<modal
			v-model="showModal"
			class="operator-filter__modal"
			title="Veranstalter"
			@Modal:Ok="onOk"
			@Modal:Cancel="onReset"
		>
			<div class="operator-filter__checkbox-list">
				<checkbox
					v-for="type in types"
					:key="type.Code"
					v-model="proxy"
					class="operator-filter__checkbox-item"
					:label="type.Name"
					:custom-value="type.Code"
				/>
			</div>
		</modal>
	</div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import { getLocaleString } from '@utils/utils';
import { useStore } from '@/components/common/store';
import type { OperatorTypesType } from '@/components/common/types';

interface Props {
	disabled?: boolean,
}
const props = withDefaults(defineProps<Props>(), {
	disabled: false
});

const store = useStore();
const showModal = ref(false);
const dropdown = ref<InstanceType<typeof DropdownField> | null>(null);
const localeString = ref(getLocaleString('close'));

const proxy = ref();

const operatorTypes = computed({
	get(): OperatorTypesType[] {
		return store.state.searchMask.operatorTypes || [];
	},
	set(value: OperatorTypesType[]) {
		store.commit('searchMask/updateFormData', { operatorTypes: value });
	}
});

watch(() => operatorTypes.value, () => {
	proxy.value = operatorTypes.value;
}, { immediate: true });

const isDesktop = computed((): boolean => store.state.config.isDesktop);

const types = computed((): OperatorTypesType[] => store.state.types.operator);

const fieldValue = computed(() => {
	const values = operatorTypes.value.map((value) => types.value.find((x: OperatorTypesType) => x.Code === String(value)));
	const filteredValues = values.filter((x) => x !== undefined);

	return filteredValues.map((val) => val?.Name).join(', ');
});

const closeDropdown = () => {
	if (dropdown.value) {
		dropdown.value.toggleDropdown();
	}
};

const onOk = () => {
	if (Array.isArray(proxy.value)) {
		operatorTypes.value = proxy.value;
	}
	closeDropdown();
};

const onReset = () => {
	proxy.value = operatorTypes.value;
	closeDropdown();
};

const toggleModal = () => {
	if (props.disabled) {
		return;
	}
	showModal.value = !showModal.value;
};

const clearInput = () => {
	operatorTypes.value = [];
};

const clearProxyInput = () => {
	proxy.value = null;
};

defineExpose({
	dropdown,
});
</script>

<style lang="scss" scoped>
.operator-filter {
	.operator-filter__icon {
		width: 3rem;
		height: 3rem;
		margin-right: 1rem;
		fill: $color-primary-l1;
	}

	.operator-filter__checkbox-list {
		overflow: auto;
		display: flex;
		flex-direction: column;

		:deep(.checkbox__icon) {
			width: 1.5rem;
			height: 1.5rem;
		}
	}

	:deep(.dropdown__container) {
		@include customScrollbar();
	}

	:deep(.modal__body) {
		@include customScrollbar();
	}

	.operator-filter__checkbox-item {
		margin: 1rem 2rem;
		font-size: $font-small-1;
	}
}

.operator-filter--disabled {
	:deep(.form-field__icon),
	:deep(.form-field__chevron-icon) {
		fill: $color-state-disabled;
	}

	:deep(.form-field) {
		cursor: default;
		border-color: $color-state-disabled;
	}

	:deep(.form-field__label-text) {
		color: $color-state-disabled;
	}

	:deep(.form-field__input::placeholder) {
		color: $color-state-disabled !important;
	}
}
</style>
