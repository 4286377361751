<template>
	<div class="price-slider">
		<div class="price-slider__header">
			<h2 class="price-slider__heading">
				Reisepreis <span class="price-slider__inline-text">pro Person</span>
			</h2>
			<p class="price-slider__value">
				{{ priceValue }}
			</p>
		</div>
		<range-slider
			v-model="price"
			:max="maxPrice"
			class="price-slider__slider"
			@RangeSlider:Select="onSelect"
			@RangeSlider:DragStart="onDragStart"
			@RangeSlider:DragEnd="onDragEnd"
		/>
	</div>
</template>

<script lang="ts" setup>
import RangeSlider from '@lmt-rpb/RangeSlider/RangeSlider.vue';
import { useStore } from '@/components/common/store';
import { computed, ref, watch } from 'vue';
import { Currency, formatPrice } from '@/js/utils/priceUtils';

interface Props {
	maxPrice?: number,
	defaultValue?: number,
	currency?: Currency,
	step?: number,
}

const props = withDefaults(defineProps<Props>(), {
	maxPrice: 1600,
	defaultValue: -1,
	currency: 'EUR',
	step: 100,
});

const store = useStore();

const price = ref(0);

const dragging = ref(false);

price.value = props.defaultValue !== -1 ? props.defaultValue : props.maxPrice;

watch(() => props.defaultValue, (value) => {
	price.value = value;
});

const roundPrice = (amount: number) => {
	if (amount <= props.step) {
		return props.step;
	}
	return Math.round(amount / props.step) * props.step;
};

const priceValue = computed((): string => {
	if (price.value > (props.maxPrice - props.step)) {
		return 'Beliebig';
	}
	if (price.value < props.step) {
		return `bis ${formatPrice(props.step, props.currency)}`;
	}
	return ` bis ${formatPrice(roundPrice(price.value), props.currency)}`;
});

const onSelect = (count: number) => {
	store.commit('searchMask/updateFormData', { maxPrice: roundPrice(count) });
};

const onDragStart = () => {
	dragging.value = true;
};

const onDragEnd = () => {
	dragging.value = false;
	onSelect(price.value);
};

</script>

<style lang="scss" scoped>
.price-slider {
	position: relative;

	.price-slider__value {
		color: $color-primary;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		text-align: left;
		margin: 0;
	}

	.price-slider__header {
		display: flex;
		justify-content: space-between;
		margin: 0;
		margin-bottom: 3rem;
		align-items: center;
	}

	.price-slider__heading {
		padding: 0;
		text-align: left;
		font-size: $font-medium-2;
		font-family: $font-family;
		font-weight: $font-weight-semibold;
		color: $color-text;
	}

	.price-slider__inline-text {
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
	}
}

.price-slider-price-index {
	font-style: italic;
	font-weight: $font-weight-semibold;
	font-size: $font-small-1;
	position: absolute;
	padding-right: 0.1rem;
	top: 0.7rem;
	left: 1.1rem;
}
</style>
