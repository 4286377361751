<template>
	<Teleport
		to="#confirm-dialog"
	>
		<div class="modal-background ">
			<div
				class="confirm__modal"
			>
				<h2
					class="confirm__title"
				>
					{{ props.title }}

					<p
						v-if="props.addition != 'addition'"

						class="confirm__title-addition"
					>
					</p>
				</h2>
				<div class="confirm__footer">
					<div class="confirm__footer-btn">
						<RoundedButton
							type="button"
							theme="outlined"
							class="confirm__button is-cancel"
							@click.stop="onCancel()"
							@keydown.enter.stop.prevent="onCancel()"
						>
							<slot
								name="cancel-icon"
								class="icon"
							></slot>
							{{ props.cancel }}
						</RoundedButton>
					</div>
					<div class="confirm__footer-btn">
						<RoundedButton
							type="button"
							theme="outlined"
							class="confirm__button is-ok"
							@click.stop="onOk()"
							@keydown.enter.stop.prevent="onOk()"
						>
							<slot
								name="ok-icon"
							></slot>
							{{ props.ok }}
						</RoundedButton>
					</div>
				</div>
			</div>
		</div>
	</Teleport>
</template>

<script setup lang="ts">
import {
	onMounted, nextTick, onUnmounted,
} from 'vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';

defineOptions({
	inheritAttrs: false
});

interface Props {
	title?: string,
	addition?: string,
	ok?: string,
	cancel?: string
}

const props = withDefaults(defineProps<Props>(), {
	title: 'title',
	addition: 'addition',
	ok: 'ok',
	cancel: 'cancel',

});

const emit = defineEmits(['Confirm:Ok', 'Confirm:Cancel', 'Confirm:BrowserBack']);

const close = () => {
	document.body.style.overflow = 'auto';
	return false;
};

const onOk = () => {
	emit('Confirm:Ok', close());
};

const onCancel = () => {
	emit('Confirm:Cancel', close());
};

const onBack = () => {
	emit('Confirm:BrowserBack', close());
};

const tabNextElement = (event: Event, currentElement: HTMLElement, confirmButton: HTMLElement, cancelButton: HTMLElement) => {
	if (currentElement === confirmButton) {
		cancelButton?.focus();
		event.preventDefault();
	} else {
		confirmButton?.focus();
		event.preventDefault();
	}
};

const handleKeydown = (event: KeyboardEvent) => {
	const currentElement = document?.activeElement as HTMLElement;
	const confirmButton = document.querySelector('.is-ok') as HTMLElement;
	const cancelButton = document.querySelector('.is-cancel') as HTMLElement;

	if ((event.shiftKey && event.key === 'Tab') || event.key === 'Tab') {
		tabNextElement(event, currentElement, confirmButton, cancelButton);
	}
};
const handlePopState = (event: Event) => {
	event.preventDefault();
	onBack();
};

onMounted(() => {
	document.addEventListener('keydown', handleKeydown);
	window.addEventListener('popstate', handlePopState);
	/* eslint no-restricted-globals: ["error", "event", "fdescribe"] */
	if (!window.history.state) {
		history.pushState('true', '');
	}
	document.body.style.overflow = 'hidden';
	nextTick(() => {
		const confirmButton = document.querySelector('.confirm__button.is-cancel');
		(confirmButton as HTMLElement)?.focus();
	});
});

onUnmounted(() => {
	document.removeEventListener('keydown', handleKeydown);
	window.removeEventListener('popstate', handlePopState);
});

</script>

<style lang="scss" scoped>
:deep(.calendar__button-icon) {
	fill: $color-primary;
	height: 1.6rem;
	margin: 0 -0.1rem -0.2rem 0;
	width: 2.2rem;
}

:deep(.confirm__footer-btn:last-child > button > svg) {
	fill: $color-primary;
}

.modal-background {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: $dialog-bg;
	z-index: 9991;
	display: flex;
	min-height: 100vh;
}

@media (min-width: $breakpoint-mobiletabs) {
	.modal-background {
		justify-content: center;
	}
}

.confirm__modal {
	position: fixed;
	border: 0.2rem solid $color-warning;
	border-radius: $border-radius-medium;
	background: $color-white;
	padding-bottom: 1rem;
	bottom: 0;
	overflow: hidden;
	margin: 0 1.6rem 3rem;
	animation: topToBottom 0.2s ease-out;

	.confirm__title {
		font-size: $font-medium-3;
		padding: 0.5rem 0 1rem;
		margin: 1.5rem 1.5rem 0 2.2rem;
		text-align: left;
		color: $color-text;
		font-weight: $font-weight-semibold;

		&-addition {
			font-size: $font-small-1;
			font-family: $font-family;
			font-weight: $font-weight-regular;
			margin-top: 0.5rem;
		}
	}

	.confirm__footer {
		margin: 0 1rem;
		display: flex;
		flex-direction: column;
		width: 96%;
		padding-right: 1rem;

		&-btn:has(.calendar__button-icon) {
			margin: 0 0.6rem;
		}

		&-btn {
			margin: 0 0.5rem;
			display: flex;
			flex-direction: column;
		}
	}

	.confirm__button {
		&:focus,
		&:hover,
		&:active {
			:deep(.calendar__button-icon.is-cancel) {
				fill: white !important;
			}

			:deep(.calendar__button-icon.is-ok) {
				fill: white !important;
			}
		}
	}

	.is-ok,
	.is-cancel {
		margin: 0.5rem;
		padding: 0.8rem;
		font-weight: $font-weight-extra-bold;
		width: 100%;
	}

	@media (min-width: $breakpoint-verysmall) {
		.confirm__footer {
			flex-direction: row;
			display: flex;
			justify-content: center;
		}
	}
}

@media (min-width: $breakpoint-medium) {
	.confirm__modal:has(.calendar__button-icon) {
		max-width: 41rem;
	}

	.confirm__title {
		font-size: $font-medium-2 !important;
	}

	.confirm__footer-btn {
		width: 100%;
		margin-left: 1rem;
	}
}

@media (min-width: $breakpoint-extralarge) {
	.confirm__modal {
		margin-bottom: 15rem;
	}

	.is-ok,
	.is-cancel {
		padding: 1rem !important;
	}
}

@keyframes topToBottom {
	0% {
		opacity: 0;
		transform: translateY(600px);
	}

	50% {
		opacity: 0.5;
		transform: translateY(200px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

</style>
