import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  type: "button",
  class: "modal__custom-icon"
}
const _hoisted_2 = { class: "modal__title" }
const _hoisted_3 = {
  key: 0,
  class: "modal__title--small-text"
}
const _hoisted_4 = {
  key: 1,
  class: "modal__close",
  viewBox: "0 0 27 32"
}
const _hoisted_5 = { class: "modal__body" }
const _hoisted_6 = { class: "modal__content-top" }
const _hoisted_7 = { class: "modal__content" }
const _hoisted_8 = { class: "modal__content-bottom" }
const _hoisted_9 = {
  key: 0,
  class: "modal__footer"
}
const _hoisted_10 = {
  key: 0,
  class: "modal__footer-warning"
}
const _hoisted_11 = { class: "modal__footer-wrap" }

import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import { EventBus } from '@global-js/event-bus';
import {
	computed, getCurrentInstance, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import ModalManager from '@/js/modules/modalManager';

interface Props {
	modelValue?: boolean,
	title: string,
	offerDurationModal?: boolean,
	initialRender?: boolean,
	board?: boolean,
	errorMessage?: string,
	customIcon?: string,
	withFooter?: boolean,
	cancelButtonLabel?: string,
	acceptButtonLabel?: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Modal',
  props: {
    modelValue: { type: Boolean, default: false },
    title: { default: 'Title' },
    offerDurationModal: { type: Boolean, default: false },
    initialRender: { type: Boolean, default: false },
    board: { type: Boolean, default: false },
    errorMessage: { default: '' },
    customIcon: { default: '' },
    withFooter: { type: Boolean, default: true },
    cancelButtonLabel: { default: 'Abbrechen' },
    acceptButtonLabel: { default: 'OK' }
  },
  emits: ['update:modelValue', 'Modal:Cancel', 'Modal:Ok', 'Modal:Close'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const emit = __emit;

const props = __props;

const componentRef = ref();

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue: boolean) {
		emit('update:modelValue', newValue);
	}
});

const parent = ref<HTMLElement | null>(null);

const root = ref();

const showModal = ref<boolean>(model.value);

const render = ref(props.initialRender);

const isClosing = ref(false);

const bodyElem = computed((): HTMLElement => document && document.body);

const modalClasses = computed((): Record<string, any> => ({
	'is-open': showModal.value,
	'is-closing': isClosing.value
}));

const isHeaderSearch = computed((): boolean => root.value?.type.name === 'SearchForm-Header' || root.value?.type.name === 'Solr-Search');

const close = (): void => {
	emit('update:modelValue', false);
};

const onPopState = (): void => {
	if (showModal.value) {
		close();
	}
};

const ok = (): void => {
	close();
	if (window.history.state && window.history.state.modal) {
		ModalManager.closeModalAndChildren(componentRef.value);
		emit('Modal:Ok');
	}
};

const hide = (): void => {
	emit('Modal:Cancel');
	close();
	if (window.history.state && window.history.state.modal) {
		ModalManager.closeModalAndChildren(componentRef.value);
	}
};

onBeforeUnmount(() => {
	EventBus.$off('OfferDuration:ModalClose', close);
	EventBus.$off('window:popstate', close);
});

onMounted(() => {
	parent.value = getCurrentInstance()?.parent?.vnode.el as unknown as HTMLElement;
	root.value = getCurrentInstance()?.root;

	const mql = window.matchMedia('(min-width: 1300px)');

	mql.addListener(function(e) {
		if (e.matches) {
			close();
		}
	});

	const unwatch = watch(() => model.value, () => {
		if (!model.value) {
			return;
		}

		render.value = true;
		unwatch();
	});

	EventBus.$on('OfferDuration:ModalClose', close);
});

watch(() => model.value, (next: boolean, prev: boolean) => {
	if (next === false && prev === true) {
		setTimeout(() => {
			isClosing.value = false;
			showModal.value = false;
		}, 100);

		isClosing.value = true;
		EventBus.$off('window:popstate', onPopState);
	} else {
		showModal.value = model.value;
		window.history.pushState({ modal: true }, '');
		EventBus.$on('window:popstate', onPopState);
		ModalManager.modalOpenHistory.push(componentRef.value);
	}

	if (root.value?.type.name === 'Solr-Search') {
		bodyElem.value.classList.toggle('remove-back-content', model.value);
	}
	if (!isHeaderSearch.value) {
		bodyElem.value.classList.toggle('no-scroll', model.value);
	} else {
		const headerSearch = document.querySelector('.rpb_header-search');
		if (headerSearch) {
			headerSearch.classList.toggle('rpb_header-search--open-modal');
		}
	}
});

__expose({
	showModal, model
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    ref_key: "componentRef",
    ref: componentRef,
    name: "scale-and-fade"
  }, {
    default: _withCtx(() => [
      (render.value)
        ? _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["modal", modalClasses.value])
          }, [
            _createElementVNode("div", {
              class: "modal__header",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (hide()))
            }, [
              (_ctx.customIcon)
                ? (_openBlock(), _createElementBlock("button", _hoisted_1, [
                    _createVNode(BaseIcon, { name: "chevron" })
                  ]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("span", _hoisted_2, [
                _createTextVNode(_toDisplayString(_ctx.title) + " ", 1 /* TEXT */),
                (_ctx.board)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, " (mind.) "))
                  : _createCommentVNode("v-if", true)
              ]),
              (!_ctx.customIcon)
                ? (_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[3] || (_cache[3] = [
                    _createElementVNode("path", { d: "M15.25 16.188l11 10.938c0.625 0.625 0.625 1.563 0 2.188-0.563 0.625-1.563 0.625-2.188 0l-10.688-10.75-10.75 10.75c-0.625 0.625-1.625 0.625-2.188 0-0.625-0.625-0.625-1.563 0-2.188l11-10.938v-0.375l-11-10.938c-0.625-0.625-0.625-1.563 0-2.188 0.563-0.625 1.563-0.625 2.188 0l10.75 10.75 10.688-10.75c0.625-0.625 1.625-0.625 2.188 0 0.625 0.625 0.625 1.563 0 2.188l-11 10.938v0.375z" }, null, -1 /* HOISTED */)
                  ])))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "content-top")
              ]),
              _createElementVNode("div", _hoisted_7, [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _createElementVNode("div", _hoisted_8, [
                _renderSlot(_ctx.$slots, "content-bottom")
              ])
            ]),
            (_ctx.withFooter)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_ctx.errorMessage)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.errorMessage), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(RoundedButton, {
                      theme: "outlined",
                      class: "modal__footer-button modal__footer-button--hide",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (hide()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.cancelButtonLabel), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(RoundedButton, {
                      class: "modal__footer-button modal__footer-button--ok",
                      disabled: !!_ctx.errorMessage,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (ok()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.acceptButtonLabel), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["disabled"])
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */)), [
            [_vShow, showModal.value]
          ])
        : _createCommentVNode("v-if", true)
    ]),
    _: 3 /* FORWARDED */
  }, 512 /* NEED_PATCH */))
}
}

})