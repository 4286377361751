import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "discount-badge" }

import { determineClient, getBaseUrl } from '@/js/utils/utils';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DiscountBadge',
  setup(__props) {

const client = determineClient(getBaseUrl());
const badgeText = computed(() => {
	switch (client) {
	case 'kuat':
		return 'Special Deal %';
	case 'lmat':
		return 'Schnäppchen %';
	default:
		return 'Restplatz %';
	}
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(badgeText.value), 1 /* TEXT */))
}
}

})