import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "theme-minimal__form",
  autocomplete: "true"
}
const _hoisted_2 = { class: "theme-minimal__values" }
const _hoisted_3 = { class: "theme-minimal__destination" }
const _hoisted_4 = { class: "theme-minimal__rest" }

import { computed, ref, watch } from 'vue';
import { NBSP } from '@global-js/constants';
import { pluralize, getTravelDuration, formatDateInterval } from '@utils/utils';
import { SearchFormDataType } from '@interfaces/search-form';
import { useStore } from '@/components/common/store';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ThemeMinimal',
  setup(__props) {

const store = useStore();

const isMinTablet = computed((): boolean => store.state.config.isMinTablet);

const formData = computed((): SearchFormDataType => store.state.searchMask);

const destination = computed((): undefined | null | string => formData.value.destination?.label || '');

const offerDuration = computed((): string => {
	const { from, to } = formData.value.offerDuration;
	return formatDateInterval(from, to);
});

const travelers = computed((): string => {
	const { adult, children } = formData.value.travelers;
	const adultLabel = (!isMinTablet.value && `${adult}${NBSP}Erw.`) || pluralize(adult, 'Erwachsener', 'Erwachsene');
	let travelersLabel = `${adultLabel}`;
	if (children.length) {
		travelersLabel += ', ' + pluralize(children.length, 'Kind', 'Kinder');
		travelersLabel += `${NBSP}(${children.map((c) => ((c === 1 ? '<2' : c) + NBSP + 'J.')).join(',' + NBSP)})`;
	}
	return travelersLabel;
});

const getSerchMaskData = () => {
	let result = '';
	if (offerDuration.value) {
		result = offerDuration.value + ' | ';
	}
	if (formData.value.travelDuration) {
		result += getTravelDuration(formData.value.travelDuration) + ' | ';
	}
	result += travelers.value;
	return result;
};

const searchMaskData = ref(getSerchMaskData());

watch(isMinTablet, () => {
	searchMaskData.value = getSerchMaskData();
});

watch(formData.value, () => {
	searchMaskData.value = getSerchMaskData();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "theme-minimal",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('ThemeMinimal:Edit')), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(destination.value), 1 /* TEXT */),
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(searchMaskData.value), 1 /* TEXT */)
        ])
      ]),
      _createVNode(BaseIcon, {
        class: "theme-minimal__svg",
        name: "pencil"
      })
    ])
  ]))
}
}

})