<template>
	<div>
		<div class="flight-details__header">
			<BaseIcon
				name="planeGo"
				:class="iconClass"
			/>
			<h3 class="flight-details__headline">
				{{ flightWording }}
			</h3>
		</div>
		<div
			v-for="(segment, index) in flights"
			:key="`${isOutbound ? 'out' : 'in'}-${index}`"
		>
			<div class="flight-details__sub-headline">
				Ihre voraussichtlichen Flugzeiten (<span v-if="stopover">{{ index + 1 }}.</span>{{ flightWording }})
			</div>
			<CheckoutFlightDetailsSegment
				:segment="segment"
				:is-right="!isOutbound"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import CheckoutFlightDetailsSegment from '@lmt-rpb/CheckoutFlightDetailsSegment/CheckoutFlightDetailsSegment.vue';
import { computed } from 'vue';
import { FlightSegment } from '@/interfaces/checkout/offer-data';

const props = defineProps<{
	isOutbound: boolean,
	flights: FlightSegment[]
}>();

const stopover = computed((): boolean => props.flights.length > 1);

const flightWording = computed((): string => (props.isOutbound ? 'Hinflug' : 'Rückflug'));

const iconClass = computed((): string => {
	const baseClass = 'flight-details__';

	return props.isOutbound ? `${baseClass}outbound-icon` : `${baseClass}inbound-icon`;
});

</script>

<style lang="scss" scoped>
.flight-details {
	max-width: 76rem;
	margin: 0 auto;

	&__header {
		@media (max-width: $breakpoint-small - 1px) {
			margin-top: 3rem;
		}

		margin: 0 auto;
		max-width: 30rem;
		margin-top: 4rem;
		display: flex;
		align-items: center;
		margin-bottom: 1.8rem;
	}

	&__headline {
		text-align: left;
		padding: 0;
		font-size: $font-medium-2;
		margin-left: 1.3rem;
		font-weight: bold;
	}

	&__sub-headline {
		margin: 0 auto;
		max-width: 30rem;
		font-style: italic;
		font-size: $font-small-2;
		font-weight: $font-weight-semibold;
		margin-bottom: 2rem;
	}

	&__outbound-icon,
	&__inbound-icon {
		width: 2rem;
	}

	&__inbound-icon {
		transform: rotate(180deg);
	}
}
</style>
