import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "operator-filter__checkbox-list" }
const _hoisted_2 = { class: "operator-filter__checkbox-list" }

import { ref, computed, watch } from 'vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import { getLocaleString } from '@utils/utils';
import { useStore } from '@/components/common/store';
import type { OperatorTypesType } from '@/components/common/types';

interface Props {
	disabled?: boolean,
}

export default /*@__PURE__*/_defineComponent({
  __name: 'OperatorField',
  props: {
    disabled: { type: Boolean, default: false }
  },
  setup(__props: any, { expose: __expose }) {

const props = __props;

const store = useStore();
const showModal = ref(false);
const dropdown = ref<InstanceType<typeof DropdownField> | null>(null);
const localeString = ref(getLocaleString('close'));

const proxy = ref();

const operatorTypes = computed({
	get(): OperatorTypesType[] {
		return store.state.searchMask.operatorTypes || [];
	},
	set(value: OperatorTypesType[]) {
		store.commit('searchMask/updateFormData', { operatorTypes: value });
	}
});

watch(() => operatorTypes.value, () => {
	proxy.value = operatorTypes.value;
}, { immediate: true });

const isDesktop = computed((): boolean => store.state.config.isDesktop);

const types = computed((): OperatorTypesType[] => store.state.types.operator);

const fieldValue = computed(() => {
	const values = operatorTypes.value.map((value) => types.value.find((x: OperatorTypesType) => x.Code === String(value)));
	const filteredValues = values.filter((x) => x !== undefined);

	return filteredValues.map((val) => val?.Name).join(', ');
});

const closeDropdown = () => {
	if (dropdown.value) {
		dropdown.value.toggleDropdown();
	}
};

const onOk = () => {
	if (Array.isArray(proxy.value)) {
		operatorTypes.value = proxy.value;
	}
	closeDropdown();
};

const onReset = () => {
	proxy.value = operatorTypes.value;
	closeDropdown();
};

const toggleModal = () => {
	if (props.disabled) {
		return;
	}
	showModal.value = !showModal.value;
};

const clearInput = () => {
	operatorTypes.value = [];
};

const clearProxyInput = () => {
	proxy.value = null;
};

__expose({
	dropdown,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["operator-filter", _ctx.disabled ? 'operator-filter--disabled' : ''])
  }, [
    (isDesktop.value)
      ? (_openBlock(), _createBlock(DropdownField, {
          key: 0,
          ref_key: "dropdown",
          ref: dropdown,
          modelValue: fieldValue.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((fieldValue).value = $event)),
          label: "Veranstalter",
          icon: "sphere",
          disabled: _ctx.disabled,
          placeholder: _ctx.disabled ? 'Keine Treffer' : 'Beliebig',
          "button-wording": localeString.value,
          "onDropdownField:Ok": onOk,
          "onDropdownField:Cancel": onReset,
          "onDropdownField:Clear": clearInput,
          "onDropdownField:OutsideClick": onOk
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(types.value, (type) => {
                return (_openBlock(), _createBlock(Checkbox, {
                  key: type.Code,
                  modelValue: operatorTypes.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((operatorTypes).value = $event)),
                  class: "operator-filter__checkbox-item",
                  label: type.Name,
                  "custom-value": type.Code
                }, null, 8 /* PROPS */, ["modelValue", "label", "custom-value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "disabled", "placeholder", "button-wording"]))
      : _createCommentVNode("v-if", true),
    (!isDesktop.value)
      ? (_openBlock(), _createBlock(FormField, {
          key: 1,
          selected: fieldValue.value,
          "show-modal": showModal.value,
          placeholder: _ctx.disabled ? 'Keine Treffer' : 'Beliebig',
          label: "Veranstalter",
          icon: "sphere",
          "show-toggle-icon": false,
          onClick: toggleModal,
          "onFormField:Clear": _cache[2] || (_cache[2] = ($event: any) => {clearInput(); clearProxyInput()})
        }, null, 8 /* PROPS */, ["selected", "show-modal", "placeholder"]))
      : _createCommentVNode("v-if", true),
    _createVNode(Modal, {
      modelValue: showModal.value,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((showModal).value = $event)),
      class: "operator-filter__modal",
      title: "Veranstalter",
      "onModal:Ok": onOk,
      "onModal:Cancel": onReset
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(types.value, (type) => {
            return (_openBlock(), _createBlock(Checkbox, {
              key: type.Code,
              modelValue: proxy.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((proxy).value = $event)),
              class: "operator-filter__checkbox-item",
              label: type.Name,
              "custom-value": type.Code
            }, null, 8 /* PROPS */, ["modelValue", "label", "custom-value"]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ], 2 /* CLASS */))
}
}

})