import ArrowIcon from './arrow-icon.vue';
import ArrowRightIcon from './arrow-right-icon.vue';
import BestPriceWarrantyIcon from './best-price-warranty-icon.vue';
import BriefcaseIcon from './briefcase-icon.vue';
import BoardIcon from './board-icon.vue';
import CalendarIcon from './calendar-icon.vue';
import CancelIcon from './cancel-icon.vue';
import CheckFormFieldIcon from './check-form-field-icon.vue';
import CheckIcon from './check-icon.vue';
import CheckGreenIcon from './check_green-icon.vue';
import CheckRedIcon from './check_red-icon.vue';
import CheckTurquoiseIcon from './check_turquoise-icon.vue';
import ChevronIcon from './chevron-icon.vue';
import ChevronLeftIcon from './chevron-left-icon.vue';
import ChevronRightIcon from './chevron-right-icon.vue';
import ClearSearch from './clear-search.vue';
import ClockIcon from './clock-icon.vue';
import CloseIcon from './close-icon.vue';
import CloseCircleIcon from './close_circle-icon.vue';
import ControlsIcon from './controls-icon.vue';
import CreateIcon from './create-icon.vue';
import DirectFlightIcon from './direct-flight-icon.vue';
import DownloadIcon from './download-icon.vue';
import EnvelopeIcon from './envelope-icon.vue';
import EuroFillIcon from './euro-fill-icon.vue';
import EuroOutlineIcon from './euro-outline-icon.vue';
import ExclamationMarkFillIcon from './exclamation-mark-fill-icon.vue';
import EyeIcon from './eye-icon.vue';
import FilterIcon from './filter-icon.vue';
import FlightStartIcon from './flight-start-icon.vue';
import FullscreenIcon from './fullscreen-icon.vue';
import GroupIcon from './group-icon.vue';
import HalfStarIcon from './half-star-icon.vue';
import HalfSunIcon from './half-sun-icon.vue';
import InfoIcon from './info-icon.vue';
import InfoFillIcon from './info-fill-icon.vue';
import InfoOutlineIcon from './info-outline-icon.vue';
import LockCheckoutIcon from './lock-checkout-icon.vue';
import MarkerIcon from './marker-icon.vue';
import MarkerIcon2 from './marker2.vue';
import MinusIcon from './minus-icon.vue';
import MoveUpIcon from './move-up-icon.vue';
import NewTabIcon from './new-tab-icon.vue';
import NotChecked from './not-checked.vue';
import PackageIcon from './package-icon.vue';
import PhoneIcon from './phone-icon.vue';
import PlaneGoIcon from './plane-go-icon.vue';
import PlaneNew from './plane-new.vue';
import PlaneDirect from './plane-direct.vue';
import PlaneIcon from './plane-icon.vue';
import PlusIcon from './plus-icon.vue';
import PrinterIcon from './printer-icon.vue';
import QuestionCircleIcon from './question-circle-icon.vue';
import ReloadIcon from './reload-icon.vue';
import RoomIcon from './room-icon.vue';
import SearchIcon from './search-icon.vue';
import ShieldIcon from './shield-icon.vue';
import SphereIcon from './sphere-icon.vue';
import StarIcon from './star-icon.vue';
import SunIcon from './sun-icon.vue';
import TemperatureWhiteIcon from './temperature-white-icon.vue';
import TransferIcon from './transfer-icon.vue';
import TrashcanIcon from './trashcan-icon.vue';
import UnserTippIcon from './unser-tipp-icon.vue';
import WavesIcon from './waves-icon.vue';
import Pages from './pages.vue';
import Home from './home.vue';
import Location from './location.vue';
import Store from './store.vue';
import Pencil from './pencil.vue';
import HeartIcon from './heart-icon.vue';
import SortIcon from './sort-icon.vue';
import SortDescIcon from './sort-desc-icon.vue';
import FilterNewIcon from './filter-new-icon.vue';
import MapIcon from './map-icon.vue';
import CrossRounded from './cross-rounded-icon.vue';
import AsteriskIcon from './asterisk-icon.vue';
import Room2Icon from './room-2-icon.vue';
import CateringIcon from './catering-icon.vue';
import EquipmentIcon from './equipment-icon.vue';
import LocationIcon from './location-icon.vue';
import EntertainmentIcon from './entertainment-icon.vue';
import CardIcon from './card-icon.vue';

type Icons = {
	[key: string]: unknown; // type Component throws a compile warning
}

export const icons: Icons = {
	arrow: ArrowIcon,
	arrowRight: ArrowRightIcon,
	bestPriceWarranty: BestPriceWarrantyIcon,
	board: BoardIcon,
	briefcase: BriefcaseIcon,
	calendar: CalendarIcon,
	cancel: CancelIcon,
	check: CheckIcon,
	checkGreen: CheckGreenIcon,
	checkRed: CheckRedIcon,
	checkTurquoise: CheckTurquoiseIcon,
	checkFormField: CheckFormFieldIcon,
	chevron: ChevronIcon,
	chevronLeft: ChevronLeftIcon,
	chevronRight: ChevronRightIcon,
	clearSearch: ClearSearch,
	clock: ClockIcon,
	close: CloseIcon,
	closeCircle: CloseCircleIcon,
	controls: ControlsIcon,
	create: CreateIcon,
	directFlight: DirectFlightIcon,
	download: DownloadIcon,
	envelope: EnvelopeIcon,
	euroFill: EuroFillIcon,
	euroOutline: EuroOutlineIcon,
	exclamationMarkFill: ExclamationMarkFillIcon,
	eye: EyeIcon,
	flightStart: FlightStartIcon,
	fullscreen: FullscreenIcon,
	filter: FilterIcon,
	group: GroupIcon,
	halfSun: HalfSunIcon,
	halfStar: HalfStarIcon,
	heart: HeartIcon,
	infoOutline: InfoOutlineIcon,
	infoFill: InfoFillIcon,
	info: InfoIcon,
	lockCheckout: LockCheckoutIcon,
	marker: MarkerIcon,
	marker2: MarkerIcon2,
	minus: MinusIcon,
	moveUp: MoveUpIcon,
	newTab: NewTabIcon,
	notChecked: NotChecked,
	package: PackageIcon,
	printer: PrinterIcon,
	plane: PlaneIcon,
	planeGo: PlaneGoIcon,
	planeNew: PlaneNew,
	planeDirect: PlaneDirect,
	phone: PhoneIcon,
	plus: PlusIcon,
	questionCircle: QuestionCircleIcon,
	reload: ReloadIcon,
	room: RoomIcon,
	shield: ShieldIcon,
	search: SearchIcon,
	sphere: SphereIcon,
	sun: SunIcon,
	star: StarIcon,
	thermometer: TemperatureWhiteIcon,
	transfer: TransferIcon,
	trashcan: TrashcanIcon,
	unserTipp: UnserTippIcon,
	waves: WavesIcon,
	pages: Pages,
	home: Home,
	location: Location,
	store: Store,
	pencil: Pencil,
	sort: SortIcon,
	sortDesc: SortDescIcon,
	filterNew: FilterNewIcon,
	map: MapIcon,
	crossRounded: CrossRounded,
	asterisk: AsteriskIcon,
	room2: Room2Icon,
	catering: CateringIcon,
	equipment: EquipmentIcon,
	locationFilled: LocationIcon,
	entertainment: EntertainmentIcon,
	card: CardIcon,
};
