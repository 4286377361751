import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["href", "title"]
const _hoisted_2 = { class: "rpb_hotelbox__image" }
const _hoisted_3 = ["alt", "data-srcset", "data-src"]
const _hoisted_4 = ["data-iff", "data-ga-name", "data-ga-category"]
const _hoisted_5 = { class: "rpb_hotelbox__subline" }
const _hoisted_6 = { class: "rpb_hotelbox__region" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 0,
  class: "rpb_hotelbox__rating"
}
const _hoisted_10 = ["title"]
const _hoisted_11 = {
  key: 0,
  class: "rpb_hotelbox__text"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  key: 1,
  class: "rpb_hotelbox__text"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "rpb_hotelbox__footer" }
const _hoisted_17 = {
  key: 0,
  class: "rpb_hotelbox__specialoffer"
}
const _hoisted_18 = { class: "rpb_hotelbox__specialoffer--discount" }
const _hoisted_19 = { class: "rpb_hotelbox__specialoffer--oldprice" }
const _hoisted_20 = { class: "rpb_hotelbox__price" }

import {
	ratingText,
	convertDateHelper,
} from '@utils/utils';
import {
	addEntryToFavoriteCookie,
	removeEntryFromFavoriteCookie,
	isFavorite as isFavoriteCookie,
} from '@modules/bookmark';
import Price from '@lmt-rpb/Price/Price.vue';
import HotelStars from '@lmt-rpb/HotelStars/HotelStars.vue';
import { HotelBoxData } from '@interfaces/components/hotelBoxData';
import { EventBus } from '@global-js/event-bus';
import BookmarkAnalytics from '@/js/modules/analytics/BookmarkAnalytics';
import PromotionAnalytics from '@/js/modules/analytics/PromotionAnalytics';
import {
	computed, onBeforeUnmount, onMounted, ref,
} from 'vue';
import { formatPrice } from '@/js/utils/priceUtils';

interface Props {
	deal?: boolean,
	hotel: HotelBoxData,
	currencySymbol: string,
	showSuns: boolean,
	domain?: string,
	slider: boolean,
	promotionName: string,
	bestRating?: number,
	index: number,
	hasPromotionAnalytics?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Hotelbox',
  props: {
    deal: { type: Boolean, default: false },
    hotel: {},
    currencySymbol: {},
    showSuns: { type: Boolean, default: false },
    domain: { default: 'AT' },
    slider: { type: Boolean, default: true },
    promotionName: {},
    bestRating: { default: 6 },
    index: {},
    hasPromotionAnalytics: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props;

const bookmarked = ref(false);

const containsBodyClass = (bodyClass: string): boolean => document.body.classList.contains(bodyClass);

const isRegionOrCity = computed((): boolean => containsBodyClass('page_region') || containsBodyClass('page_city') || containsBodyClass('page_topic_topregion'));

const boxClass = computed(() => ({
	'rpb_hotelbox--deal-list': props.deal && !props.slider,
	'rpb_hotelbox--slider': props.slider,
}));

const averagePriceFormatted = computed((): string => (props.hotel.PriceAverage ? formatPrice(props.hotel.PriceAverage, props.currencySymbol) : ''));

const bookmark = (): void => {
	const items = BookmarkAnalytics.fromHotelBoxData(props.hotel, props.currencySymbol);
	if (!bookmarked.value) {
		addEntryToFavoriteCookie(items, props.hotel.Category);
	} else {
		removeEntryFromFavoriteCookie(items);
	}
};

const updateBookmark = (iffCode: number, favorite: boolean): void => {
	if (iffCode === props.hotel.HotelIffCode) {
		bookmarked.value = favorite;
	}
};

const checkForFavorites = async() => {
	bookmarked.value = await isFavoriteCookie(props.hotel.HotelIffCode);
};

const fireSelectPromotionEvent = (event: MouseEvent): void => {
	if (!props.hasPromotionAnalytics) {
		return;
	}
	const buttonType = event?.button;

	// Firing GA4 event for left and middle mouse button (works on desktop and mobile)
	if (buttonType === 0 || buttonType === 1) {
		const creativeName = props.hotel.DealFactor ? 'Restplätze' : 'Topseller';
		const promotionAnalytics = PromotionAnalytics.fromHotelBoxData(creativeName, props.promotionName, props.hotel, props.currencySymbol, props.index);
		promotionAnalytics.fireSelectPromotion();
	}
};

onMounted((): void => {
	checkForFavorites();
	EventBus.$on('bookmark:favoriteChange', updateBookmark);
});

onBeforeUnmount((): void => {
	EventBus.$off('bookmark:favoriteChange', updateBookmark);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(["rpb_hotelbox", boxClass.value]),
    href: _ctx.hotel.Url,
    title: _ctx.hotel.Name,
    onMousedown: _cache[2] || (_cache[2] = ($event: any) => (fireSelectPromotionEvent($event)))
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        alt: _ctx.hotel.Name,
        "data-srcset": _ctx.hotel.Image.s + ' 330w, ' + _ctx.hotel.Image.m + ' 513w',
        "data-src": _ctx.hotel.Image.m,
        src: "data:image/gif;base64,R0lGODdhEgAMAIABAPD2/////ywAAAAAEgAMAAACDYSPqcvtD6OctNqLVQEAOw==",
        class: "rpb_hotelbox__image rpb_lazy"
      }, null, 8 /* PROPS */, _hoisted_3),
      _createElementVNode("div", {
        tabindex: "0",
        class: _normalizeClass(["rpb_hotelbox__bookmark", bookmarked.value ? 'rpb_hotelbox__bookmark--active' : '']),
        "data-iff": _ctx.hotel.HotelIffCode,
        "data-ga-name": _ctx.hotel.Name,
        "data-ga-category": _ctx.hotel.CountryName + ' / ' + _ctx.hotel.RegionName + ' / ' + _ctx.hotel.CityName,
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (bookmark()), ["prevent","stop"])),
        onMousedown: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent","stop"]))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("svg", {
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          width: "32",
          height: "32",
          viewBox: "0 0 27 32"
        }, [
          _createElementVNode("path", { d: "M13.813 29.063l-0.375-0.313-0.438-0.375-0.625-0.438-0.563-0.5-0.5-0.438-0.875-0.75-0.563-0.438-0.688-0.625-0.625-0.563-2.125-2.125-0.625-0.688-1.25-1.25-0.688-0.813-0.563-0.75-0.688-0.75-0.438-0.688-0.563-0.813-0.563-0.875-0.438-0.938-0.313-0.938-0.188-0.938-0.125-0.875v-1.5l0.063-0.75 0.25-1.125 0.25-0.75 0.25-0.563 0.25-0.625 0.438-0.75 0.688-0.875 0.688-0.688 1.125-0.813 0.875-0.438 1-0.313 0.625-0.125h0.438l1.438 0.063 1.375 0.375 0.875 0.5 0.75 0.5 0.625 0.563 0.75 0.75 0.5 0.688 0.25 0.563h0.125l0.125-0.25 0.125-0.313 0.25-0.313 0.375-0.563 0.375-0.438 0.438-0.438 0.563-0.5 0.75-0.5 0.75-0.313 0.875-0.375 1.188-0.188h0.688l1.125 0.125 1.125 0.375 0.813 0.438 1.188 0.813 0.75 0.875 0.813 1.125 0.563 1.125 0.5 1.438 0.25 1.5-0.125 2.063-0.063 0.75-0.313 1-0.25 0.688-0.938 1.688-0.688 1.063-0.813 1.063-0.75 0.875-0.938 1.188-0.813 0.938-0.875 0.875-1.313 1.375-0.75 0.75-1 0.938-1.438 1.375-1.5 1.25z" })
        ], -1 /* HOISTED */)
      ]), 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, [
        (isRegionOrCity.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.hotel.CityName), 1 /* TEXT */))
          : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.hotel.RegionName), 1 /* TEXT */))
      ]),
      (_ctx.hotel.MeanRatingOverall >= 4.2)
        ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref(ratingText)(_ctx.hotel.MeanRatingOverall)) + " – " + _toDisplayString(_ctx.hotel.MeanRatingOverall) + "/" + _toDisplayString(_ctx.bestRating), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("h3", {
      class: "rpb_hotelbox__hotel-name",
      title: _ctx.hotel.Name
    }, _toDisplayString(_ctx.hotel.Name), 9 /* TEXT, PROPS */, _hoisted_10),
    _createVNode(HotelStars, {
      "show-suns": _ctx.showSuns,
      rating: _ctx.hotel.Category,
      class: "hotelrating--height"
    }, null, 8 /* PROPS */, ["show-suns", "rating"]),
    (!_ctx.deal)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [
          _createElementVNode("li", null, _toDisplayString(_ctx.hotel.TravelDuration) + " Tage", 1 /* TEXT */),
          _createElementVNode("li", null, _toDisplayString(_ctx.hotel.BoardType), 1 /* TEXT */),
          (_ctx.hotel.DepartureAirportTown)
            ? (_openBlock(), _createElementBlock("li", _hoisted_12, "Flug ab " + _toDisplayString(_ctx.hotel.DepartureAirportTown), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.deal)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_13, [
          _createElementVNode("li", null, _toDisplayString(_ctx.hotel.CountryName), 1 /* TEXT */),
          _createElementVNode("li", null, _toDisplayString(_ctx.hotel.BoardType), 1 /* TEXT */),
          _createElementVNode("li", null, _toDisplayString(_ctx.hotel.TravelDuration) + " Tage", 1 /* TEXT */),
          (_ctx.hotel.DepartureAirportTown)
            ? (_openBlock(), _createElementBlock("li", _hoisted_14, " Flug ab " + _toDisplayString(_ctx.hotel.DepartureAirportTown), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          (_ctx.hotel.DepartureDay)
            ? (_openBlock(), _createElementBlock("li", _hoisted_15, "Abflug: " + _toDisplayString(_unref(convertDateHelper)(_ctx.hotel.DepartureDay, _ctx.domain)), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_16, [
      (_ctx.deal)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            (_ctx.hotel.DealFactor && _ctx.hotel.DealFactor < 100 && _ctx.hotel.Discount && _ctx.hotel.PriceAverage)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.hotel.Discount) + "%", 1 /* TEXT */),
                  _createElementVNode("del", _hoisted_19, _toDisplayString(averagePriceFormatted.value), 1 /* TEXT */)
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_20, [
        _createVNode(Price, {
          price: _ctx.hotel.Price,
          currency: _ctx.currencySymbol
        }, null, 8 /* PROPS */, ["price", "currency"]),
        _cache[4] || (_cache[4] = _createTextVNode(" p.P. "))
      ])
    ])
  ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1))
}
}

})