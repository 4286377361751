import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "room-type" }
const _hoisted_2 = { class: "room-type__checkbox-list" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "room-type__price"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "room-type__price"
}

import FormField from '@lmt-rpb/FormField/FormField.vue';
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import { getLocaleString, cloneDeep } from '@utils/utils';
import { computed, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';
import type { UpsellingRoom } from '@/interfaces/search-form';
import Loading from '@lmt-rpb/Loading/Loading.vue';
import { getOfferUpsellingRoom as requestUpsellingRoomList } from '@/components/common/services/bestOfferRoomService';
import { getPriceCorrectCurrency } from '@/js/utils/priceUtils';
import type { RoomTypesType } from '@/components/common/types';

interface Props {
	isHotelPage?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RoomTypeField',
  props: {
    isHotelPage: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const store = useStore();

const showModal = ref(false);

const proxy = ref<any[]>([]);

const upsellingList = ref([] as RoomTypesType[]);

const isDesktop = computed((): boolean => store.state.config.isDesktop);

const defaultTypes = computed((): RoomTypesType[] => store.state.types.room);

const roomList = computed(() => (props.isHotelPage ? upsellingList.value : defaultTypes.value));

const dropdown = ref<InstanceType<typeof DropdownField> | null>(null);

const isfetchUpsellingList = ref(false);

const upsellingResponseError = ref(false);

const isEntryDisabled = (entry: RoomTypesType) => !entry.price && props.isHotelPage && !upsellingResponseError.value;

const roomTypes = computed({
	get(): string[] {
		return store.state.searchMask.roomTypes;
	},

	set(value: string[]) {
		store.commit('searchMask/updateFormData', { roomTypes: value });
	}
});

const fieldValue = computed((): string => roomTypes.value.map((value: string) => defaultTypes.value.find((x) => x.value === value).label).join(', '));

const closeDropdown = () => {
	if (dropdown.value) {
		dropdown.value.toggleDropdown();
	}
};

const onOk = () => {
	roomTypes.value = proxy.value;
	closeDropdown();
};

const onReset = () => {
	store.dispatch('searchMask/rollbackToSavedCurrentState');
	closeDropdown();
};

const getUpsellingList = (responseOffers: UpsellingRoom[]) => {
	const roomTypesUpdate: RoomTypesType[] = [];
	cloneDeep(defaultTypes.value).forEach((item) => {
		const responseItem = responseOffers.find((respItem: any) => respItem.Room.Type === item.value);
		if (responseItem) {
			item.price = responseItem?.Price;
			roomTypesUpdate.push(item);
		}
		const selectedItem = fieldValue.value.split(',').find((field) => field.trim() === item.label.trim());
		if (!responseItem && selectedItem) {
			roomTypesUpdate.push(item);
		}
	});
	return roomTypesUpdate;
};

const requestUpsellingData = async() => {
	isfetchUpsellingList.value = true;
	upsellingResponseError.value = false;
	try {
		const responseOffers = await requestUpsellingRoomList();
		if (responseOffers.length > 0) {
			upsellingList.value = getUpsellingList(responseOffers);
		} else {
			upsellingList.value = defaultTypes.value;
		}
	} catch (error) {
		upsellingList.value = defaultTypes.value;
		upsellingResponseError.value = true;
		console.error(error);
	} finally {
		isfetchUpsellingList.value = false;
	}
};

const toggleModal = () => {
	showModal.value = !showModal.value;
	if (showModal.value) {
		store.dispatch('searchMask/saveCurrentState');
		proxy.value = store.state.searchMask.roomTypes;
	}
	if (showModal.value && props.isHotelPage) {
		requestUpsellingData();
	} else {
		upsellingList.value = defaultTypes.value;
	}
};

const clearInput = () => {
	roomTypes.value = [];
};

const clearProxyInput = () => {
	proxy.value = [];
};

watch(() => roomTypes.value, () => {
	proxy.value = roomTypes.value;
}, { immediate: true });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isDesktop.value)
      ? (_openBlock(), _createBlock(DropdownField, {
          key: 0,
          ref_key: "dropdown",
          ref: dropdown,
          modelValue: fieldValue.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((fieldValue).value = $event)),
          label: "Zimmertyp",
          "room-type": true,
          icon: "room",
          "button-wording": _unref(getLocaleString)('close'),
          "onDropdownField:Ok": onOk,
          "onDropdownField:Cancel": onReset,
          "onDropdownField:Clear": clearInput,
          "onDropdownField:OutsideClick": onOk,
          "onDropdownField:open": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isHotelPage && requestUpsellingData()))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (isfetchUpsellingList.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(Loading, { class: "room-type__loading" })
                  ]))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(roomList.value, (type) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: type.id,
                      class: _normalizeClass(["room-type__checkbox-container", {'is-disabled' : isEntryDisabled(type)}])
                    }, [
                      _createVNode(Checkbox, {
                        modelValue: roomTypes.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((roomTypes).value = $event)),
                        class: "room-type__checkbox-item",
                        label: type.label,
                        "custom-value": type.value
                      }, null, 8 /* PROPS */, ["modelValue", "label", "custom-value"]),
                      (type.price)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "ab " + _toDisplayString(_unref(getPriceCorrectCurrency)(type.price)), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                    ], 2 /* CLASS */))
                  }), 128 /* KEYED_FRAGMENT */))
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "button-wording"]))
      : _createCommentVNode("v-if", true),
    (!isDesktop.value)
      ? (_openBlock(), _createBlock(FormField, {
          key: 1,
          selected: fieldValue.value,
          "show-modal": showModal.value,
          label: "Zimmertyp",
          icon: "room",
          "show-toggle-icon": false,
          onClick: toggleModal,
          "onFormField:Clear": _cache[3] || (_cache[3] = ($event: any) => {clearInput(); clearProxyInput()})
        }, null, 8 /* PROPS */, ["selected", "show-modal"]))
      : _createCommentVNode("v-if", true),
    _createVNode(Modal, {
      modelValue: showModal.value,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((showModal).value = $event)),
      class: "room-type__modal",
      title: "Zimmertyp",
      "onModal:Ok": onOk,
      "onModal:Cancel": onReset
    }, {
      default: _withCtx(() => [
        (isfetchUpsellingList.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(Loading, { class: "room-type__loading" })
            ]))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(roomList.value, (type) => {
              return (_openBlock(), _createElementBlock("div", {
                key: type.id,
                class: _normalizeClass(["room-type__checkbox-container", {'is-disabled' : isEntryDisabled(type)}])
              }, [
                _createVNode(Checkbox, {
                  modelValue: roomTypes.value,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((roomTypes).value = $event)),
                  class: "room-type__checkbox-item",
                  label: type.label,
                  "custom-value": type.value
                }, null, 8 /* PROPS */, ["modelValue", "label", "custom-value"]),
                (type.price && _ctx.isHotelPage)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, "ab " + _toDisplayString(_unref(getPriceCorrectCurrency)(type.price)), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ], 2 /* CLASS */))
            }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}
}

})