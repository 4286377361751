import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "theme-main__tabs" }
const _hoisted_2 = {
  ref: "travel-group",
  class: "theme-main-form__travel-group"
}
const _hoisted_3 = { class: "theme-main-form__date-group" }
const _hoisted_4 = { class: "theme-main-form__footer" }
const _hoisted_5 = { class: "theme-main-form__submit-button-text" }

import {
	SearchFormDataType,
} from '@interfaces/search-form';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import DepartureField from '@lmt-rpb/DepartureField/DepartureField.vue';
import DestinationField from '@lmt-rpb/DestinationField/DestinationField.vue';
import OfferDurationField from '@lmt-rpb/OfferDurationField/OfferDurationField.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import SearchModeSwitch from '@lmt-rpb/SearchModeSwitch/SearchModeSwitch.vue';
import TravelDurationField from '@lmt-rpb/TravelDurationField/TravelDurationField.vue';
import TravelersField from '@lmt-rpb/TravelersField/TravelersField.vue';
import {
	getLocaleString, isOfferlistPage, scrollIt,
} from '@utils/utils';
import { EventBus } from '@global-js/event-bus';
import {
	computed, onBeforeUnmount, onMounted, onUpdated, ref, watch,
} from 'vue';
import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'ThemeMain',
  emits: ['ThemeMain:Submit', 'ThemeMain:Cancel'],
  setup(__props, { emit: __emit }) {


const store = useStore();

const emit = __emit;

const destinationField = ref<InstanceType<typeof DestinationField> | null>(null);

const departureField = ref<InstanceType<typeof DepartureField> | null>(null);

const transferGroup = ref<HTMLElement | null>(null);

const isDragging = ref(false);

const destinationFieldWidth = ref(55); // in percent

const departureFieldHeight = ref<number>(0);

const transferGroupHeight = ref(0);

const isHotelPage = ref(false);

const toggleActive = ref(false);

const isOfferlistPageRef = ref<boolean>(isOfferlistPage());

const isDefaultHotelOnly = computed((): boolean => store.state.config.hotelOnly);

const isDesktop = computed((): boolean => store.state.config.isDesktop);

const formData = computed((): SearchFormDataType => store.state.searchMask);

const isHotelOnly = computed((): boolean => formData.value.onlyHotel);

const progress = ref<number>(formData.value.onlyHotel ? 100 : 0);

const destinationSuggestionUrl = computed((): string => store.state.config.suggestions);

const pageType = computed((): string => store.state.config.pageType);

const maxTravelDurationError = computed(() => store.state.searchMask.maxTravelDurationError);

const destinationStyle = computed((): Record<string, string> => {
	if (!isDesktop.value) {
		return {};
	}

	const add = ((100 - destinationFieldWidth.value) / 100) * progress.value;

	return {
		width: `${destinationFieldWidth.value + add}%`
	};
});

const submitButtonText = computed((): string => {
	if (isDesktop.value || isOfferlistPageRef.value) return 'Suchen';
	return 'Reise finden';
});

const animationStyle = (elementHeight: number): Record<string, number | string> => {
	if (!elementHeight && !isHotelOnly.value) {
		return {};
	}

	const percent = 100 - progress.value;
	const opacity = percent / 100;

	return {
		opacity,
		visibility: opacity === 0 ? 'hidden' : 'visible',
		...(!isDesktop.value ? {
			'max-height': `${(elementHeight / 100) * percent}px`,
			'margin-bottom': `${(0.5 / 100) * percent}rem`,
		} : {})
	};
};

const scrollUpSearchForm = (): void => {
	const searchForm: HTMLElement | null = document.querySelector('#search-form-container .search-form');
	if (!searchForm) {
		return;
	}

	const spaceSearchToBottom = (window.innerHeight - searchForm?.getBoundingClientRect().bottom);

	if (spaceSearchToBottom < 450) {
		scrollIt(
			searchForm,
			300,
			'easeOutQuad',
		);
	}
};

const onSubmit = (): void => {
	emit('ThemeMain:Submit', formData.value);
};

const updateSizes = (): void => {
	if (departureField.value?.$el?.scrollHeight) {
		departureFieldHeight.value = departureField.value?.$el?.scrollHeight;
	}
	if (transferGroup.value) {
		transferGroupHeight.value = transferGroup.value.scrollHeight;
	}
};

const onDrag = (progressNum: number): void => {
	progress.value = progressNum;
	isDragging.value = true;
};

const onDragEnd = (): void => {
	isDragging.value = false;
};

const onChange = (checked: boolean): void => {
	progress.value = checked ? 100 : 0;
};

const setAccommodationType = (onlyHotel: boolean): void => {
	store.commit('searchMask/updateFormData', { onlyHotel });
};
const onTabClick = (onlyHotel: boolean): void => {
	setAccommodationType(onlyHotel);
};

const onToggle = (): void => {
	// Small delay for overflow hidden during animation
	toggleActive.value = true;
	setTimeout(() => {
		toggleActive.value = false;
	}, 500);
};

watch(() => formData.value.onlyHotel, (onlyHotel: boolean) => {
	onChange(onlyHotel);
});

watch(() => isDesktop.value, () => {
	updateSizes();
});

onMounted(() => {
	isHotelPage.value = document.body.classList.contains('page_hotel');

	EventBus.$on('window:resize', updateSizes);
	EventBus.$on('OfferSearch:ScrollUpSearchForm', scrollUpSearchForm);

	if (isDefaultHotelOnly.value) {
		setAccommodationType(true);
	}

	updateSizes();
});

onUpdated(() => {
	const departureFieldScrollHeight = departureField.value?.$el?.scrollHeight;

	transferGroupHeight.value = 230; // default value for max-height

	if (departureFieldScrollHeight && (departureFieldScrollHeight !== departureFieldHeight.value)) {
		departureFieldHeight.value = departureFieldScrollHeight;
	}
});

onBeforeUnmount(() => {
	EventBus.$off('window:resize', updateSizes);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["theme-main", {'is-hotel-page': pageType.value === 'hotelPage'}])
  }, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("li", {
        class: _normalizeClass(["theme-main__tab", {'is-active': !isHotelOnly.value, 'is-home-page' : pageType.value === 'homePage'}]),
        role: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (onTabClick(false)))
      }, [
        _createVNode(BaseIcon, {
          name: "package",
          class: "theme-main__tab-icon package-icon"
        }),
        _createTextVNode(" " + _toDisplayString(_unref(getLocaleString)('package')), 1 /* TEXT */)
      ], 2 /* CLASS */),
      _createElementVNode("li", {
        class: _normalizeClass(["theme-main__tab", {'is-active': isHotelOnly.value, 'is-home-page' : pageType.value === 'homePage'}]),
        role: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (onTabClick(true)))
      }, [
        _createVNode(BaseIcon, {
          name: "room",
          class: "theme-main__tab-icon"
        }),
        _cache[6] || (_cache[6] = _createTextVNode(" Nur Hotel "))
      ], 2 /* CLASS */)
    ]),
    _createCommentVNode(" mobile searchmask "),
    _createElementVNode("form", {
      class: "theme-main-form theme-main__form",
      autocomplete: "false",
      onSubmit: _withModifiers(onSubmit, ["prevent"])
    }, [
      _createVNode(SearchModeSwitch, {
        class: "theme-main-form__switch-button",
        "onSearchModeSwitchSimple:Drag": _cache[2] || (_cache[2] = ($event: any) => (onDrag($event))),
        "onSearchModeSwitchSimple:DragEnd": _cache[3] || (_cache[3] = ($event: any) => (onDragEnd())),
        "onSearchModeSwitchSimple:Change": _cache[4] || (_cache[4] = ($event: any) => (onChange($event))),
        "onSearchModeSwitchSimple:Toggle": onToggle
      }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(DestinationField, {
          ref_key: "destinationField",
          ref: destinationField,
          modelValue: formData.value.destination,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((formData.value.destination) = $event)),
          class: _normalizeClass(["theme-main-form__destination-field", {'theme-main-form__destination-field--is-dragging': isDragging.value}]),
          style: _normalizeStyle(destinationStyle.value),
          inline: false,
          url: destinationSuggestionUrl.value,
          label: "Reiseziel, Hotel, Ort, ..."
        }, null, 8 /* PROPS */, ["modelValue", "class", "style", "url"]),
        _createVNode(DepartureField, {
          ref_key: "departureField",
          ref: departureField,
          class: _normalizeClass(["theme-main-form__departure-field", {'theme-main-form__departure-field--is-dragging': isDragging.value}]),
          style: _normalizeStyle(animationStyle(departureFieldHeight.value))
        }, null, 8 /* PROPS */, ["class", "style"])
      ], 512 /* NEED_PATCH */),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(OfferDurationField, { class: "theme-main-form__offer-duration-field" }),
        _createVNode(TravelDurationField, { class: "theme-main-form__travel-duration-field" })
      ]),
      _createVNode(TravelersField, { class: "theme-main-form__passengers-field" }),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(RoundedButton, {
          type: "submit",
          theme: "extra",
          class: "theme-main-form__submit-button",
          disabled: maxTravelDurationError.value,
          onKeydown: [
            _withKeys(_withModifiers(onSubmit, ["prevent"]), ["enter"]),
            _withKeys(_withModifiers(onSubmit, ["prevent"]), ["space"])
          ]
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_5, _toDisplayString(submitButtonText.value), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "onKeydown"])
      ])
    ], 32 /* NEED_HYDRATION */)
  ], 2 /* CLASS */))
}
}

})