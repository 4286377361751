<template>
	<div
		class="gallery-modal"
		:class="{'is-visible': model}"
	>
		<div class="gallery-modal__header">
			<img
				class="gallery-modal__logo"
				:alt="altTextForLogo"
				:src="modalLogo"
			/>

			<RoundedButton
				class="gallery-modal__close-button"
				theme="outlined"
				aria-label="Close"
				@click="$emit('close')"
			>
				<BaseIcon
					name="close"
					class="gallery-modal__close-icon"
				/>
			</RoundedButton>
		</div>
		<div class="gallery-modal__body">
			<div class="gallery-modal__main-slides">
				<Carousel
					id="gallery"
					ref="mainCarousel"
					v-model="carouselIndex"
					v-bind="mainCarouselSettings"
				>
					<Slide
						v-for="(image, slideIndex) in images"
						:key="slideIndex"
					>
						<img
							class="gallery-modal__main-image"
							:src="image.URL"
							:alt="`${image.Caption} - ${slideIndex}`"
							:title="`© GIATA GmbH 1996 - ${new Date().getFullYear()}`"
						/>
						<span class="gallery-modal__copyright">&copy; GIATA GmbH 1996 - {{ new Date().getFullYear() }}</span>
					</Slide>
					<template #addons>
						<CarouselNavigation>
							<template #prev>
								<span class="gallery-modal__aria-label">prev</span>
								<BaseIcon
									name="chevron"
									class="gallery-modal__prev-icon"
								/>
							</template>
							<template #next>
								<span class="gallery-modal__aria-label">next</span>
								<BaseIcon
									name="chevron"
									class="gallery-modal__next-icon"
								/>
							</template>
						</CarouselNavigation>
					</template>
				</Carousel>
			</div>

			<div
				v-if="images"
				class="gallery-modal__thumb-slides"
			>
				<Carousel
					id="thumbnails"
					ref="thumbnailCarousel"
					v-model="carouselIndex"
					class="is-thumbnail"
					v-bind="thumbCarouselSettings"
				>
					<Slide
						v-for="(image, imageIndex) in images"
						:key="imageIndex"
					>
						<img
							class="gallery-modal__image"
							:src="image.Preview.URL"
							:alt="`${image.Caption} - ${index}`"
							@click="slideTo(imageIndex)"
						/>
					</Slide>

					<template #addons>
						<CarouselNavigation>
							<template #prev>
								<span class="gallery-modal__aria-label">prev</span>
								<BaseIcon
									name="chevron"
									class="gallery-modal__prev-icon is-thumb"
								/>
							</template>
							<template #next>
								<span class="gallery-modal__aria-label">next</span>
								<BaseIcon
									name="chevron"
									class="gallery-modal__next-icon is-thumb"
								/>
							</template>
						</CarouselNavigation>
					</template>
				</Carousel>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { Carousel, Slide, Navigation as CarouselNavigation } from 'vue3-carousel';
import { getWebsiteLogoPath } from '@utils/utils';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import { HotelCatalogueImageType } from '@interfaces/hotel-catalogue-types';
import {
	computed, nextTick, ref, watch,
} from 'vue';

interface Props {
	images: HotelCatalogueImageType[],
	index?: number,
	modelValue?: boolean,
	client?: string,
}

const props = withDefaults(defineProps<Props>(), {
	modelValue: false,
	index: 0,
	client: 'at',
});

const emit = defineEmits(['slide', 'update:modelValue', 'close']);

const carouselIndex = ref(props.index);

const mainCarouselSettings = {
	snapAlign: 'start',
	breakpoints: {
		1200: { // breakpoint-large
			touchDrag: false,
		}
	}
};

const model = computed({
	get() {
		return props.modelValue;
	},

	set(value: boolean) {
		emit('update:modelValue', value);
	}
});

const thumbCarouselSettings = ref({
	itemsToShow: 3,
	snapAlign: 'center',
	breakpoints: {
		768: { // breakpoint-small
			itemsToShow: 10,
		},
		1200: { // breakpoint-large
			itemsToShow: 16,
			touchDrag: false,
		}
	}
});

const mainCarousel = ref<typeof Carousel>();

const thumbnailCarousel = ref<typeof Carousel>();

const altTextForLogo = computed((): string => {
	if (props.client === 'kuat') return 'Kuoni';
	if (props.client === 'lmat') return 'Lastminute';
	return 'Restplatzbörse';
});

const modalLogo = computed((): string => `${getWebsiteLogoPath(props.client)}/img/logo.svg`);

const slideTo = (val: number) => {
	carouselIndex.value = val;
};

watch(() => model.value, () => {
	if (model.value) {
		nextTick(() => { // one tick delay
			mainCarousel.value?.updateSlideWidth();
			thumbnailCarousel.value?.updateSlideWidth();
			document.body.classList.add('gallery-open');
		});
	} else {
		document.body.classList.remove('gallery-open');
	}
});

watch(() => props.index, () => {
	carouselIndex.value = props.index;
});
</script>

<style lang="scss" scoped>
@import '../../../../../node_modules/vue3-carousel/dist/carousel.css';

.carousel {
	height: 100%;

	&.is-thumbnail {
		position: static;
	}

	:deep(.carousel__viewport),
	:deep(.carousel__track) {
		height: 100%;
	}

	:deep(.carousel__slide) {
		position: relative;
	}

	:deep(.carousel__prev),
	:deep(.carousel__next) {
		@include visible-from($breakpoint-small);
	}

	:deep(.carousel__prev--disabled),
	:deep(.carousel__next--disabled) {
		display: none;
	}
}

.gallery-modal {
	position: fixed;
	z-index: 10000;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: none;
	flex-direction: column;
	background: $color-white;

	&.is-visible {
		display: flex;
	}

	.gallery-modal__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1.5rem 1rem;
		height: 9rem;
		max-height: 12%;
		min-height: 7rem;
		border-bottom: 0.1rem solid $color-primary-l4;
	}

	.gallery-modal__logo {
		width: auto;
		height: 100%;
	}

	.gallery-modal__close-button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.5rem;

		&:hover,
		&:active,
		&:focus {
			background: $color-white;
		}
	}

	.gallery-modal__close-icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: $color-primary;
	}

	.gallery-modal__body {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.gallery-modal__main-slides {
		height: 100%;

		:deep(.carousel__prev),
		:deep(.carousel__next) {
			width: 12rem;
			height: 100%;
			background: $color-white-t5;
			color: $color-primary;
		}
	}

	.gallery-modal__aria-label {
		@include sr-only;
	}

	.gallery-modal__prev-icon,
	.gallery-modal__next-icon {
		width: 4rem;
		height: 4rem;
		fill: $color-primary;

		&.is-thumb {
			width: 2rem;
			height: 2rem;
		}
	}

	.gallery-modal__prev-icon {
		transform: rotate(-90deg);
	}

	.gallery-modal__next-icon {
		transform: rotate(90deg);
	}

	.gallery-modal__main-image {
		transform: translate(-50%, -50%);
		position: absolute;
		left: 50%;
		top: 50%;
		max-width: 100%;
		max-height: 100%;
		height: auto;
	}

	.gallery-modal__copyright {
		position: absolute;
		right: 0;
		bottom: 1rem;
		padding: 0.5rem 1rem;
		background: $color-white;
		font-size: $font-small-3;
		color: $color-dark-gray;
	}

	.gallery-modal__thumb-slides {
		@include media-query-up($breakpoint-small) {
			padding: 2rem 5rem;
		}

		position: relative;
		padding: 2rem 0;

		.carousel {
			height: 8rem;
		}

		.carousel__slide {
			padding: 0 0.5rem;
			text-align: center;
			cursor: pointer;

			// TODO: not working correctly. First and last item are never active
			// &:not(.carousel__slide--active) {
			// 	opacity: 0.5;
			// }
		}
	}

	.gallery-modal__image {
		width: 10rem;
		object-fit: cover;
	}
}

</style>
