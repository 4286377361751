<template>
	<section
		v-if="items.length"
		:class="{
			'filter-tag-list' : true,
			'filter-tag-list--no-results': !hasResults,
		}"
	>
		<ul
			v-if="items.length"
			class="filter-tag-list__list"
		>
			<div
				class="filter-tag-list__collapse"
			>
				<li
					v-for="(item, index) in getItems"
					:key="index"
					class="filter-tag-list__item"
				>
					<filter-tag
						:label="item.label"
						@click="resetFilter(item)"
					/>
				</li>
				<li class="filter-tag-list__reset-all">
					<filter-tag
						v-if="isDesktop"
						label="Alle Filter löschen"
						:cancelable="false"
						class="filter-tag-list__item filter-tag-list__reset-all "
						@click="resetAll"
					/>
				</li>

				<collapse
					v-if="isMobile && items.length > 4"
					:hide-arrow-icon="true"
					:open="isCollapseOpen"
					:duration="100"
					@Collapse:Toggle="toggleCollapse"
				>
					<li
						v-for="(item, index) in items.slice(4)"
						:key="index"
						class="filter-tag-list__item"
					>
						<filter-tag
							:label="item.label"
							@click="resetFilter(item)"
						/>
					</li>
					<template #icon>
						<p class="filter-tag-list__collapse-text">
							{{ isCollapseOpen ? 'Weniger' : 'Mehr' }}
						</p>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 27.563 15.267"
							class="collapse__icon--small"
							:class="{'collapse__icon--small-open': isCollapseOpen}"
						>
							<path
								d="M24.907 14.798L13.782 3.673 2.657 14.798a1.511 1.511 0 01-2.188 0 1.511 1.511 0 010-2.188L12.719.422a1.548 1.548 0 012.125 0l12.25 12.188a1.54714964 1.54714964 0 01-2.188 2.188z"
							/>
						</svg>
					</template>
				</collapse>
			</div>
		</ul>
	</section>
</template>

<script lang="ts" setup>
import FilterTag from '@lmt-rpb/FilterTag/FilterTag.vue';
import {
	groupBy, pluralize, throttle,
} from '@utils/utils';
import { SearchFormDataType } from '@interfaces/search-form';
import Collapse from '@lmt-rpb/Collapse/Collapse.vue';
import {
	boardTypes,
	roomTypes,
	transferTypes,
	roomViews,
	mostPopularTypes,
	additionalCheckboxFilterTypes,
} from '@/js/data/index';

import { DEFAULT_DURATION_FILTER, FLEX_ACCEPTED_VALUES } from '@global-js/constants';
import { useStore } from '@/components/common/store';
import {
	computed, ref, onBeforeMount,
} from 'vue';
import { AdditionalCheckboxFilters, MostPopularFilters } from '@/interfaces/checkbox-filter-types';
import { EventBus } from '@global-js/event-bus';
import { formatPrice } from '@/js/utils/priceUtils';

interface Props {
	hasResults?: boolean
}

defineProps<Props>();

const isCollapseOpen = ref(false);

const isMobile = ref(false);

const store = useStore();

const toggleCollapse = () => {
	isCollapseOpen.value = !isCollapseOpen.value;
};

const isDesktop = computed(() => store.state.config.isDesktop);

const formData = computed((): SearchFormDataType => store.state.searchMask as SearchFormDataType);

const isHotelPage = computed((): boolean => {
	if (document.querySelector('.page_hotel')) return true;
	return false;
});

const currencyType = computed((): string => (store.state.config.client === 'ch' ? 'CHF' : 'EUR'));

const locationIdName = computed(() : 'RegionID' | 'CityID' => (
	store.getters.locationType === 'COUNTRY' || store.getters.locationType === 'REGIONGROUP' ? 'RegionID' : 'CityID'));

const destinationTypes = computed((): any[] => store.state.types.destination);

const getDestinationTypeName = computed((): string => {
	const { destinationTypes: destination } = formData.value;
	const { destinationTypeName } = formData.value;
	let destName = '';

	if (destinationTypeName) {
		return destinationTypeName;
	}

	// if destinationTypes is set via paramsToForm then only the id is known and it is an array with 1 value
	if (Array.isArray(destination) && destination[0] && destinationTypes.value.length) {
		const foundType = destinationTypes.value.find((type) => type[locationIdName.value] === destination[0]);

		if (foundType) {
			destName = foundType.Name;
		}
	}
	store.commit('searchMask/updateFormData', { destinationTypeName: destName });
	return destName;
});

const items = computed((): any => {
	const {
		boardTypes: boards,
		roomTypes: rooms,
		directFlight,
		roomViews: views,
		transferTypes: transfers,
		onlyHotel,
		hotelCategory,
		minMeanRecommendationRate,
		maxPrice,
		operatorTypes,
		mostPopularFilters: mostPopular,
		additionalCheckboxFilters,
		maxArrivalTime,
		minArrivalTime,
		maxDepartureTime,
		minDepartureTime,
		flex
	} = formData.value;
	const types = groupBy([...boardTypes, ...roomTypes, ...roomViews, ...transferTypes, ...mostPopularTypes, ...additionalCheckboxFilterTypes], 'value');
	let filterItems: {label: string; value: string | boolean; type: string}[] = [];

	const boardItems = boards.map((item) => {
		const { label, value } = types[item][0];
		return { label, value, type: 'boardTypes' };
	});

	const roomItems = rooms.map((item) => {
		const { label, value } = types[item][0];
		return { label, value, type: 'roomTypes' };
	});

	const viewItems = views.length > 0 ? [{ label: 'Meerblick', value: 'FullOcean', type: 'roomViews' }] : [];

	const transferItems = transfers.map((item) => {
		const { label, value } = types[item][0];
		return { label, value, type: 'transferTypes' };
	});

	const mostPopularArray = [];
	if (!isHotelPage.value && mostPopular?.hotelAttributes && mostPopular?.hotelAttributes.length > 0) {
		mostPopularArray.push(...mostPopular.hotelAttributes);
	}
	if (!isHotelPage.value && mostPopular?.ratingAttributes && mostPopular?.ratingAttributes.length > 0) {
		mostPopularArray.push(...mostPopular.ratingAttributes);
	}
	const mostPopularItems = mostPopularArray.map((item: any) => {
		const { label, value } = types[item][0];
		let { attrType } = types[item][0];
		attrType = attrType.charAt(0).toLowerCase() + attrType.slice(1);
		return {
			label,
			value,
			attrType,
			type: 'mostPopularFilters'
		};
	});

	const additionalCheckboxFiltersArray = [];
	if (!isHotelPage.value && additionalCheckboxFilters?.hotelAttributes && additionalCheckboxFilters?.hotelAttributes.length > 0) {
		additionalCheckboxFiltersArray.push(...additionalCheckboxFilters.hotelAttributes);
	}
	if (!isHotelPage.value && additionalCheckboxFilters?.ratingAttributes && additionalCheckboxFilters?.ratingAttributes.length > 0) {
		additionalCheckboxFiltersArray.push(...additionalCheckboxFilters.ratingAttributes);
	}
	const additionalCheckboxFiltersItems = additionalCheckboxFiltersArray.map((item: any) => {
		const { label, value } = types[item][0];
		let { attrType } = types[item][0];
		attrType = attrType.charAt(0).toLowerCase() + attrType.slice(1);
		return {
			label,
			value,
			attrType,
			type: 'additionalCheckboxFilters'
		};
	});

	filterItems = [...boardItems, ...roomItems, ...viewItems, ...mostPopularItems, ...additionalCheckboxFiltersItems];

	if (!onlyHotel) {
		filterItems = filterItems.concat(transferItems);
	}

	if (directFlight && !onlyHotel) {
		filterItems.push({ label: 'Direktflug', value: true, type: 'directFlight' });
	}

	if (!isHotelPage.value && hotelCategory) {
		filterItems.push({ label: `Hotelkategorie ${hotelCategory}`, value: true, type: 'hotelCategory' });
	}

	if (!isHotelPage.value && minMeanRecommendationRate) {
		filterItems.push({ label: `Weiterempfehlungsrate ${minMeanRecommendationRate}%`, value: true, type: 'minMeanRecommendationRate' });
	}

	if (!isHotelPage.value && maxPrice && maxPrice !== 1600) {
		filterItems.push({ label: `Reisepreis bis ${formatPrice(maxPrice, currencyType.value)}`, value: true, type: 'maxPrice' });
	}

	if (FLEX_ACCEPTED_VALUES.includes(flex as typeof FLEX_ACCEPTED_VALUES[number])) {
		filterItems.push({ label: 'Flexibel buchen', value: true, type: 'flex' });
	}

	if (operatorTypes && operatorTypes.length > 0) {
		filterItems.push({ label: 'Veranstalter', value: true, type: 'operatorTypes' });
	}

	if (!isHotelPage.value && destinationTypes.value && getDestinationTypeName.value) {
		filterItems.push({ label: `Reiseziel ${getDestinationTypeName.value}`, value: true, type: 'destinationTypes' });
	}

	if (!store.state.searchMask.onlyHotel && (minArrivalTime > 0 || minDepartureTime > 0 || maxDepartureTime < 24 || maxArrivalTime < 24)) {
		filterItems.push({ label: 'Flugzeiten', value: true, type: 'flight' });
	}

	return filterItems;
});

const getItems = computed(() => {
	if (isMobile.value) {
		return items.value.slice(0, 4);
	}
	return items.value;
});

const resetAll = (): void => {
	store.dispatch('resetFormData');
};

const resetFilter = (item: { label: string; value: string | boolean; type: string; attrType?: string}): void => {
	type FormDataKey = keyof typeof formData.value;
	if (['boardTypes', 'roomTypes', 'roomViews', 'transferTypes'].indexOf(item.type) !== -1) {
		// update stored value without this item
		const stored = formData.value[item.type as FormDataKey] as string[];

		const updated = item.type === 'roomViews' ? [] : stored.filter((storedItem: string) => storedItem !== item.value);
		store.dispatch('setFormData', {
			...formData.value,
			[item.type]: updated
		});
	} else if (['mostPopularFilters'].indexOf(item.type) !== -1 && item.attrType) {
		const stored = formData.value[item.type as FormDataKey] as MostPopularFilters;
		type StoredDataKey = keyof typeof stored;
		const storedFilter = stored ? stored[item.attrType as StoredDataKey] : [];
		const updated = storedFilter?.filter((storedItem: string) => storedItem !== item.value);
		const storedMostPopular = formData.value.mostPopularFilters;
		store.dispatch('setFormData', {
			...formData.value,
			[item.type]: {
				...storedMostPopular,
				[item.attrType]: updated,
			}
		});
	} else if (['additionalCheckboxFilters'].indexOf(item.type) !== -1 && item.attrType) {
		const stored = formData.value[item.type as FormDataKey] as AdditionalCheckboxFilters;
		type StoredDataKey = keyof typeof stored;
		const storedFilter = stored ? stored[item.attrType as StoredDataKey] : [];
		const updated = storedFilter?.filter((storedItem: string) => storedItem !== item.value);
		const storedAdditionalCheckboxFilters = formData.value.additionalCheckboxFilters;
		store.dispatch('setFormData', {
			...formData.value,
			[item.type]: {
				...storedAdditionalCheckboxFilters,
				[item.attrType]: updated,
			}
		});
	} else {
		// reset stored value by init state
		store.dispatch('resetItem', item.type);

		// if type is destinationTypes we also need to reset the saved name
		if (['destinationTypes'].indexOf(item.type) !== -1) {
			store.dispatch('resetItem', 'destinationTypeName');
		} else if (['travelDuration'].indexOf(item.type) !== -1) {
			store.dispatch('resetItem', 'isTravelDurationExactSelected');
		}
	}

	// update filters
	EventBus.$emit('SearchMask:MobileSubmit');
};

const handleResize = (): void => {
	const width = window.innerWidth;
	isMobile.value = width < 544;
};

onBeforeMount(() => {
	const width = window.innerWidth;
	isMobile.value = width < 544;
	window.addEventListener('resize', throttle(handleResize, 250));
});

onBeforeMount((): void => {
	window.removeEventListener('resize', handleResize);
});

</script>

<style lang="scss" scoped>
:deep(.collapse__header) {
	margin: auto 1rem 0 auto;
}

:deep(.collapse) {
	flex-direction: column-reverse;
	display: flex;
	flex: 1;
	flex-basis: auto;
}

:deep(.collapse__icon) {
	color: $color-primary;
}

:deep(.collapse__body) {
	margin: 0 !important;
}

.collapse__icon--small {
	color: $color-primary;
	width: 2rem;
	height: 2rem;
	margin-left: 1rem;
	transform: rotate(180deg);
	transition: transform 0.1s ease-out;
	fill: currentcolor;
	vertical-align: middle;

	&-open {
		transform: rotate(0deg);
	}
}

.filter-tag-list,
.filter-tag-list--no-results {
	border: none;
	border-radius: $border-radius-small;
	align-items: center;
	padding-top: 0.7rem;
	padding-bottom: 0.7rem;
	display: block;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;

	@media screen and (min-width: $breakpoint-extralarge) {
		border: 0.1rem solid $color-primary-l4;
		display: flex;
	}

	&--no-results {
		border-color: $color-warning;
	}

	&__collapse {
		display: flex;
		flex-wrap: wrap;
		flex: 1;

		@media (min-width: $breakpoint-verysmall) {
			flex: unset;
		}
	}

	&__collapse-text {
		font-weight: $font-weight-semibold;
		color: $color-primary;
		font-size: $font-small-1;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		padding: 0;
		list-style: none;
		align-items: center;

		@media screen and (min-width: $breakpoint-extralarge) {
			margin: 0 1.9rem;
		}
	}

	&__item {
		display: inline-block;
		margin: 0.5rem 0.7rem;
	}

	&__reset-all {
		display: inline-flex;
		padding: 0;
		font-size: 1.6;
		align-items: center;
	}
}
</style>
