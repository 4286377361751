import { defineComponent as _defineComponent } from 'vue'
import { withKeys as _withKeys, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "mode-switch__icon",
  viewBox: "0 0 53 32"
}
const _hoisted_2 = ["value"]

import Draggable from '@lmt-rpb/Draggable/Draggable.vue';
import { getLocaleString } from '@utils/utils';
import { computed, ref, watch } from 'vue';

interface Props {
	threshold?: number,
	draggableAriaLabel: string
	modelValue?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchModeSwitchSimple',
  props: {
    threshold: { default: 5 },
    draggableAriaLabel: {},
    modelValue: { type: Boolean, default: false }
  },
  emits: ['update:modelValue', 'SearchModeSwitchSimple:Change', 'SearchModeSwitchSimple:Drag', 'SearchModeSwitchSimple:DragEnd', 'SearchModeSwitchSimple:Toggle'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const progress = ref(0);

const dragging = ref(false);

const colorStages = computed((): number => Math.floor(progress.value / 20));

const onDrag = (progressNum: number): void => {
	progress.value = progressNum;
	emit('SearchModeSwitchSimple:Drag', progress.value);
	dragging.value = true;
};

const toggle = (): void => {
	model.value = !model.value;
	emit('SearchModeSwitchSimple:Toggle');
};

const onDragEnd = (diff: number): void => {
	if (Math.abs(diff) >= props.threshold) {
		toggle();
	}

	emit('SearchModeSwitchSimple:DragEnd');
	dragging.value = false;
};

const onClick = (event: MouseEvent): void => {
	if (dragging.value) {
		event.stopPropagation();
		return;
	}

	toggle();
};

const check = (): void => {
	progress.value = 100;
	model.value = true;
};

const uncheck = (): void => {
	progress.value = 0;
	model.value = false;
};

watch(() =>	model.value, () => {
	progress.value = model.value ? 100 : 0;
}, { immediate: true });

__expose({
	progress,
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref: "slide",
    class: "mode-switch",
    "aria-live": "polite",
    onClick: _withModifiers(onClick, ["prevent"])
  }, [
    _createVNode(Draggable, {
      progressValue: progress.value,
      "onUpdate:progressValue": _cache[0] || (_cache[0] = ($event: any) => ((progress).value = $event)),
      class: _normalizeClass(["mode-switch__handle", {'is-dragging': dragging.value, 'initial-position': progress.value === 0}]),
      role: "switch",
      "aria-label": _ctx.draggableAriaLabel,
      "aria-checked": `${model.value}`,
      "parent-ref": "slide",
      tabindex: "0",
      "onDraggable:Drag": _cache[1] || (_cache[1] = ($event: any) => (onDrag($event))),
      "onDraggable:DragEnd": _cache[2] || (_cache[2] = ($event: any) => (onDragEnd($event))),
      onKeydown: [
        _withKeys(uncheck, ["left"]),
        _withKeys(check, ["right"]),
        _withKeys(_withModifiers(toggle, ["prevent"]), ["enter"])
      ]
    }, null, 8 /* PROPS */, ["progressValue", "class", "aria-label", "aria-checked", "onKeydown"]),
    _createElementVNode("span", {
      class: _normalizeClass(["mode-switch__label", [`progress-${colorStages.value}`]])
    }, [
      _renderSlot(_ctx.$slots, "left", {}, () => [
        (_openBlock(), _createElementBlock("svg", _hoisted_1, [
          _createElementVNode("title", null, _toDisplayString(_unref(getLocaleString)('package')), 1 /* TEXT */),
          _cache[4] || (_cache[4] = _createElementVNode("path", { d: "M52.403 15.729c-0.456-1.671-1.961-2.88-3.748-2.88-0.358 0-0.704 0.048-1.032 0.139l0.027-0.006-13.769 3.691-17.878-16.671-5.001 1.346 10.726 18.589-12.877 3.446-5.089-4.003-3.758 1.011 4.714 8.169 1.973 3.437 42.963-11.512c1.673-0.455 2.883-1.961 2.883-3.749 0-0.358-0.049-0.705-0.139-1.035l0.006 0.027z" }, null, -1 /* HOISTED */))
        ])),
        _createTextVNode(" " + _toDisplayString(_unref(getLocaleString)('package')), 1 /* TEXT */)
      ])
    ], 2 /* CLASS */),
    _createElementVNode("span", {
      class: _normalizeClass(["mode-switch__label", [`progress-${colorStages.value}`]])
    }, [
      _renderSlot(_ctx.$slots, "right", {}, () => [
        _cache[5] || (_cache[5] = _createTextVNode(" Nur Hotel ")),
        _cache[6] || (_cache[6] = _createElementVNode("svg", {
          class: "mode-switch__icon",
          viewBox: "0 0 32 32"
        }, [
          _createElementVNode("title", null, "Nur Hotel"),
          _createElementVNode("path", { d: "M9.343 17.345c2.209-0.002 4-1.793 4-4.003 0-2.211-1.792-4.003-4.003-4.003s-4.003 1.792-4.003 4.003c0 0.001 0 0.001 0 0.002v-0c0 0.001 0 0.002 0 0.003 0 2.208 1.79 3.998 3.998 3.998 0.003 0 0.006 0 0.009 0h-0zM25.361 9.336h-10.685v9.339h-10.673v-12.004h-2.669v20.014h2.669v-3.996h24.015v4.003h2.669v-12.012c0-0.001 0-0.003 0-0.005 0-2.946-2.389-5.335-5.335-5.335-0.001 0-0.002 0-0.003 0h0z" })
        ], -1 /* HOISTED */))
      ])
    ], 2 /* CLASS */),
    _createElementVNode("input", {
      class: "mode-switch__input",
      type: "checkbox",
      value: model.value,
      onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('SearchModeSwitchSimple:Change', !model.value)))
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2)
  ], 512 /* NEED_PATCH */))
}
}

})